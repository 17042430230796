import React, { FC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import Icon from '../Icon';
import InputError from '../input/InputError';
import { UploadedFileComponent } from './UploadedFileComponent';

type Props = {
    registerFileInput: UseFormRegisterReturn;
    fileName?: string;
    onRemoveFile: () => void;
    errorMessage?: string;
};

export const FileUploader: FC<Props> = ({
    registerFileInput,
    fileName,
    onRemoveFile,
    errorMessage,
}) => (
    <div className="file-uploader__wrapper">
        <label htmlFor="fileUploader" className="custom-file-uploader">
            <div>
                <Icon icon="oa-expand" />{' '}
                <p className="body-text">Attach file</p>
            </div>
        </label>
        <input
            data-testid="fileUploader"
            id="fileUploader"
            type="file"
            accept="image/png, image/jpeg, image/x-png, image/webp, application/pdf"
            {...registerFileInput}
        />
        <UploadedFileComponent onRemoveFile={onRemoveFile} text={fileName} />
        {errorMessage && (
            <InputError testId="fileUploaderError">{errorMessage}</InputError>
        )}
    </div>
);
