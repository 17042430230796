import React from 'react';
import { ContentfulGenre, Event } from 'onair_frontend-lib';

export const getIsTheScrollAllTheWayRight = (
    carouselRef?: React.RefObject<HTMLDivElement>,
    pusherRef?: React.RefObject<HTMLDivElement>,
): boolean => {
    if (carouselRef?.current && pusherRef?.current) {
        // 0 when the scrollbar is at its leftmost position (at the start of the scrolled content), and then increasingly positive as you scroll towards the end of the content.
        const containerScrollLeft = carouselRef.current.scrollLeft || 0;
        // container's width, including content not visible on the screen due to overflow
        const containerScrollWidth = carouselRef.current.scrollWidth || 0;
        // measurement in pixels of the element's CSS width, including any borders, padding, and vertical scrollbars
        const containerOffsetWidth = carouselRef.current.offsetWidth || 0;
        // the width of the pusher. The pusher is meant to help the user to scroll all the way to the end of the container in order to see all the items
        const pusherWidth = pusherRef?.current?.offsetWidth || 0;

        // If the scrollLeft plus the container's width plus the pusher's width is equal or bigger than the
        // whole scrollable container's width, then the scrollbar is at its rightmost position.
        return (
            containerScrollLeft + containerOffsetWidth + pusherWidth >=
            containerScrollWidth
        );
    }
    return false;
};

/**
 *
 * @param events
 * @returns Removes any double entered events and returns an array of unique  GatsbyEvents
 */
export const getUniqueGatsbyEvents = (events: Event[]): Event[] => {
    const uniqueEvents = [...events]
        .map((event) => event)
        .filter((value, index, self) => self.indexOf(value) === index);

    return uniqueEvents;
};

const sortEventsBasedOnEventStart = (events: Event[]) => {
    return events.sort((a,b) => Date.parse(b.preferredVariant?.eventStart || b.variants[0].eventStart) - Date.parse(a.preferredVariant?.eventStart || a.variants[0].eventStart))
}

/**
 * @param events
 * @param genres Carousel's genres
 *
 * @returns In case the carousel has one or more genres it will
 * return only the events that have at least one same genre with the carousel
 */
export const filterByGenre = (
    events: Event[],
    genres?: ContentfulGenre[],
    carousel?: any
) => {
    let result = [...events];
    if (genres?.length) {
        result = [...events].filter((event) =>
            event.genres?.some?.((genre) =>
                genres?.some(
                    (carouselGenre) => carouselGenre.title === genre.title,
                ),
            ),
        );
    }

    const sortedEvents = sortEventsBasedOnEventStart(result)
    
    // Ensure the events are unique
    return getUniqueGatsbyEvents(carousel.sortingOrder === 'ascending' ? sortedEvents.reverse(): sortedEvents);
};
