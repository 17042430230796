'use client';
import React, { useEffect } from 'react';
import { Event } from 'onair_frontend-lib';
import { TheoPlayerContextWrapper } from '../../theo-player/theo-player-context';
import { EventPage } from './EventPage';
import NotFoundPage from '../not-found-page';
import { EventsByTag } from '@/types/page-context';

type EventPageProps = {
    readonly location: Location;
    readonly event?: Event;
    readonly eventsByTag: EventsByTag;
};

const EventPageWithTheoContext = ({
    location,
    event,
    eventsByTag,
}: EventPageProps) => {
    useEffect(() => {
        setTimeout(() => {
            window?.analytics?.page();
        }, 1000);
    }, []);

    if (!event) return <NotFoundPage />;

    return (
        <TheoPlayerContextWrapper>
            <EventPage
                location={location}
                event={event}
                eventsByTag={eventsByTag}
            />
        </TheoPlayerContextWrapper>
    );
};

export default EventPageWithTheoContext;
