"use client";
import React from "react";

import ContentBlockComponent from "./ContentBlockComponent";

import { renderContentfulDescription } from "../../utils/contentful";
import { generateShareInSocialsLinks } from "../../utils/social";
import { useContentfulButtonProps } from "../../hooks/useContentfulButtonProps";

import type {
	ContentfulContentBlock,
	ContentfulImageWithGatsbyImageData,
} from "onair_frontend-lib";
import type { FC } from "react";

const ContentBlock: FC<{
	contentBlock: ContentfulContentBlock;
	showTransparentBackground?: boolean;
	isFirstBlock?: boolean;
}> = ({ contentBlock, showTransparentBackground, isFirstBlock }) => {
	const contentfulButtonProps = useContentfulButtonProps(contentBlock.button);
	return (
		<ContentBlockComponent
			isFirstBlock={isFirstBlock}
			event={contentBlock.event}
			key={contentBlock.title}
			title={contentBlock.title}
			subtitle={contentBlock.subtitle}
			showAppStoreBanners={contentBlock.showAppStoreBanners}
			showTransparentBackground={showTransparentBackground}
			// description={<p>Hello</p>}
			description={renderContentfulDescription(contentBlock.description)}
			image={contentBlock.image as ContentfulImageWithGatsbyImageData}
			buttonProps={contentfulButtonProps}
			imagePosition={contentBlock.imagePosition}
			links={[
				...(contentBlock.shouldShowSocialButtons
					? generateShareInSocialsLinks()
					: []),
				...(contentBlock.links || []),
			]}
		/>
	);
};

export default ContentBlock;
