"use client";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
	categoryOptions,
	fetchSupportTicket,
	NotificationType,
	deletePersonalDataCategory,
	simpleActionTrack,
} from "onair_frontend-lib";
import { useSearchParams } from "next/navigation";

import ContactComponent from "./ContactComponent";
import { CONTACT_PAGE_MESSAGES } from "./messages";
import { getContactSerializedFormatData } from "./contact-utils";

import { useAppSelector } from "../../state/hooks";
import { isObjectEmpty } from "../../utils/object";
import { userAgentDetails } from "../../utils/user-agent";
import { useReCaptcha } from "../../hooks/useReCaptcha";
import { sharedAnalyticsProperties } from "../../utils/analytics";

import type { NotificationObject } from "onair_frontend-lib";
import type { FC } from "react";
import type { ContactFields } from "./types";

function getInitialFieldsFromParams(): Partial<ContactFields | undefined> {
	const searchParams = useSearchParams();
	if (!searchParams) {
		return undefined;
	}
	const initialFields: Partial<ContactFields> = {
		type: Number(searchParams.get("category")) || undefined,
		subject: searchParams.get("subject") ?? undefined,
		description: searchParams.get("message") ?? undefined,
		firstName: searchParams.get("firstName") ?? undefined,
		lastName: searchParams.get("lastName") ?? undefined,
		email: searchParams.get("email") ?? undefined,
	};

	return initialFields;
}

export const Contact: FC = (props) => {
	const initialFields = getInitialFieldsFromParams();
	const methods = useForm();
	const {
		register: registerInput,
		handleSubmit,
		formState: { errors },
		reset: resetForm,
		resetField,
		getValues,
		setValue,
	} = methods;
	const [notification, setNotification] = useState<
		NotificationObject | undefined
	>(undefined);
	const formRef = useRef<HTMLFormElement>(null);
	const notificationRef = useRef<HTMLDivElement>(null);
	const [fileName, setFileName] = useState<string | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [category, setCategory] = useState(initialFields?.type ?? 0);
	const { ReCaptchaComponent, getRecaptchaAndValidate } = useReCaptcha();

	const customerJwt = useAppSelector((state) => state.auth.token?.jwt);

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 0);
	  }, []);

	useEffect(() => {
		if (category === deletePersonalDataCategory.type) {
			setNotification({
				text: CONTACT_PAGE_MESSAGES.delete_account.notification_warning,
				type: NotificationType.warning,
			});
			// If subject or descrption are empty set up an initial value
			if (!getValues("subject")) {
				setValue("subject", CONTACT_PAGE_MESSAGES.delete_account.subject);
			}
			if (!getValues("description")) {
				setValue(
					"description",
					CONTACT_PAGE_MESSAGES.delete_account.description
				);
			}
		} else {
			setNotification(undefined);
		}
	}, [category, getValues, setValue]);

	const handleRemoveFile = useCallback(() => {
		setFileName(undefined);
		resetField("attachment");
	}, [setFileName, resetField]);

	const onSubmitForm = useCallback(async () => {
		if (isObjectEmpty(errors) && formRef.current) {
			const { firstName, lastName, type, ...serializedData } =
				getContactSerializedFormatData(formRef.current);

			const freshdeskData = {
				type,
				...(categoryOptions[type].requiresAuth && { customerJwt }),
				...serializedData,
				// add device info to support ticket endpoint
				deviceInfo: {
					platform: userAgentDetails().platformName,
					browser: userAgentDetails().browserName,
					isMobile: userAgentDetails().isMobile.toString(),
				},
			};

			const reCaptchaToken = await getRecaptchaAndValidate();

			try {
				setIsLoading(true);
				// fetchSupportTicket is using Fetch so any 3rd party cookies will not be saved.
				await fetchSupportTicket({
					...freshdeskData,
					reCaptchaToken: reCaptchaToken || "",
				});
				simpleActionTrack({
					...sharedAnalyticsProperties,
					trackCallback: window?.analytics?.track,
					action: "Helpdesk Contacted",
				});
				setNotification({
					text: CONTACT_PAGE_MESSAGES.contact_form_submit_success,
					type: NotificationType.success,
				});
				resetForm();
				resetField("subject");
				resetField("description");

				if (fileName) {
					handleRemoveFile();
				}
			} catch (error: any) {
				setNotification({
					text:
						error.message || CONTACT_PAGE_MESSAGES.contact_form_submit_error,
					type: NotificationType.error,
				});
			} finally {
				setIsLoading(false);
			}
		}
	}, [
		errors,
		customerJwt,
		resetForm,
		fileName,
		handleRemoveFile,
		resetField,
		getRecaptchaAndValidate,
	]);

	useEffect(() => {
		// Redirect user's attention to the updated notification
		const offsetTop = notificationRef.current?.offsetTop;
		if (offsetTop) {
			window.scrollTo(0, offsetTop - 128);
		}
	}, [notification]);

	return (
		<>
			<ContactComponent
				{...props}
				formRef={formRef}
				initialFields={initialFields}
				notificationRef={notificationRef}
				validationErrors={errors}
				formMethods={methods}
				onSubmitForm={handleSubmit(onSubmitForm)}
				registerInput={registerInput}
				notification={notification}
				fileName={fileName}
				setFileName={setFileName}
				onRemoveFile={handleRemoveFile}
				isLoading={isLoading}
				category={category}
				setCategory={setCategory}
			/>
			{ReCaptchaComponent}
		</>
	);
};
