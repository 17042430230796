'use client';
import React, { useEffect } from 'react';
import { Contact } from '../../contact/Contact';
import ShellClientSide from '@/components/ShellClientSide';
import { OGP } from '@/types/page-context';
import { logPageViewToSegment } from '@/utils/segment';

type Props = {
    readonly location: Location;
    readonly ogp: OGP;
    readonly pageContext: any;
};

const ContactPage = ({ pageContext }: Props) => {
    useEffect(() => {
        logPageViewToSegment(`/${pageContext.slug}`);
    }, []);
    return (
        <ShellClientSide
            isFooterTopLineDisabled
            slug={'contact'}
            ogp={pageContext.seo}
        >
            <Contact />
        </ShellClientSide>
    );
};

export default ContactPage;
