import Image from 'next/image';
import React, { FC } from 'react';
import {
    ContentfulImageWithGatsbyImageData,
    NotificationType,
} from 'onair_frontend-lib';
import OnAirNotification from '../../OnAirNotification';
import messages from './messages';

type NotForSaleProps = {
    image?: ContentfulImageWithGatsbyImageData;
};

const NotForSale: FC<NotForSaleProps> = ({ image }) => {
    const {
        not_for_sale: { title },
    } = messages;

    const eventInfoImage = image && image?.asset?.file?.url;

    return (
        <div className="event-country-availability hero__content">
            {eventInfoImage && (
                <Image
                    fill
                    src={eventInfoImage}
                    alt={image?.alt || ''}
                    className="hero__event-image mx-auto"
                    objectFit="contain"
                />
            )}
            <OnAirNotification
                text={title}
                type={NotificationType.info}
                className="event-country-availability__items-width mt-4"
                customIconClassName="oa-exclamation-mark"
            />
        </div>
    );
};

export default NotForSale;
