/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
'use server';

import { contentfulEventsBySlugsQuery } from '@/graphql/queries';
import { AlgoliaResult } from '@/hooks/useAlgoliaSearch';
import { transformToOldEvent } from '../data-fetching/fetch/utils';
import { ContentCollection } from '@/types/api';

interface ContentfulEventsBySlugsServerSideProps {
    algoliaResults: AlgoliaResult[];
}

export const contentfulEventsBySlugsServerSide = async ({
    algoliaResults,
}: ContentfulEventsBySlugsServerSideProps) => {
    const response = await fetch(
        `${process.env.ONAIR_ENDPOINT}/search?eventIds=${algoliaResults.map(
            (res) => res.objectID,
        )}&skip=0&take=100`,
        {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            },
            cache: 'no-store',
        },
    );

    const { data, error } = await response.json();

    return (
        data?.eventCollection.map((event: ContentCollection) =>
            transformToOldEvent(event),
        ) || []
    );
};
