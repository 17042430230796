import { isBefore } from 'date-fns';
import React, { FC, useCallback } from 'react';
import Image from 'next/image';
import { Document as ContentfulDocument } from '@contentful/rich-text-types';
import classNames from 'classnames';
import messages from './messages';
import Icon from '../Icon';
import Button from '../Button';
import {
    ButtonType,
    ContentfulImage,
    ContentfulEventHeroTheme,
    ContentfulGraphqlRichDescription,
    ContentfulEventType,
    ContentfulEventVariant,
    ContentfulIcon,
    ContentfulAssetWithGatsbyImageData,
} from 'onair_frontend-lib';
import { renderContentfulDescription } from '../../utils/contentful';
import { localizedDateFormat } from '../../utils/date';
import { useCountryCodeSelector } from '../../state/hooks';

export type HeroEventInfoProps = {
    eventInfoLogo: ContentfulImage;
    description: ContentfulGraphqlRichDescription | ContentfulDocument | string;
    eventSlug: string;
    handleHeroTracking: (buttonMessage: string) => void;
    hasTrailer?: boolean;
    price?: string;
    eventIcons?: ContentfulIcon[];
    theme: ContentfulEventHeroTheme;
    eventType?: ContentfulEventType;
    earliestVariant?: ContentfulEventVariant;
    isHeroEventPurchased: boolean;
};

const HeroEventInfo: FC<HeroEventInfoProps> = ({
    eventInfoLogo,
    description,
    eventSlug,
    hasTrailer,
    price,
    eventIcons,
    theme,
    eventType,
    earliestVariant,
    isHeroEventPurchased,
    handleHeroTracking,
}) => {
    const customerCountryCode = useCountryCodeSelector();

    const getBuyTicketButtonType = useCallback(() => {
        if (theme === 'dark') {
            return ButtonType.SOLID_PRIMARY;
        }

        // As default return the default
        return ButtonType.SOLID_SECONDARY;
    }, [theme]);

    const getWatchTrailerButtonType = useCallback(() => {
        if (theme === 'dark') {
            return ButtonType.SOLID_SECONDARY;
        }

        // As default return the default
        return ButtonType.OUTLINE_SECONDARY;
    }, [theme]);

    const eventInfoImage = eventInfoLogo.asset;

    const formattedStartTime = earliestVariant
        ? localizedDateFormat(
              new Date(earliestVariant.eventStart),
              'd MMMM yyyy, HH:mm',
              customerCountryCode,
              false,
          )
        : 'Date coming soon..'; // can not happen

    const isPastEvent =
        earliestVariant &&
        isBefore(new Date(earliestVariant?.eventEnd), new Date());

    const isLiveEvent =
        earliestVariant &&
        isBefore(new Date(earliestVariant?.eventStart), new Date()) &&
        isBefore(new Date(), new Date(earliestVariant?.eventEnd));

    const isFutureEvent =
        earliestVariant &&
        isBefore(new Date(), new Date(earliestVariant?.eventStart));

    let eventDetailButtonText = messages.buyShow;
    if (isHeroEventPurchased) {
        eventDetailButtonText = messages.goToShow;
    } else if ((isFutureEvent || isLiveEvent) && eventType === 'spo') {
        eventDetailButtonText = messages.buyTicket;
    } else if (isFutureEvent && eventType === 'vod') {
        eventDetailButtonText = messages.preOrderShow;
    } else if (isPastEvent && (eventType === 'spo' || eventType === 'vod')) {
        eventDetailButtonText = messages.moreInfo;
    }

    let eventDateTitle = messages.releaseDate;
    if (eventType === 'spo') {
        if (isFutureEvent) {
            eventDateTitle = messages.onAiron;
        } else if (isLiveEvent) {
            eventDateTitle = messages.onAirSince;
        } else if (isPastEvent) {
            eventDateTitle = messages.airedOn;
        }
    } else if (eventType === 'vod') {
        if (isLiveEvent) {
            eventDateTitle = messages.releasedOn;
        } else if (isPastEvent) {
            eventDateTitle = '';
        }
    }

    return (
        <div className="hero__content" key={`hero-event-info-${eventSlug}`}>
            {eventInfoImage && (
                <Image
                    className="hero__logo"
                    width={(eventInfoImage as any).width}
                    height={(eventInfoImage as any).height}
                    src={(eventInfoImage as any).url}
                    alt={eventInfoLogo.alt || ''}
                    style={{
                        objectFit: 'contain',
                        objectPosition: 'left',
                    }}
                />
            )}

            <div className="hero__event-info">
                <div className="event-info__description body-text d-none d-md-block">
                    {renderContentfulDescription(description)}
                </div>
                <div className="event-info__details d-flex">
                    {eventDateTitle && (
                        <div className="event-info__release-date d-none d-md-block">
                            <span className="event-info__title caption-text uppercase">
                                {eventDateTitle}
                            </span>
                            <br />
                            <span className="body-text">
                                {formattedStartTime}
                            </span>
                        </div>
                    )}

                    <div className="event-info__price d-none d-md-block">
                        <span className="event-info__title caption-text">
                            PRICE
                        </span>
                        <br />
                        <span className="event-info__price-from body-title">
                            from {price}
                        </span>
                    </div>
                    {eventIcons && eventIcons?.length > 0 && (
                        <div className="event-info__quality d-none d-md-block">
                            <span className="event-info__title caption-text">
                                QUALITY
                            </span>
                            <br />
                            {eventIcons.map(({ type: icon }) => (
                                <Icon
                                    key={`hero-icon-${icon}`}
                                    icon={icon}
                                    className="d-none d-md-inline hero__icon me-1"
                                />
                            ))}
                        </div>
                    )}
                </div>
                <div className="event-info__buttons">
                    <Button
                        className="buy-show-link d-none d-md-flex body-title"
                        type={getBuyTicketButtonType()}
                        icon={isHeroEventPurchased ? 'oa-play' : 'oa-ticket'}
                        hrefInternal={`/${eventSlug}/`}
                        onClick={() =>
                            handleHeroTracking(eventDetailButtonText)
                        }
                    >
                        {eventDetailButtonText}
                    </Button>

                    <Button
                        className="buy-show-link d-flex d-md-none caption-title"
                        type={getBuyTicketButtonType()}
                        icon={isHeroEventPurchased ? 'oa-play' : 'oa-ticket'}
                        hrefInternal={`/${eventSlug}/`}
                        onClick={() =>
                            handleHeroTracking(eventDetailButtonText)
                        }
                    >
                        {eventDetailButtonText}
                    </Button>

                    <Button
                        className={classNames(
                            'watch-trailer-link body-title',
                            {
                                'd-none': !hasTrailer,
                            },
                            {
                                'd-md-none d-lg-flex': hasTrailer,
                            },
                        )}
                        type={getWatchTrailerButtonType()}
                        icon="oa-play"
                        hrefInternal={`/${eventSlug}/?watch-trailer`}
                    >
                        Watch trailer
                    </Button>
                    <Button
                        className={classNames(
                            'watch-trailer-link caption-title',
                            { 'd-none': !hasTrailer },
                            {
                                'd-none d-md-flex d-lg-none': hasTrailer,
                            },
                        )}
                        type={getWatchTrailerButtonType()}
                        hrefInternal={`/${eventSlug}/?watch-trailer`}
                        icon="oa-play"
                    >
                        Watch trailer
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default HeroEventInfo;
