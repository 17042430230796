'use client';
import React, { FC, useRef } from 'react';
import { ContentfulTestimonial } from 'onair_frontend-lib';
import Carousel from '../carousel';
import { TestomonialCarouselItem } from './TestimonialCarouselItem';

const TestimonialBlockComponent: FC<{
    title: string;
    testimonials: ContentfulTestimonial[];
    landscape: boolean;
    isFirstBlock: boolean;
}> = ({ title, testimonials, landscape, isFirstBlock }) => {
    const carouselItemRef = useRef<HTMLDivElement>(null);

    // If there are no testomonials in this carousel, don't render at all.
    if (testimonials.length <= 0) return null;

    return (
        <Carousel
            data={testimonials.map((testimonial, counter) => (
                <TestomonialCarouselItem
                    key={testimonial.id}
                    testimonial={testimonial}
                    passedRef={counter === 0 ? carouselItemRef : undefined}
                    lastItem={counter === testimonials.length - 1}
                />
            ))}
            title={title}
            carouselItemRef={carouselItemRef}
            isLandscape={landscape}
            isFirstBlock={isFirstBlock}
        />
    );
};

export default TestimonialBlockComponent;
