import * as React from 'react';

export const GoogleLogo = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21.4999 13.5499C21.4999 12.7608 21.4344 12.185 21.2926 11.5878H12.0713V15.1495H17.484C17.3749 16.0347 16.7856 17.3676 15.4761 18.2634L15.4577 18.3826L18.3733 20.5898L18.5753 20.6095C20.4304 18.9352 21.4999 16.4718 21.4999 13.5499Z"
            fill="#4285F4"
        />
        <path
            d="M12.0708 22.9343C14.7225 22.9343 16.9487 22.0811 18.5748 20.6095L15.4755 18.2634C14.6462 18.8286 13.533 19.2231 12.0708 19.2231C9.47351 19.2231 7.26918 17.549 6.48339 15.2349L6.36821 15.2445L3.33653 17.5372L3.29688 17.6449C4.91195 20.7801 8.22941 22.9343 12.0708 22.9343Z"
            fill="#34A853"
        />
        <path
            d="M6.48411 15.2341C6.27677 14.6369 6.15678 13.997 6.15678 13.3359C6.15678 12.6747 6.27677 12.0349 6.4732 11.4377L6.46771 11.3105L3.39803 8.98096L3.2976 9.02768C2.63195 10.3286 2.25 11.7896 2.25 13.3359C2.25 14.8822 2.63195 16.3431 3.2976 17.6441L6.48411 15.2341Z"
            fill="#FBBC05"
        />
        <path
            d="M12.0708 7.45041C13.915 7.45041 15.159 8.22885 15.8684 8.8794L18.6402 6.23474C16.9379 4.68848 14.7225 3.73938 12.0708 3.73938C8.22941 3.73938 4.91195 5.89349 3.29688 9.02865L6.47248 11.4388C7.26918 9.12465 9.47351 7.45041 12.0708 7.45041Z"
            fill="#EB4335"
        />
    </svg>
);
