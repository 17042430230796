import { format } from 'date-fns';
import { CalendarOptions } from 'onair_frontend-lib';

export interface AddToCalendarEvent {
    title: string;
    description?: string;
    location?: string;
    startTime?: Date;
    duration?: number;
    sku?: string;
}

interface BuildUrlProps {
    platform: CalendarOptions;
    startTime?: Date;
    duration?: number;
    title: string;
    location?: string;
    description?: string;
    sku?: string;
}

// This function is used to download the .ics file for Apple and Outlook (desktop)
export function downloadBlob(blob: Blob, filename: string): void {
    const linkEl = document.createElement('a');
    linkEl.href = window.URL.createObjectURL(blob);
    linkEl.setAttribute('download', filename);
    document.body.appendChild(linkEl);
    linkEl.click();
    document.body.removeChild(linkEl);
}

export const getRandomKey = () => {
    const n = Math.floor(Math.random() * 999999999999).toString();
    return `${new Date().getTime().toString()}_${n}`;
};

export const buildUrl = ({
    platform,
    startTime = new Date(),
    duration = 120,
    title,
    location,
    description,
    sku,
}: BuildUrlProps) => {
    let calendarUrl = '';
    let descriptionToUse = description;

    const isAppleOrOutlook = platform === 'Apple' || platform === 'Outlook';
    const isAppleOrOutlookOrOutlookCom =
        isAppleOrOutlook || platform === 'Outlook.com';

    const endTime = new Date(startTime);

    // If there is no duration, we use 2 hours as a placeholder.
    // Use a disclaimer in the description to let the user know that the duration is a placeholder.
    if (!duration)
        descriptionToUse = `Duration is a placeholder, the actual show duration is not yet known.\n\n${description}`;
    endTime?.setMinutes(startTime?.getMinutes() + (duration ?? 120));

    // These functions compensate for the timezone offset
    // The getTimezoneOffset() returns minutes and we need to convert it to milliseconds (60 * 1000) to get the amount of hours that we need to compensate
    const startTimeWithoutTimezoneOffset = new Date(
        startTime?.valueOf() + startTime?.getTimezoneOffset() * 60 * 1000,
    );
    const endTimeWithoutTimezoneOffset = new Date(
        endTime?.valueOf() + startTime?.getTimezoneOffset() * 60 * 1000,
    );

    const startTimeString = `${format(
        new Date(isAppleOrOutlook ? startTimeWithoutTimezoneOffset : startTime),
        'yyyyMMdd',
    )}T${format(
        new Date(isAppleOrOutlook ? startTimeWithoutTimezoneOffset : startTime),
        'HHmmss',
    )}${isAppleOrOutlookOrOutlookCom ? 'Z' : ''}`;

    const endTimeString = `${format(
        new Date(isAppleOrOutlook ? endTimeWithoutTimezoneOffset : endTime),
        'yyyyMMdd',
    )}T${format(
        new Date(isAppleOrOutlook ? endTimeWithoutTimezoneOffset : endTime),
        'HHmmss',
    )}${isAppleOrOutlookOrOutlookCom ? 'Z' : ''}`;

    switch (platform) {
        case 'Google':
            calendarUrl = 'https://calendar.google.com/calendar/render';
            calendarUrl += '?action=TEMPLATE';
            calendarUrl += `&dates=${startTimeString}`;
            calendarUrl += `/${endTimeString}`;
            if (location)
                calendarUrl += `&location=${encodeURIComponent(location)}`;
            calendarUrl += `&text=${encodeURIComponent(title)}`;
            if (descriptionToUse)
                calendarUrl += `&details=${encodeURIComponent(
                    descriptionToUse,
                )}`;

            window.open(calendarUrl, '_blank');

            break;

        case 'Office 365':
            calendarUrl =
                'https://outlook.office.com/owa/?path=/calendar/action/compose&rru=addevent';
            calendarUrl += `&startdt=${new Date(startTime).toISOString()}`;
            calendarUrl += `&enddt=${new Date(endTime).toISOString()}`;
            calendarUrl += `&subject=${encodeURIComponent(title)}`;
            if (location)
                calendarUrl += `&location=${encodeURIComponent(location)}`;
            if (descriptionToUse)
                calendarUrl += `&body=${encodeURIComponent(descriptionToUse)}`;
            calendarUrl += '&allday=false';
            calendarUrl += `&uid=${sku ?? getRandomKey()}`;
            calendarUrl += '&path=/calendar/view/Month';

            window.open(calendarUrl, '_blank');
            break;

        case 'Yahoo':
            calendarUrl = 'https://calendar.yahoo.com/?v=60&view=d&type=20';
            calendarUrl += `&title=${encodeURIComponent(title)}`;
            calendarUrl += `&st=${startTimeString}`;
            calendarUrl += `&et=${endTimeString}`;
            if (descriptionToUse)
                calendarUrl += `&desc=${encodeURIComponent(descriptionToUse)}`;
            if (location)
                calendarUrl += `&in_loc=${encodeURIComponent(location)}`;

            window.open(calendarUrl, '_blank');

            break;

        case 'Outlook.com':
            calendarUrl = 'https://outlook.live.com/owa/?rru=addevent';
            calendarUrl += `&startdt=${new Date(startTime).toISOString()}`;
            calendarUrl += `&enddt=${new Date(endTime).toISOString()}`;
            calendarUrl += `&subject=${encodeURIComponent(title)}`;
            if (location)
                calendarUrl += `&location=${encodeURIComponent(location)}`;
            if (descriptionToUse)
                calendarUrl += `&body=${encodeURIComponent(descriptionToUse)}`;
            calendarUrl += '&allday=false';
            calendarUrl += `&uid=${sku ?? getRandomKey()}`;
            calendarUrl += '&path=/calendar/view/Month';

            window.open(calendarUrl, '_blank');

            break;

        // Apple and Outlook (desktop) are downloadable .ics files
        case 'Apple':
            calendarUrl = [
                'BEGIN:VCALENDAR',
                'VERSION:2.0',
                'BEGIN:VEVENT',
                `URL:${document.URL}`,
                `DTSTART:${startTimeString}`,
                `DTEND:${endTimeString}`,
                `SUMMARY:${title}`,
                `DESCRIPTION:${descriptionToUse}`,
                `LOCATION:${location}`,
                'END:VEVENT',
                'END:VCALENDAR',
            ].join('\n');

            downloadBlob(
                new Blob([calendarUrl], { type: 'text/calendar' }),
                'event.ics',
            );

            break;

        case 'Outlook':
            calendarUrl = [
                'BEGIN:VCALENDAR',
                'VERSION:2.0',
                'BEGIN:VEVENT',
                `URL:${document.URL}`,
                `DTSTART:${startTimeString}`,
                `DTEND:${endTimeString}`,
                `SUMMARY:${title}`,
                descriptionToUse && `DESCRIPTION:${descriptionToUse}`,
                location && `LOCATION:${location}`,
                'END:VEVENT',
                'END:VCALENDAR',
            ].join('\n');

            downloadBlob(
                new Blob([calendarUrl], { type: 'text/calendar' }),
                'event.ics',
            );

            break;

        default:
            break;
    }

    return calendarUrl;
};

export const createAddToCalendarUrl = ({
    platform,
    event,
}: {
    platform: CalendarOptions;
    event: AddToCalendarEvent;
}) => {
    const { title, description, startTime, location, duration } = event;

    return buildUrl({
        platform,
        title,
        description,
        startTime,
        location,
        duration,
    });
};
