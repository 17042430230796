import classNames from 'classnames';
import debounce from 'lodash.debounce';
import React, {
    FC,
    useRef,
    useEffect,
    useState,
    useCallback,
    useMemo,
    ReactNode,
} from 'react';
import { getIsTheScrollAllTheWayRight } from '../event-carousel/event-carousel-utils';

const Carousel: FC<{
    data: ReactNode[];
    title?: string;
    carouselItemRef:
        | React.RefObject<HTMLDivElement>
        | React.RefObject<HTMLAnchorElement>;
    isLandscape?: boolean;
    small?: boolean;
    isFirstBlock?: boolean;
    fetchNextPage?: ({
        onLastPage,
        onTheresMore,
    }: {
        onLastPage: () => void;
        onTheresMore: () => void;
    }) => void;
}> = ({
    data,
    title,
    carouselItemRef,
    isLandscape = true,
    small,
    isFirstBlock,
    fetchNextPage,
}) => {
    const [amountOfItems] = useState(data.length);
    const [isAllTheWayLeft, setIsAllTheWayLeft] = useState(true);
    const [isAllTheWayRight, setIsAllTheWayRight] = useState(true);

    const carouselRef = useRef<HTMLDivElement>(null);
    const pusherRef = useRef<HTMLDivElement>(null);
    const actualContainerRef = useRef<HTMLDivElement>(null);

    const checkIfCarouselParentAndItemAndPusherAreDefined = useCallback(
        () =>
            carouselItemRef.current && carouselRef.current && pusherRef.current,
        [carouselItemRef, carouselRef, pusherRef],
    );

    const scrollCarouselLeft = useCallback(() => {
        if (checkIfCarouselParentAndItemAndPusherAreDefined()) {
            const itemWidth = carouselItemRef?.current?.offsetWidth || 0;
            const containerScrollLeft = carouselRef?.current?.scrollLeft || 0;
            const newIsAllTheWayLeft = containerScrollLeft <= 0;

            setIsAllTheWayLeft(newIsAllTheWayLeft);
            setIsAllTheWayRight(false);
            carouselRef?.current?.scrollTo({
                top: 0,
                left: containerScrollLeft - itemWidth,
                behavior: 'smooth',
            });
        }
    }, [checkIfCarouselParentAndItemAndPusherAreDefined, carouselItemRef]);

    const scrollCarouselRight = useCallback(() => {
        if (checkIfCarouselParentAndItemAndPusherAreDefined()) {
            const itemWidth = carouselItemRef?.current?.offsetWidth || 0;
            const containerScrollLeft = carouselRef?.current?.scrollLeft || 0;
            const newIsAllTheWayRight = getIsTheScrollAllTheWayRight(
                carouselRef,
                pusherRef,
            );

            setIsAllTheWayRight(newIsAllTheWayRight);
            setIsAllTheWayLeft(false);

            carouselRef?.current?.scrollTo({
                top: 0,
                left: containerScrollLeft + itemWidth,
                behavior: 'smooth',
            });
        }
    }, [checkIfCarouselParentAndItemAndPusherAreDefined, carouselItemRef]);

    const onDebouncedScroll = useMemo(
        () =>
            debounce((newScrollLeft: number) => {
                const newIsAllTheWayRight = getIsTheScrollAllTheWayRight(
                    carouselRef,
                    pusherRef,
                );
                const newAllTheWayLeft = newScrollLeft <= 0;

                setIsAllTheWayLeft(newAllTheWayLeft);
                setIsAllTheWayRight(newIsAllTheWayRight);
            }, 50),
        [],
    );

    const onScroll = useCallback(
        (e: React.UIEvent<HTMLDivElement>) => {
            onDebouncedScroll(e.currentTarget.scrollLeft);
        },
        [onDebouncedScroll],
    );

    // By default we assume it's not a carousel, now check to see if we need to enable
    useEffect(() => {
        if (carouselItemRef.current && actualContainerRef.current) {
            const carouselItemWidth = carouselItemRef.current.offsetWidth;
            const actualContainerWidth = actualContainerRef.current.clientWidth;

            const carouselEnabled =
                amountOfItems * carouselItemWidth <= actualContainerWidth;
            setIsAllTheWayRight(carouselEnabled);
        }
    }, [amountOfItems, carouselItemRef]);

    useEffect(() => {
        if (
            isAllTheWayRight &&
            getIsTheScrollAllTheWayRight(carouselRef, pusherRef)
        ) {
            fetchNextPage?.({
                onLastPage: () => {
                    setIsAllTheWayRight(true);
                },
                onTheresMore: () => {
                    setIsAllTheWayRight(false);
                },
            });
        }
    }, [isAllTheWayRight, fetchNextPage]);

    return (
        <div
            className={classNames({
                'container--vertical-padding': !small,
                'col mt-3': small,
            })}
        >
            <div
                className={classNames('container mb-3', {
                    'first-block': isFirstBlock,
                })}
            >
                <div className="col-12" ref={actualContainerRef}>
                    <h3 className="subtitle">{title}</h3>
                </div>
            </div>

            <div
                className={classNames('carousel container-fluid', {
                    'carousel--landscape': isLandscape,
                })}
            >
                <div className="carousel__wrapper">
                    <div className="carousel__shadow-overlay carousel__shadow-overlay--start" />
                    <div
                        className={classNames(
                            'carousel__button carousel__button--left d-none d-sm-block',
                            {
                                hidden: isAllTheWayLeft,
                            },
                        )}
                        onClick={scrollCarouselLeft}
                        role="button"
                        aria-label="Scrolls to the left"
                        tabIndex={0}
                        onKeyPress={scrollCarouselLeft}
                    />

                    <div
                        className={classNames('carousel__items', {
                            'carousel__items--small': small,
                        })}
                        ref={carouselRef}
                        onScroll={onScroll}
                    >
                        <div
                            className={classNames(
                                'carousel__item carousel__item-pusher d-inline-block',
                                {
                                    'carousel__item-pusher--landscape':
                                        isLandscape,
                                },
                            )}
                            ref={pusherRef}
                        />
                        {data}
                        <div
                            className={classNames(
                                'carousel__item carousel__item-pusher carousel__item-pusher--end d-inline-block',
                                {
                                    'carousel__item-pusher--landscape':
                                        isLandscape,
                                },
                            )}
                        />
                    </div>
                    <div
                        className={classNames(
                            'carousel__button carousel__button--right d-none d-sm-block',
                            {
                                hidden: isAllTheWayRight,
                            },
                        )}
                        onClick={scrollCarouselRight}
                        role="button"
                        aria-label="Scrolls to the right"
                        tabIndex={0}
                        onKeyPress={scrollCarouselRight}
                    />
                    <div className="carousel__shadow-overlay carousel__shadow-overlay--end" />
                </div>
            </div>
        </div>
    );
};

export default Carousel;
