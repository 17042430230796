/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
'use client';
import { isBefore } from 'date-fns';
// import { convertToBgImage } from 'gbimage-bridge';
import { useEffect, useMemo, useState } from 'react';

import { isMobile } from 'react-device-detect';

import { generateEventSchema } from './events-schema';

import { useCountryCodeSelector } from '../../../state/hooks';

import type { EventStatus, EventVariant, Event } from 'onair_frontend-lib';

import { useGetEnvironmentVariable } from '@/hooks/useGetEnvironmentVariable';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { isPortrait } from '@/hooks/useIsPortrait';

/**
 *
 * Custom hook componet holding most of the logic used
 * to pupulate the properties of Event page
 */
export const useEventConfiguratorProperties = (
    event: Event,
    selectedVariant: EventVariant,
    eventStatus: EventStatus,
) => {
    const { SITE_URL } = useGetEnvironmentVariable(['SITE_URL']);
    const customerCountryCode = useCountryCodeSelector();
    const { breakpoint, breakpointSmallerThan } = useBreakpoint();

    const backgroundImageProps = useMemo(
        () => ({
            ...event?.poster?.asset,
            imageUrl: (event?.poster?.asset as any)?.url,
            alt: event?.poster.alt,
        }),
        [event?.poster?.asset],
    );

    const mobileBackgroundImageProps = useMemo(
        () =>
            event.portraitPoster?.asset
                ? {
                      ...event?.portraitPoster?.asset,
                      imageUrl: (event?.portraitPoster?.asset as any)?.url,
                      alt: event?.portraitPoster?.alt,
                  }
                : {
                      ...event?.poster?.asset,
                      imageUrl: (event?.poster?.asset as any)?.url,
                      alt: event?.poster.alt,
                  },
        [event?.portraitPoster, event?.poster?.asset],
    );

    const [backgroundProps, setBackgroundProps] =
        useState(backgroundImageProps);

    const currentDate = new Date();

    const availabilityOfShowInCurrentCountry =
        event?.blacklistedCountries?.find(
            (country) => country.countryCode === customerCountryCode,
        );

    const isShowRestrictedInCurrentCountry =
        !!availabilityOfShowInCurrentCountry?.countryCode;

    const partnerToAccessShowInCurrentCountry = {
        partnerName:
            availabilityOfShowInCurrentCountry?.partnerName ?? undefined,
        partnerLink:
            availabilityOfShowInCurrentCountry?.partnerLink ?? undefined,
    };

    const eventStartDate = selectedVariant?.eventStart
        ? new Date(selectedVariant.eventStart)
        : undefined;

    const endSaleDate = selectedVariant?.saleEnd
        ? new Date(selectedVariant.saleEnd)
        : undefined;

    const eventEndDate = selectedVariant?.eventEnd
        ? new Date(selectedVariant.eventEnd)
        : undefined;

    const stream = selectedVariant?.stream ?? undefined;

    const isSaleOpen = endSaleDate ? isBefore(currentDate, endSaleDate) : true;

    const isSpoLiveShowWithOpenSale =
        event?.type === 'spo' && eventStatus === 'LIVE' && isSaleOpen;

    const willShowGoLive = useMemo(() => {
        // Check if we(today) are 3 days (or more) away from the event start date
        const NUMBER_OF_DAYS_BEFORE = 3;
        const dateUsedForCalculations = new Date(selectedVariant?.eventStart);

        dateUsedForCalculations.setDate(
            dateUsedForCalculations.getDate() - NUMBER_OF_DAYS_BEFORE,
        );

        const doesEventStartsWithinThreeDays =
            dateUsedForCalculations < new Date();
        return (
            (event?.type === 'vod' || event?.type === 'spo') &&
            doesEventStartsWithinThreeDays &&
            eventStatus === 'FUTURE'
        );
    }, [eventStatus, event?.type, selectedVariant?.eventStart]);

    const countDownBannerInfo = {
        playingNow: isSpoLiveShowWithOpenSale,
        countDownTime: (willShowGoLive ? eventStartDate : endSaleDate) as Date,
    };

    const schemaData = useMemo(() => {
        if (!SITE_URL) return {};
        return {
            prerendered: JSON.stringify(
                event?.variants?.map((eventVariant) =>
                    generateEventSchema(event, eventVariant, SITE_URL),
                ),
            ),
        };
    }, [event, SITE_URL]);

    useEffect(() => {
        if (breakpointSmallerThan('lg') && isPortrait()) {
            setBackgroundProps(mobileBackgroundImageProps);
        } else {
            setBackgroundProps(backgroundImageProps);
        }
    }, [
        backgroundImageProps,
        breakpoint,
        breakpointSmallerThan,
        mobileBackgroundImageProps,
    ]);

    return {
        isShowRestrictedInCurrentCountry,
        partnerToAccessShowInCurrentCountry,
        stream,
        backgroundImageProps: backgroundProps,
        countDownBannerInfo,
        willShowGoLive,
        isSpoLiveShowWithOpenSale,
        endSaleDate,
        isSaleOpen,
        schemaData,
        eventEndDate,
    };
};
