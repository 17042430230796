import React, { FC } from 'react';
import CartListItem from './CartListItem';
import { useAppSelector } from '../../../state/hooks';
import { CartState } from '../../../state/reducers/cartSlice';
import { NormalizedCartListItem } from '../../../types/cart';

type CartListProps = {
    normalizedCartList: Array<NormalizedCartListItem>;
};
const CartListComponent: FC<CartListProps> = ({ normalizedCartList }) => {
    const {
        status: cartStatus,
        removingLineId,
        addingAddonsId,
    }: CartState = useAppSelector((state) => state.cartSlice);
    return (
        <>
            <div className="product-list">
                {normalizedCartList.map(
                    (
                        {
                            variantTitle,
                            productTitle,
                            shopifyVariantId,
                            poster,
                            eventStart,
                            quantity,
                            price,
                            compareAtPrice,
                            lineId,
                            estimatedCost,
                            isAddOnsProduct,
                            icons,
                        },
                        index,
                    ) => (
                        <CartListItem
                            productTitle={productTitle}
                            variantTitle={variantTitle}
                            shopifyVariantId={shopifyVariantId}
                            poster={poster}
                            eventStart={eventStart}
                            quantity={quantity}
                            price={price}
                            compareAtPrice={compareAtPrice}
                            index={index}
                            lineId={lineId}
                            estimatedCost={estimatedCost}
                            isAddOnsProduct={isAddOnsProduct}
                            icons={icons}
                            isLoading={
                                (cartStatus === 'REMOVE_CART_LINES_PENDING' &&
                                    lineId === removingLineId) ||
                                (isAddOnsProduct &&
                                    cartStatus ===
                                        'UPDATE_CART_LINES_PENDING' &&
                                    addingAddonsId === shopifyVariantId)
                            }
                            key={lineId}
                        />
                    ),
                )}
            </div>
        </>
    );
};

export default CartListComponent;
