"use client";
import { useDispatch } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import * as EmailValidator from "email-validator";
import {
	forgotPasswordActions,
	ForgotPasswordStatus,
	sendResetPasswordEmailAction,
	VALIDATION_ERROR_MESSAGES,
	ValidationErrorCode,
	ValidationError,
} from "onair_frontend-lib";

import ForgotPasswordFormComponent from "./component";
import FORGOT_PASSWORD_STORED_EMAIL from "./constants";

import ROUTES from "../../constants/routes";
import { goToRoute } from "../../utils/navigation";
import { setItemInLocalStorage } from "../../state/localStorage";
import useRedirectIfLoggedIn from "../../hooks/useRedirectIfLoggedIn";
import { useAppSelector } from "../../state/hooks";

import type { FC } from "react";
import type { AppDispatch } from "@/state/createStore";

const ForgotPasswordForm: FC = () => {
	useRedirectIfLoggedIn(ROUTES.myPlace);
	const [email, setEmail] = useState<string>("");
	const [localError, setLocalError] = useState<ValidationError | undefined>(
		undefined
	);
	const dispatch = useDispatch<AppDispatch>();
	const notification = useAppSelector(
		({ forgotPassword }) => forgotPassword.notification
	);
	const forgotPasswordStatus = useAppSelector(
		({ forgotPassword }) => forgotPassword.status
	);

	useEffect(() => {
		if (
			forgotPasswordStatus ===
			ForgotPasswordStatus.sendResetPasswordEmailSuccessful
		) {
			// We will need the email for login him once he updates his password
			setItemInLocalStorage(FORGOT_PASSWORD_STORED_EMAIL, email);
			goToRoute(ROUTES.forgotPasswordCheckYourEmail);
			// Resetting the state to avoid an infinite loop when returning to this page
			dispatch(forgotPasswordActions.resetState());
		}
	}, [dispatch, email, forgotPasswordStatus]);

	const handleSubmitForm = useCallback(async () => {
		setLocalError(undefined);
		dispatch(forgotPasswordActions.resetState());
		if (email && EmailValidator.validate(email)) {
			dispatch(sendResetPasswordEmailAction(email));
		} else {
			setLocalError(
				new ValidationError(
					ValidationErrorCode.invalidEmail,
					VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalidEmail]
				)
			);
		}
	}, [dispatch, email]);

	return (
		<ForgotPasswordFormComponent
			onEmailChange={(e) => setEmail(e.target.value)}
			onSubmitForm={handleSubmitForm}
			onGoBackToLogin={() => goToRoute(ROUTES.login)}
			notification={notification}
			error={localError}
		/>
	);
};

export default ForgotPasswordForm;
