"use client"
import React, { useRef, useEffect, useCallback } from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import messages from './messages';
import loadingAnimation from './loading.json';

/** In future it might be made more generic by passing animationData as prop
 *  And animation text is also might be passed as prop
 *  Same applies if animation should show a text or not can be passed as boolean prop
 */
const PlayerLoader = () => {
    const lottieRef = useRef<LottieRefCurrentProps | null>(null);
    const { loadingMessages } = messages;

    const generatePlayerTextElement = useCallback(() => {
        const loadingMessage = document.createElement('div');
        loadingMessage.className = 'player-loader-message';
        const threeDotEl = document.createElement('div');
        threeDotEl.className = 'player-loader-three-dot';

        const loadingContainer = document.createElement('div');
        loadingContainer.appendChild(loadingMessage);
        loadingContainer.appendChild(threeDotEl);
        loadingContainer.className = 'body-title loader-text-container';

        return loadingContainer;
    }, []);

    const generateRandomText = useCallback(() => {
        const randomIndex = Math.ceil(
            Math.random() * (loadingMessages.length - 1),
        );
        return messages.loadingMessages[randomIndex];
    }, [loadingMessages]);

    useEffect(() => {
        let interval: number;

        if (lottieRef?.current) {
            const loaderTextContainer = generatePlayerTextElement();
            lottieRef.current.animationContainerRef?.current?.appendChild(
                loaderTextContainer,
            );

            const loadingText = generateRandomText();
            loaderTextContainer.children[0].innerHTML = loadingText;
            const threeDotEl = loaderTextContainer.children[1];
            interval = window.setInterval(() => {
                if (threeDotEl.innerHTML.endsWith('...')) {
                    threeDotEl.innerHTML = ' ';
                } else {
                    threeDotEl.innerHTML += '.';
                }
            }, 1000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [generatePlayerTextElement, generateRandomText, loadingMessages.length]);

    return (
        <Lottie
            animationData={loadingAnimation}
            className="player-loader-animation"
            lottieRef={lottieRef}
            loop
        />
    );
};

export default PlayerLoader;
