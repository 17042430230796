'use client';
import { useMemo } from 'react';
import {
    DEFAULT_COUNTRY_CODE,
    parseProductVariantShopifyStringFromID,
    trackCartAddOrRemove,
    trackViewEventDetails,
    useEventConfigurator,
} from 'onair_frontend-lib';
import { AnalyticsBrowser } from '@segment/analytics-next';

import {
    CART_ATTRIBUTE,
    CART_ITEM_TYPE,
    CART_LINE_ATTRIBUTE,
} from '../components/cart/constants';
import ROUTES from '../constants/routes';
import {
    addCartLinesAction,
    createCartAction,
} from '../state/actions/cartActions';
import {
    useAppDispatch,
    useAppSelector,
    useCountryCodeSelector,
} from '../state/hooks';
import { navigate } from '../utils/navigation';
import { sharedAnalyticsProperties } from '../utils/analytics';

import type { CartState } from '../state/reducers/cartSlice';
import type { Event, EventVariant } from 'onair_frontend-lib';

export const useBuyTicket = (event: Event, chosenVariant?: EventVariant) => {
    if (!event) return {};

    const dispatch = useAppDispatch();
    const customerAccessToken = useAppSelector(
        (state) => state.auth.token?.customerAccessToken,
    );
    const { selectedVariant: defaultVariant } = useEventConfigurator({
        variants: event.variants || [],
    });
    const selectedVariant = chosenVariant ?? defaultVariant;
    const customerCountryCode =
        useCountryCodeSelector() || DEFAULT_COUNTRY_CODE;
    const customerInfo = useAppSelector(
        (state) => state.auth.token?.customerInfo,
    );
    const { cart, status: cartStatus }: CartState = useAppSelector(
        (state) => state.cartSlice,
    );

    const skusInTheCart: string[] = useMemo(
        () => cart?.lines.edges.map(({ node }) => node.merchandise.sku) || [],
        [cart],
    );
    const addItemToCart = () => {
        trackViewEventDetails({
            ...sharedAnalyticsProperties,
            trackCallback: window?.analytics?.track,
            eventDetailsAction: 'Add To Cart',
            countryCode: customerCountryCode,
            selectedVariant,
            event,
        });

        trackCartAddOrRemove({
            ...sharedAnalyticsProperties,
            trackCallback: window?.analytics?.track,
            cartAction: 'Product Added',
            event,
            selectedVariant,
            countryCode: customerCountryCode,
            url: window.location.href,
            cartId: `${cart?.id}`,
        });
        if (selectedVariant && skusInTheCart.includes(selectedVariant.sku)) {
            navigate(ROUTES.cart);
        } else if (selectedVariant?.shopifyProductVariantId) {
            const lines = [
                {
                    merchandiseId: btoa(
                        parseProductVariantShopifyStringFromID(
                            selectedVariant.shopifyProductVariantId,
                        ),
                    ),
                    quantity: 1,
                    attributes: [
                        {
                            key: CART_LINE_ATTRIBUTE.CART_ITEM_TYPE,
                            value: CART_ITEM_TYPE.EVENT,
                        },
                        {
                            key: CART_LINE_ATTRIBUTE.LINE_CREATION_DATE,
                            value: new Date().toISOString(),
                        },
                    ],
                },
            ];
            dispatch(
                cart?.id
                    ? addCartLinesAction({
                          cartId: cart.id,
                          lines,
                          countryCode: customerCountryCode,
                      })
                    : createCartAction({
                          createCartVariables: {
                              customerAccessToken,
                              email: customerInfo?.email,
                              lines,
                              countryCode: customerCountryCode,
                              attributes: [
                                  {
                                      key: CART_ATTRIBUTE.CART_CREATED_COUNTRY,
                                      value: customerCountryCode,
                                  },
                              ],
                          },
                      }),
            );
        }
    };

    return {
        addItemToCart,
        cartStatus,
    };
};
