import React from 'react';
import { Event } from 'onair_frontend-lib';

import ROUTES from '../../../constants/routes';
import { CodedError } from '../../../types/error';
import { ErrorComponent, ErrorOption } from '../../error';
import LinkInternal from '../../LinkInternal';
import messages from './messages';

type Props = {
    error: CodedError;
    event: Event;
};

const EventError = ({ error, event }: Props) => {
    const {
        title,
        message,
        option_title,
        option_refresh,
        option_contact,
        option_wait,
    } = messages.error;
    const details = `${error?.name} (${error?.code}) - ${error?.message}`;
    return (
        <div className="error-wrap">
            <ErrorComponent
                title={title}
                message={message}
                details={details}
                optionsTitle={option_title}
                isEventError
            >
                <ErrorOption icon="oa-restart">{option_refresh}</ErrorOption>
                <ErrorOption icon="oa-loader">{option_wait}</ErrorOption>
                <ErrorOption icon="oa-email">
                    <LinkInternal
                        to={`${ROUTES.contact}/?category=0&subject=${encodeURI(
                            `Error on page /${event.slug}`,
                        )}&message=${encodeURI(
                            `An error occured on page /${event.slug}\n\nDetails: ${details}`,
                        )}`}
                    >
                        {option_contact}
                    </LinkInternal>
                </ErrorOption>
            </ErrorComponent>
        </div>
    );
};

export default EventError;
