//@ts-nocheck TODO
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchShows } from "onair_frontend-lib";

import MyPlaceComponent from "./MyPlaceIndexComponent";

import { useAppSelector } from "../../../state/hooks";

import type { Event, EventsState } from "onair_frontend-lib";
import type { FC } from "react";

const MyPlaceIndex: FC<{ path: string }> = () => {
	const customerInfo = useAppSelector(
		(state) => state.auth.token?.customerInfo
	);
	const customerAccessToken = useAppSelector(
		({ auth }) => auth.token?.customerAccessToken
	);
	const jwt = useAppSelector(({ auth }) => auth.token?.jwt);
	const expiresAt = useAppSelector(({ auth }) => auth.token?.expiresAt);

	const events: Event[] = useAppSelector(({ tickets }) => tickets.events);
	const fetchStatus: EventsState["status"] = useAppSelector(
		({ tickets }) => tickets.status
	);
	const dispatch = useDispatch();
	const [newestTicket, setNewestTicket] = useState<Event | undefined>();

	useEffect(() => {
		if (customerAccessToken) {
			dispatch(
				fetchShows({
					auth: {
						jwt: jwt ?? "",
					},
				})
			);
		}
	}, [dispatch, customerAccessToken, jwt, expiresAt]);

	useEffect(() => {
		if (events.length > 0) {
			setNewestTicket(events[0]);
		}
	}, [events]);

	return (
		<MyPlaceComponent
			customerInfo={customerInfo}
			fetchStatus={fetchStatus}
			ticket={newestTicket}
		/>
	);
};

export default MyPlaceIndex;
