//@ts-nocheck TODO
import React, { useEffect, useState } from 'react';
import { getEventCategories } from 'onair_frontend-lib';

import TicketsComponent from './MyTicketsComponent';

import { useAppSelector } from '../../../state/hooks';

import type { Event, EventsState } from 'onair_frontend-lib';
import type { FC } from 'react';

export interface MyTicketsProps {
    path: string;
}

const MyTickets: FC<MyTicketsProps> = () => {
    const [pastTickets, setPastTickets] = useState<Event[]>([]);
    const [futureTickets, setFutureTickets] = useState<Event[]>([]);
    const events: Event[] = useAppSelector(({ tickets }) => tickets.events);
    const fetchStatus: EventsState['status'] = useAppSelector(
        ({ tickets }) => tickets.status,
    );
    const error: EventsState['error'] = useAppSelector(
        ({ tickets }) => tickets.error,
    );

    useEffect(() => {
        if (events.length > 0) {
            const { pastEvents, futureEvents, liveEvents } =
                getEventCategories(events);
            setPastTickets(pastEvents);
            setFutureTickets([...futureEvents, ...liveEvents]);
        }
    }, [events]);

    return (
        <TicketsComponent
            error={error}
            fetchStatus={fetchStatus}
            futureTickets={futureTickets}
            pastTickets={pastTickets}
        />
    );
};

export default MyTickets;
