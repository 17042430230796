import classNames from 'classnames';

/* eslint-disable unicorn/no-keyword-prefix */
interface BackgroundImageProps {
    imageUrl: string;
    children: JSX.Element | JSX.Element[] | '' | undefined | false | any;
    height?: number;
    width?: number;
    className?: string;
    ariaLabel?: string;
    noGradient?: boolean;
    showShadow?: boolean;
}

export const BackgroundImage = ({
    imageUrl,
    children,
    height,
    width,
    className,
    noGradient = false,
    showShadow = true,
    ariaLabel,
}: BackgroundImageProps) => (
    <div
        className={`background-image ${className}`}
        aria-label={ariaLabel}
        style={{
            backgroundImage: noGradient
                ? `url(${imageUrl})`
                : showShadow
                ? `linear-gradient(
                  180deg,
                  rgba(18, 17, 18, 0.00) 0%,
                  rgba(18, 17, 18, 0.70) 100%,
                  #121112 100%
                ),
                url(${imageUrl})`
                : '',
            backgroundSize: 'cover',
            borderRadius: '8px',
            backgroundPosition: 'center',
            height: height,
            width: width,
        }}
    >
        {children}
    </div>
);
