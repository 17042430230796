/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { FC } from 'react';
import { ContentfulEventCarousel } from 'onair_frontend-lib';
import EventCarouselComponent from './EventCarouselComponent';

const EventCarousel: FC<{
    carousel: ContentfulEventCarousel;
    small?: boolean;
    isFirstBlock?: boolean;
}> = ({ carousel, small = false, isFirstBlock }) => {
    return (
        <EventCarouselComponent
            carousel={carousel}
            //@ts-ignore
            dynamicCarousel={carousel.dynamicCarousel}
            small={small}
            isFirstBlock={isFirstBlock}
        />
    );
};
export default EventCarousel;
