import { useFormContext, RegisterOptions } from 'react-hook-form';
import React, {
    FC,
    useMemo,
    useCallback,
    MouseEventHandler,
    KeyboardEventHandler,
    memo,
    useEffect,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { useDropdown } from './useDropdown';
import { KeyboardKey } from '../../types/keyboard';
import InputError from '../input/InputError';

export interface DropdownProps {
    id?: string;
    selected: number;
    options: string[];
    selectCallback?: (id: number) => void;
    label?: string;
    error?: React.ReactElement | string;
    testId?: string;
    validated?: boolean;
    smallLabel?: boolean;
    disabled?: boolean;
    className?: string;
    inputName?: string;
    validationRules?: Pick<RegisterOptions, 'validate'>;
    dark?: boolean;
}

const noopUserFormContext = {
    setValue: () => {},
    register: () => {},
};

const Dropdown: FC<DropdownProps> = memo(
    ({
        // replace by useId from React 18, or something like useId from https://react-spectrum.adobe.com/react-aria/useId.html
        id = 'hardcoded-id',
        options,
        selected,
        selectCallback,
        label,
        error,
        testId = 'test-id-dropdown',
        validated,
        smallLabel,
        disabled,
        className,
        inputName = 'dropdown',
        validationRules,
        dark = false,
    }) => {
        const { isOpen, menuRef, toggleRef } = useDropdown();

        const getOnOptionClickHandler = useCallback(
            (optionId: number): MouseEventHandler =>
                () => {
                    selectCallback?.(optionId);
                },
            [selectCallback],
        );

        const { register, setValue } = useFormContext() || noopUserFormContext;

        useEffect(() => {
            setValue(inputName, selected);
        }, [setValue, selected, inputName]);

        const getOnOptionKeyDownHandler = useCallback(
            (optionId: number): KeyboardEventHandler =>
                (event) => {
                    switch (event.key) {
                        case KeyboardKey.Enter:
                        case KeyboardKey.Space:
                            selectCallback?.(optionId);
                            break;
                        default:
                    }
                },
            [selectCallback],
        );

        const renderedOptions = useMemo(
            () =>
                options.map((option, optionId) => (
                    <button
                        type="button"
                        className="onair-dropdown__option body-text"
                        onClick={getOnOptionClickHandler(optionId)}
                        onKeyDown={getOnOptionKeyDownHandler(optionId)}
                        key={`date-dropdown-option-${option}`}
                        role="option"
                        aria-selected={optionId === selected}
                    >
                        {option}
                    </button>
                )),
            [
                options,
                getOnOptionClickHandler,
                getOnOptionKeyDownHandler,
                selected,
            ],
        );

        return (
            <>
                <input
                    type="hidden"
                    {...register(inputName, validationRules)}
                />
                <div
                    className={classNames('onair-dropdown', {
                        'onair-dropdown--open': isOpen,
                        'onair-dropdown--dark': dark,
                    })}
                >
                    {label && (
                        <label
                            htmlFor={id}
                            className={classNames(
                                'onair-dropdown__label body-text',
                                {
                                    'caption-text': smallLabel,
                                },
                            )}
                        >
                            {label}
                        </label>
                    )}
                    <div className="onair-dropdown__wrapper">
                        <button
                            className={classNames(
                                'onair-dropdown__toggle body-text',
                                {
                                    'onair-dropdown--with-label': label,
                                    'onair-dropdown--error': error,
                                    'onair-dropdown--validated': validated,
                                },
                                className,
                            )}
                            ref={toggleRef}
                            type="button"
                            aria-labelledby="to-replace-dropdown-label-id to-replace-dropdown-id"
                            aria-haspopup="listbox"
                            id={id}
                            disabled={disabled}
                            data-testid="dropdown-toggle"
                        >
                            <span>{options[selected]}</span>
                        </button>
                        {/* @ts-ignore */}
                        <CSSTransition
                            in={isOpen}
                            timeout={200}
                            classNames="onair-dropdown__menu"
                        >
                            <ol
                                ref={menuRef}
                                className="onair-dropdown__menu"
                                role="listbox"
                            >
                                {renderedOptions}
                            </ol>
                        </CSSTransition>
                    </div>
                    {error && (
                        <InputError testId={testId ? `${testId}Error` : ''}>
                            {error}
                        </InputError>
                    )}
                </div>
            </>
        );
    },
);

export default Dropdown;
