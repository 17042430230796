import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import { Event, trackProduct } from 'onair_frontend-lib';
import LinkInternal from '../LinkInternal';
import { EventCarouselItemUI } from './EventCarouselItemUI';
import { sharedAnalyticsProperties } from '@/utils/analytics';
import { setItemInLocalStorage } from '@/state/localStorage';

type EventCarouselItemProps = {
    event: Event;
    passedRef?: React.RefObject<HTMLAnchorElement>;
    lastItem?: boolean;
    landscape?: boolean;
    small?: boolean;
    displayDate: boolean;
    title?: string;
};

export const EventCarouselItem: FC<EventCarouselItemProps> = ({
    event,
    passedRef,
    lastItem,
    landscape,
    small,
    displayDate,
    title,
}) => {
    const [isFocusedOrHovered, setIsFocusedOrHovered] = useState(false);
    const handleProductClick = () => {
        setItemInLocalStorage('carouselTitle', title);
        trackProduct({
            ...sharedAnalyticsProperties,
            trackCallback: window?.analytics?.track,
            action: 'Product Clicked',
            url: window.location.href,
            event,
        });
    };

    /**
     * Helper function to detect if the item tile is focused or hovered
     * On 'focus' or 'mouseover' events the isFocusedOrHovered property will be true
     * In the rest of the cases false
     */
    const handleSetIsFocusedOrHovered: React.FocusEventHandler<HTMLAnchorElement> &
        React.MouseEventHandler<HTMLAnchorElement> = useCallback(
        (e): void => {
            setIsFocusedOrHovered(e.type === 'focus' || e.type === 'mouseover');
        },
        [setIsFocusedOrHovered],
    );

    return (
        <LinkInternal
            className={classNames('carousel__item', {
                'carousel__item--last': lastItem,
                'carousel__item--small': small,
            })}
            to={`/${event.slug}/`}
            innerRef={passedRef}
            onClick={handleProductClick}
            onBlur={handleSetIsFocusedOrHovered}
            onFocus={handleSetIsFocusedOrHovered}
            onMouseOver={handleSetIsFocusedOrHovered}
            onMouseLeave={handleSetIsFocusedOrHovered}
        >
            <EventCarouselItemUI
                event={event}
                landscape={landscape}
                displayDate={displayDate}
                isFocusedOrHovered={isFocusedOrHovered}
            />
        </LinkInternal>
    );
};
