import React, { FC } from 'react';

type Props = {
    playerId: string;
};

/**
 *
 * @param playerId Spotify's player id eg: playlist/37i9dQZF1E34YSAadcCxI0
 * @returns Spotify's embedded playlist
 */
export const EmbeddedSpotifyPlayer: FC<Props> = ({ playerId }) => (
    <iframe
        className="spotify-player"
        src={`https://open.spotify.com/embed/${playerId}?utm_source=generator&theme=0`}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
        title="Embedded Spotify"
    />
);
