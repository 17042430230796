export default {
    title: 'Connect a TV',
    description:
        'Enter the code shown on your TV to connect it with your On Air account.',
    error: 'The code you have filled in is not recognised. Please double check the code displayed on your TV and try again. You could also ’get a new code’ on your TV.',
    details:
        'Find your connect code displayed on the screen on the log in page of the On Air Smart TV app.',
    connect_button: 'Connect device',
    successful_connection: 'Your account is successfully connected to your TV.',
};
