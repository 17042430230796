/* eslint-disable @typescript-eslint/no-unsafe-assignment */
'use client';
import React, { useEffect, useMemo, useState } from 'react';
import {
    getCheapestEventVariant,
    getEarliestEventVariant,
    formatDisplayPrice,
    trackViewEventDetails,
} from 'onair_frontend-lib';

import HeroEventInfo from '../hero/HeroEventInfo';
import HeroComponent from '../hero/HeroComponent';
import { useAppSelector, useCountryCodeSelector } from '../../state/hooks';
import useEventIcons from '../../hooks/useVariantIconsPick';
import messages from '../hero/messages';
import { sharedAnalyticsProperties } from '../../utils/analytics';

import type { FC } from 'react';
import type {
    ContentfulEventHero,
    EventDetailsAction,
} from 'onair_frontend-lib';

import { useBreakpoint } from '@/hooks/useBreakpoint';
import { isPortrait } from '@/hooks/useIsPortrait';

const EventHero: FC<{ eventHero: ContentfulEventHero }> = ({ eventHero }) => {
    // Grab the cheapest and earliest for the hero
    const earliestVariant = getEarliestEventVariant(eventHero.event.variants);
    const cheapestVariant = getCheapestEventVariant(eventHero.event.variants);
    const { breakpoint, breakpointSmallerThan } = useBreakpoint();

    const mobileBackgroundImageProps = useMemo(
        () => ({
            imageUrl: eventHero.event.portraitPoster?.asset
                ? (eventHero.event.portraitPoster?.asset as any)?.url!
                : (eventHero.event.poster?.asset as any)?.url!,
            alt: eventHero.event.portraitPoster?.alt
                ? eventHero.event.portraitPoster?.alt!
                : eventHero?.background?.asset?.title!,
        }),
        [eventHero],
    );

    const backgroundImageProps = useMemo(
        () => ({
            imageUrl: (eventHero.background?.asset as any)?.url!,
            alt: eventHero?.background?.alt,
        }),
        [eventHero],
    );

    const [backgroundProps, setBackgroundProps] =
        useState(backgroundImageProps);

    const customerCountryCode = useCountryCodeSelector();

    const { events: purchasedEvents } = useAppSelector(
        ({ tickets }) => tickets,
    );

    const isHeroEventPurchased = purchasedEvents.some(
        (purchasedEvent) => purchasedEvent.slug === eventHero.event.slug,
    );

    // Format value of cheapest
    const cheapestPrice =
        cheapestVariant?.price &&
        formatDisplayPrice(customerCountryCode, cheapestVariant.price);

    const heroEventIcons = useEventIcons(eventHero?.event);

    /**
     *
     * @param buttonMessage - Message of the hero button
     * @returns The appropriate EventDetailsAction string or an empty string if there is no match
     */
    const getTrackingAction = (
        buttonMessage: string,
        // eslint-disable-next-line unicorn/consistent-function-scoping
    ): EventDetailsAction | '' => {
        switch (buttonMessage) {
            case messages.preOrderShow: {
                return 'Pre Order';
            }
            default: {
                return '';
            }
        }
    };

    /**
     * This function is responsible of handling the actions we want to track regarding the hero component
     * @param buttonMessage - Message of the hero button
     */
    const handleHeroTracking = (buttonMessage: string) => {
        const eventDetailsAction = getTrackingAction(buttonMessage);

        // fire the request only if trackingString is populated
        // meaning is a valid tracking string
        if (eventDetailsAction) {
            trackViewEventDetails({
                ...sharedAnalyticsProperties,
                trackCallback: window?.analytics?.track,
                eventDetailsAction,
                event: eventHero.event,
                countryCode: customerCountryCode,
            });
        } else {
            trackViewEventDetails({
                ...sharedAnalyticsProperties,
                trackCallback: window?.analytics?.track,
                eventDetailsAction: 'Hero CTA Clicked',
                event: eventHero.event,
                countryCode: customerCountryCode,
                ctaButtonLabel: buttonMessage,
            });
        }
    };

    useEffect(() => {
        if (breakpointSmallerThan('lg') && isPortrait()) {
            setBackgroundProps(mobileBackgroundImageProps);
        } else {
            setBackgroundProps(backgroundImageProps);
        }
    }, [
        backgroundImageProps,
        breakpoint,
        breakpointSmallerThan,
        isPortrait,
        mobileBackgroundImageProps,
    ]);

    return (
        <HeroComponent
            backgroundImageProps={backgroundProps}
            theme={eventHero.theme}
        >
            <HeroEventInfo
                eventInfoLogo={eventHero.eventInfoLogo}
                // Either use the hero description or the simpleEvents short description
                description={
                    eventHero.description ?? eventHero.event.shortDescription
                }
                eventSlug={eventHero.event.slug}
                hasTrailer={
                    !!eventHero.event.blocks?.find(
                        (block) => block.__type === 'videoBlock',
                    )
                }
                earliestVariant={earliestVariant}
                isHeroEventPurchased={isHeroEventPurchased}
                price={cheapestPrice}
                eventIcons={heroEventIcons}
                theme={eventHero.theme}
                eventType={eventHero.event.type}
                handleHeroTracking={handleHeroTracking}
            />
        </HeroComponent>
    );
};

export default EventHero;
