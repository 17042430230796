'use client';
import { ReactNode, useEffect } from 'react';
import { useAppSelector } from '../state/hooks';
import { navigate } from '@/utils/navigation';
import { createNavigationParams } from '@/state/reducers/navigationSlice';
import { useDispatch } from "react-redux";

type PrivateRouteProps = {
    component: ReactNode;
    returnTo?: string;
    pageContext?: any;
};

const PrivateRoute = ({
    component: Component,
    returnTo,
    pageContext
}: PrivateRouteProps) => {
    const loginStatus = useAppSelector((state) => state.auth.loginStatus);
    const dispatch = useDispatch();

    useEffect(() => {
        if (loginStatus !== 'LOGGED_IN') {
            dispatch(
                createNavigationParams({
                    state: { from: "privateRoute", returnTo },
                })
            )
            // TO DO - Replace with router.push
            navigate('/login/');
        }
    }, [loginStatus, returnTo]);

    // @ts-ignore - TS doesn't like hacking, but we really need to here...
    // eslint-disable-next-line react/jsx-props-no-spreading,react/react-in-jsx-scope
    return <Component pageContext={pageContext}/>;
};

export default PrivateRoute;
