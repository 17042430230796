import React from 'react';
import { NotificationType } from 'onair_frontend-lib';

import EmptyState from './EmptyState';
import Ticket from './Ticket';
import MyTicketsSkeleton from './MyTicketsSkeleton';

import OnAirNotification from '../../OnAirNotification';
import messages from '../messages';
import MyPlaceTitle from '../MyPlaceTitle';

import type { Event, EventsState } from 'onair_frontend-lib';
import type { FC } from 'react';

const renderEvents = (events: Event[]) => {
    const currentDate = new Date();
    return events.map((event) => {
        return event.variants.map((variant) => {
            return (
                <div className="my-4" key={variant.sku}>
                    <Ticket
                        title={event.title}
                        description={event.shortDescription}
                        href={event.slug}
                        imageUrl={event.poster.asset?.url}
                        imageAlt={event.poster.alt}
                        startDate={
                            variant?.eventStart
                                ? new Date(variant?.eventStart)
                                : undefined
                        }
                        endDate={
                            variant?.eventEnd
                                ? new Date(variant?.eventEnd)
                                : undefined
                        }
                        currentDate={currentDate}
                        variantIcons={variant?.icons || []}
                    />
                </div>
            );
        });
    });
};

export interface MyTicketsComponentProps {
    fetchStatus: EventsState['status'];
    futureTickets: Event[];
    pastTickets: Event[];
    error?: EventsState['error'];
}

const MyTicketsComponent: FC<MyTicketsComponentProps> = ({
    error,
    fetchStatus,
    futureTickets,
    pastTickets,
}) => (
    <>
        <MyPlaceTitle testId={messages.my_tickets.title}>
            {messages.my_tickets.title}
            {futureTickets.length > 0 && ` (${futureTickets.length})`}
        </MyPlaceTitle>

        {error?.message && (
            <OnAirNotification
                type={NotificationType.error}
                text={error.message}
                className="mb-3"
            />
        )}

        {fetchStatus === 'IN_PROGRESS' && <MyTicketsSkeleton />}

        {fetchStatus === 'FAILED' && (
            <p className="body-text">
                {messages.my_tickets.error_fetching_tickets}
            </p>
        )}

        {fetchStatus === 'FETCHED' && (
            <>
                {futureTickets.length > 0 ? (
                    renderEvents(futureTickets)
                ) : (
                    <EmptyState />
                )}
                {pastTickets.length > 0 && (
                    <>
                        <MyPlaceTitle testId={messages.my_tickets.title}>
                            {messages.my_tickets.past_events} (
                            {pastTickets.length})
                        </MyPlaceTitle>
                        {renderEvents(pastTickets)}
                    </>
                )}
            </>
        )}
    </>
);

export default MyTicketsComponent;
