/* eslint-disable unicorn/prefer-query-selector */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
'use client';
import React from 'react';
import classNames from 'classnames';
import Image from 'next/image';

import { ContentBlockIcons } from './ContentBlockIcons';

import Button from '../Button';
import Icon from '../Icon';
import { AppStoreBanner } from '../app-store-banner';
import { useBuyTicket } from '../../hooks/useBuyTicket';
import { BUY_BUTTON_ID } from '../constants';

import type {
    ContentfulContentBlock,
    ContentfulEvent,
    ContentfulImageWithGatsbyImageData,
} from 'onair_frontend-lib/src/types';
import type { ButtonProps } from '../../types/button';
import type { ExtendedContentfulLink } from '../../types/link';
import type { ContentfulIcon } from 'onair_frontend-lib';
import type { FC, ReactNode } from 'react';

export interface ContentBlockProps {
    readonly event?: ContentfulEvent;
    readonly title: string;
    readonly subtitle?: string;
    readonly description?: ReactNode;
    readonly image?: ContentfulImageWithGatsbyImageData;
    readonly buttonProps?: ButtonProps;
    readonly imagePosition?: ContentfulContentBlock['imagePosition'];
    readonly links?: ExtendedContentfulLink[];
    readonly showTransparentBackground?: boolean;
    readonly icons?: ContentfulIcon[];
    readonly useH1TagForTitle?: boolean;
    readonly showAppStoreBanners?: boolean;
    readonly socialMediaIconsTitle?: string;
    readonly isFirstBlock?: boolean;
}

const ContentBlockComponent: FC<ContentBlockProps> = ({
    event,
    title,
    subtitle,
    description,
    image,
    buttonProps,
    imagePosition,
    links,
    showTransparentBackground,
    showAppStoreBanners,
    icons,
    useH1TagForTitle = false,
    socialMediaIconsTitle,
    isFirstBlock,
}) => {
    const nextImage = (image?.asset as any)?.url;

    const { cartStatus } = useBuyTicket(event!);

    // Workaround to buy the selected variant
    // it will forward the click to hero's buy button
    const handleBuyTicket = () => {
        const buyButton = document.getElementById(BUY_BUTTON_ID);
        buyButton?.click();
    };

    return (
        <section
            className={classNames('content-block', {
                'content-block--show-transparent-background':
                    isFirstBlock || showTransparentBackground,
                'first-block': isFirstBlock,
            })}
        >
            <div className="container container--vertical-padding">
                <div
                    style={{
                        height: '100%',
                        flex: 1,
                        flexGrow: 1,
                        flexBasis: '100%',
                    }}
                    className={classNames(
                        'flex-column-reverse flex-lg-row row my-md-0',
                        {
                            'flex-lg-row-reverse': imagePosition === 'left',
                            'flex-lg-row': imagePosition !== 'center',
                            'flex-lg-column': imagePosition === 'center',
                            'mx-auto': imagePosition === 'center',
                            'justify-content-center':
                                imagePosition === 'center',
                            'align-items-center': imagePosition === 'center',
                            'text-center': imagePosition === 'center',
                        },
                    )}
                >
                    <div
                        className={classNames(
                            'd-flex container--padding-vertical content-block-container px-1 flex-column my-auto',
                            {
                                'col-12 col-lg-6': nextImage,
                                'col-12 col-lg-8 mx-auto': !nextImage,
                                'justify-content-center':
                                    imagePosition === 'center',
                                'align-items-between':
                                    imagePosition === 'center',
                                'mb-5': imagePosition === 'center',
                                'pe-lg-5':
                                    nextImage && imagePosition === 'right',
                                'ps-lg-5':
                                    nextImage && imagePosition === 'left',
                            },
                        )}
                    >
                        {subtitle && (
                            <div
                                className={classNames(
                                    'content-block__subtitle mb-1',
                                    {
                                        'content-block__subtitle--offset':
                                            imagePosition !== 'center',
                                    },
                                )}
                            >
                                <p>{subtitle}</p>
                            </div>
                        )}
                        {useH1TagForTitle ? (
                            <h1 className="title">{title}</h1>
                        ) : (
                            <h3 className="title">{title}</h3>
                        )}
                        {description && (
                            <div className="description">{description}</div>
                        )}
                        {icons && icons.length > 0 && (
                            <div className="content-block__icons mb-3">
                                {icons.map(({ type: iconName }) => (
                                    <Icon
                                        key={`icon-${iconName}`}
                                        icon={iconName}
                                    />
                                ))}
                            </div>
                        )}
                        {buttonProps?.children && (
                            <Button
                                className="content-block__call-to-action mb-3"
                                onClick={
                                    buttonProps.isBuyButton
                                        ? handleBuyTicket
                                        : buttonProps.onClick
                                }
                                loading={
                                    (buttonProps.isBuyButton &&
                                        cartStatus === 'CREATE_CART_PENDING') ||
                                    (buttonProps.isBuyButton &&
                                        cartStatus === 'ADD_CART_LINES_PENDING')
                                }
                                type={buttonProps.type}
                                ariaLabel={buttonProps.ariaLabel}
                                href={
                                    buttonProps.isBuyButton
                                        ? undefined
                                        : buttonProps.href
                                }
                            >
                                {buttonProps.children}
                            </Button>
                        )}
                        {links && links.length > 0 && (
                            <div
                                className={classNames(
                                    'content-block__link-area d-flex flex-column mb-2',
                                    {
                                        'justify-content-center':
                                            imagePosition === 'center',
                                        'align-items-center':
                                            imagePosition === 'center',
                                        'text-center':
                                            imagePosition === 'center',
                                    },
                                )}
                            >
                                <ContentBlockIcons
                                    links={links}
                                    alignImageCenter={
                                        imagePosition === 'center'
                                    }
                                    socialMediaIconsTitle={
                                        socialMediaIconsTitle
                                    }
                                />
                            </div>
                        )}
                        {showAppStoreBanners && (
                            <AppStoreBanner
                                component="content-block"
                                centered={imagePosition === 'center'}
                            />
                        )}
                    </div>

                    {image?.alt && nextImage && (
                        <div
                            className={classNames(
                                'content-block__image mb-5 mb-lg-0 col-12 col-lg-6 d-flex align-items-center',
                                {
                                    'justify-content-center':
                                        imagePosition === 'center',
                                    'pe-lg-5':
                                        nextImage && imagePosition === 'left',
                                    'ps-lg-5':
                                        nextImage && imagePosition === 'right',
                                },
                            )}
                            style={{
                                width: '100%',
                                flex: 1,
                                // height: '100%',
                            }}
                        >
                            <Image
                                height={696}
                                width={696}
                                className="w-100"
                                src={nextImage}
                                alt={image.alt}
                                // objectFit="fill"
                                style={{
                                    objectFit: 'contain',
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default ContentBlockComponent;
