import * as React from 'react';

export const Office365Logo = () => (
    <svg
        width="24"
        height="20.25"
        viewBox="0 0 24 20.25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.75 16.3351V4.02424L10.4686 0L15.75 1.89304V18.4655L10.4686 20.25L0.75 16.3351L10.4686 17.6458V3.31466L4.13024 4.97198V14.6786L0.75 16.3351Z"
            fill="#EB3C00"
        />
    </svg>
);
