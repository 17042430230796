'use client';
import React, { useEffect } from 'react';
import ForgotPasswordForm from '../../forgot-password';
import Shell from '../../Shell';
import { logPageViewToSegment } from '@/utils/segment';

const ForgotPassword = ({
    pageContext,
    location,
}: {
    pageContext: any;
    location?: Location;
}) => {
    useEffect(() => {
        logPageViewToSegment(`/${pageContext.slug}`);
    }, []);
    return (
        <Shell
            isFooterTopLineDisabled
            ogp={pageContext?.seo}
            pathname={location?.pathname}
        >
            <div
                className="container"
                style={{ marginTop: 200, marginBottom: 200 }}
            >
                <ForgotPasswordForm />
            </div>
        </Shell>
    );
};

export default ForgotPassword;
