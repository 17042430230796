import Image from 'next/image';
import { useCallback, useState } from 'react';

import Button from '../Button';

import type { ReactNode} from 'react';

interface Props {
    thumbnailImageUrl?: string;
    alt?: string;
    children: ReactNode
}
/** 
 * Display an Image with a play button as a workaround to THEO's plyer.poster
 * 
 * Wrap the player with it and render the player only of the play button is pressed.
 * Unfortunately the payer.play() method was nor working as supposed to.
 */
export const ThumbnailImage = ({ thumbnailImageUrl, alt, children }: Props) => {
    const [showThumbnail, setShowThumbnail] = useState(true);

    const thumbnailImage = thumbnailImageUrl ?? '/assets/images/login-register-bg.png'

    const handlePlayPress = useCallback(() => {
        setShowThumbnail(false);
    }, []);

    if (!showThumbnail) return <>{children}</>;

    return (
        <div className="thumbnail-image-container">
            <Button className="play-icon" onClick={handlePlayPress} icon='oa-play' />
            <Image
                fill
                src={thumbnailImage}
                alt={alt ?? ''}
                className='thumbnail-image'
                objectFit="contain"
            />
        </div>
    );
};
