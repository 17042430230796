import React, { FC } from "react";

export const ContactMapLocation: FC = () => (
	<iframe
		className="contact-onair-location"
		src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1480.2037791474063!2d5.288406222355935!3d52.21307800177141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6403854779dd5%3A0xef7e692619df9d56!2sOn%20Air%20B.V.!5e0!3m2!1sen!2snl!4v1665998417247!5m2!1sen!2snl"
		style={{ border: 0 }}
		loading="lazy"
		referrerPolicy="no-referrer-when-downgrade"
		title="address"
	/>
);
