import React, { FC } from "react";
import {
	ButtonType,
	commonTexts,
	parseIdFromShopify,
	parseProductVariantShopifyStringFromID,
	getCountryCurrency,
	formatDisplayPrice,
	EventPrice,
	filterEventMerchandiseByCountry,
} from "onair_frontend-lib";
import {
	useAppDispatch,
	useAppSelector,
	useCountryCodeSelector,
} from "../../../state/hooks";
import { NormalizedAddOns } from "../../../types/cart";
import {
	addCartLinesAction,
	updateCartLinesAction,
} from "../../../state/actions/cartActions";
import Button from "../../Button";
import {
	CART_ITEM_TYPE,
	CART_LINE_ATTRIBUTE,
	MAX_ADD_ONS_ITEM_QUANTITY,
	MAX_SHOWN_ADD_ONS_COUNT,
} from "../constants";

type CartAddOnsProductType = {
	poster: {
		asset: {
			url: string;
		};
		alt?: string;
	};
	price: EventPrice;
	title: string;
	shopifyVariantId: string;
};

const CartAddOnsProduct: FC<CartAddOnsProductType> = ({
	title,
	poster,
	price,
	shopifyVariantId,
}) => {
	const {
		cart,
		status: cartStatus,
		addingAddonsId,
	} = useAppSelector((state) => state.cartSlice);
	const dispatch = useAppDispatch();

	const addOnInCart = cart?.lines.edges.find(
		(line: any) =>
			parseIdFromShopify(line.node.merchandise.id) === shopifyVariantId
	)?.node;

	const customerCountryCode = useCountryCodeSelector();
	const customerCurrency = getCountryCurrency(customerCountryCode);

	const handleAddRelatedProduct = () => {
		// check whether execute add mutation or update mutation
		if (!addOnInCart) {
			// add new addons item to the cart
			const lines = [
				{
					merchandiseId: btoa(
						parseProductVariantShopifyStringFromID(shopifyVariantId)
					),
					quantity: 1,
					attributes: [
						{
							key: CART_LINE_ATTRIBUTE.CART_ITEM_TYPE,
							value: CART_ITEM_TYPE.ADDON,
						},
						{
							key: CART_LINE_ATTRIBUTE.LINE_CREATION_DATE,
							value: new Date().toISOString(),
						},
					],
				},
			];

			if (cart?.id) {
				dispatch(
					addCartLinesAction({
						cartId: cart.id,
						lines,
						shopifyVariantId,
						countryCode: customerCountryCode,
					})
				);
			}
		} else {
			// update existing addons item in the cart
			const lines = [
				{
					quantity: addOnInCart.quantity + 1,
					id: addOnInCart.id,
					attributes: addOnInCart.attributes,
				},
			];

			if (cart?.id) {
				dispatch(
					updateCartLinesAction({
						cartId: cart.id,
						lines,
						shopifyVariantId,
						countryCode: customerCountryCode,
					})
				);
			}
		}
	};

	const posterImage = poster?.asset?.url;

	return (
		<div className="cart__add-ons-item mb-2 col-lg-3 col-md-4 col-sm-6 col-6">
			<div className="cart__add-ons-logo">
				{posterImage && <img src={poster?.asset?.url} alt={poster.alt} />}
			</div>
			<div className="body-title mt-2 mb-1 text-center">{title}</div>
			<div className="caption-text color-accent-lightest mb-2 text-center">
				{price?.[customerCurrency] && (
					<i>{formatDisplayPrice(customerCountryCode, price)}</i>
				)}
			</div>
			<Button
				icon="oa-cart"
				type={ButtonType.OUTLINE_SECONDARY}
				className="cart__add-ons-btn"
				onClick={handleAddRelatedProduct}
				loading={
					(cartStatus === "UPDATE_CART_LINES_PENDING" ||
						cartStatus === "ADD_CART_LINES_PENDING") &&
					addingAddonsId === shopifyVariantId
				}
				disabled={
					(addOnInCart?.quantity || 0) >= MAX_ADD_ONS_ITEM_QUANTITY ||
					cartStatus === "UPDATE_CART_LINES_PENDING" ||
					cartStatus === "ADD_CART_LINES_PENDING"
				}
				grow
			>
				{commonTexts.add}
			</Button>
		</div>
	);
};

export const CartAddOnsProductList: FC<{}> = ({}) => {
	const { cartAddOnsList } = useAppSelector((state) => state.cartSlice);
	const customerCountryCode = useAppSelector(
		(state) => state.geoLocation?.countryCode
	);
	const filteredList = filterEventMerchandiseByCountry(
		customerCountryCode,
		cartAddOnsList
	).slice(0, MAX_SHOWN_ADD_ONS_COUNT);

	return filteredList?.length > 0 ? (
		<>
			<div className="section-title uppercase-first-letter">
				{commonTexts.related_product.concat(
					filteredList?.length > 1 ? "s" : ""
				)}
			</div>
			<div className="cart__add-ons-list mt-3">
				<div className="row">
					{filteredList.map((addOnItem: NormalizedAddOns) => (
						<CartAddOnsProduct
							{...addOnItem}
							key={addOnItem.shopifyVariantId}
						/>
					))}
				</div>
			</div>
		</>
	) : null;
};
