import React from 'react';
import messages from './messages';

const HeroEventConfiguratorBroken = () => (
    <div className="hero__content hero__error">
        <p className="section-title">{messages.stillWorking}</p>
        <p className="subtitle mt-4">{messages.pleaseComeBackLater}</p>
    </div>
);

export default HeroEventConfiguratorBroken;
