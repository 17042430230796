import React from 'react';

import GalleryComponent from './GalleryComponent';

import type { FC } from 'react';
import type { ContentfulGallery } from 'onair_frontend-lib';

const Gallery: FC<{ gallery: ContentfulGallery; isFirstBlock: boolean }> = ({
    gallery,
    isFirstBlock,
}) => (
    <div className="container container--vertical-padding">
        <GalleryComponent
            images={gallery.images}
            preserveAspectRatio={gallery.preserveAspectRatio}
            isFirstBlock={isFirstBlock}
        />
    </div>
);

export default Gallery;
