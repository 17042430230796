import * as React from 'react';

export const OutlookLogo = () => (
    <svg
        width="24"
        height="19.5"
        viewBox="0 0 24 19.5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.08001 8.90167C4.76009 9.38535 4.68392 9.93187 4.68583 10.4974C4.68583 11.0173 4.75057 11.5239 5.01907 11.979C5.24569 12.3675 5.57135 12.6093 6.0398 12.5998C6.50443 12.5903 6.85295 12.3541 7.03385 11.9428C7.17282 11.6248 7.27182 11.2744 7.31563 10.9297C7.3937 10.309 7.34803 9.68812 7.08523 9.1035C6.93103 8.76262 6.69868 8.49037 6.31588 8.39512C5.82268 8.27137 5.37518 8.45415 5.08001 8.90167Z"
            fill="#DAA603"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.6128 20.2481C7.82173 19.4541 4.04588 18.6639 0.256691 17.8717L0.256797 15.2183C0.257062 11.2117 0.257327 7.20519 0.250977 3.19869C0.250977 2.99876 0.304294 2.93021 0.498514 2.89403C3.52066 2.31658 6.54279 1.73154 9.56497 1.14649L9.58686 1.14225C10.0515 1.05091 10.5161 0.961409 10.9878 0.870549C11.1944 0.830763 11.403 0.790577 11.6128 0.75V20.2481ZM8.59101 10.4801C8.58336 9.6879 8.47483 8.95673 8.08258 8.29222C7.76653 7.7571 7.34001 7.35157 6.72493 7.17637C5.52921 6.83558 4.38672 7.39537 3.8231 8.60445C3.46322 9.37943 3.38515 10.202 3.4556 11.0436C3.51654 11.7729 3.72789 12.447 4.1982 13.0239C5.13312 14.1702 6.83353 14.1778 7.78176 13.0391C8.40823 12.2851 8.57961 11.3883 8.59101 10.4801Z"
            fill="#DAA603"
        />
        <path
            d="M12.5441 4.87976C12.5395 4.89424 12.5356 4.90531 12.5324 4.91423C12.5264 4.93113 12.5232 4.94029 12.5232 4.95026C12.5212 6.15949 12.5193 7.36871 12.525 8.57794C12.525 8.64461 12.5879 8.73596 12.6488 8.77601C12.8479 8.90628 13.0492 9.03255 13.2506 9.15888C13.4172 9.26339 13.584 9.36797 13.7495 9.47486C13.8752 9.55676 13.9857 9.55294 14.1075 9.46534C14.2614 9.35577 14.418 9.25159 14.5748 9.14724C14.6469 9.09931 14.7193 9.05113 14.7912 9.00259C15.2329 8.70547 15.6745 8.40822 16.116 8.11098C17.2734 7.33181 18.4307 6.55275 19.59 5.77669C19.7175 5.69291 19.769 5.61679 19.7175 5.46064C19.6014 5.10641 19.3158 4.87976 18.9463 4.87976H12.5441Z"
            fill="#DAA603"
        />
        <path
            d="M12.5308 10.1317V15.3052H18.6259C19.3703 15.3052 19.7493 14.9282 19.7493 14.1798C19.7505 12.6781 19.7501 11.1771 19.7497 9.67649C19.7495 8.9264 19.7493 8.17597 19.7493 7.42595C19.7493 7.3893 19.7456 7.35193 19.7415 7.30973C19.7389 7.284 19.7362 7.25633 19.7341 7.22607L19.687 7.2546C19.6326 7.28755 19.5893 7.31376 19.5475 7.34217C18.6792 7.91942 17.811 8.4962 16.9428 9.07296L16.9375 9.07646L16.9357 9.07767C16.045 9.66941 15.1542 10.2611 14.2635 10.8534C13.9456 11.0647 13.8941 11.0667 13.5856 10.8552C13.4204 10.7414 13.2547 10.628 13.0862 10.5127L13.0839 10.5112C12.9037 10.3878 12.7203 10.2623 12.5308 10.1317Z"
            fill="#DAA603"
        />
    </svg>
);
