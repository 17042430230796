"use client"
import React, { FC } from 'react';
import useRedirectIfLoggedIn from '../../hooks/useRedirectIfLoggedIn';
import ROUTES from '../../constants/routes';
import CheckYourEmailComponent from './component';

const CheckYourEmail: FC = () => {
    useRedirectIfLoggedIn(ROUTES.myPlace);
    return <CheckYourEmailComponent />;
};

export default CheckYourEmail;
