'use client';
import React, { useEffect } from 'react';

import Cart from '../../cart';
import Shell from '../../Shell';
import { logPageViewToSegment } from '@/utils/segment';
const CartPage = ({
    location,
    pageContext,
}: {
    location?: Location;
    pageContext: any;
}) => {
    useEffect(() => {
        logPageViewToSegment(`/${pageContext.slug}`);
    }, []);
    return (
        <Shell
            isFooterTopLineDisabled
            pathname={location?.pathname}
            ogp={pageContext?.seo}
        >
            <Cart />
        </Shell>
    );
};

export default CartPage;
