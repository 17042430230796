import { CheckboxValue } from '../../types/form';

export type PreSerializedRegisterFormData = {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    acceptsMarketing: CheckboxValue;
    acceptsTermsAndConditions: CheckboxValue;
    day: string;
    month: string;
    year: string;
};

export type SerializedRegisterFormData = {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    acceptsMarketing: boolean;
    acceptsTermsAndConditions: boolean;
    day: string;
    month: string;
    year: string;
};

export enum RequestStatus {
    IDLE = 'IDLE',
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}
