import React, { FC } from 'react';
import classNames from 'classnames';
import { HighlightComponent } from './HighlightComponent';
import { ContentfulHighlight } from 'onair_frontend-lib';
import { renderContentfulDescription } from '../../utils/contentful';

export interface HighlightsBlockProps {
    readonly highlights: ContentfulHighlight[];
    readonly showTransparentBackground?: boolean;
    readonly isFirstBlock?: boolean;
}

export const HighlightsBlock: FC<HighlightsBlockProps> = ({
    highlights,
    showTransparentBackground,
    isFirstBlock,
}) => (
    <section
        className={classNames('highlights-block', {
            'highlights-block--show-transparent-background':
                showTransparentBackground,
            'first-block': isFirstBlock,
        })}
    >
        <div className="container">
            {highlights.map((highlight, counter) => (
                <HighlightComponent
                    key={`${highlight.id}-${counter}`}
                    title={highlight.title}
                    text={renderContentfulDescription(highlight.text)}
                    icon={highlight?.icon?.type}
                />
            ))}
        </div>
    </section>
);
