/* eslint-disable no-underscore-dangle */
import React, { FC } from 'react';
import Hero from '../../hero';
import { ContentfulFlexiblePageHero } from '../../../types/contentful';
import EventHero from '../../event-hero';

const FlexiblePageHero: FC<{ hero: ContentfulFlexiblePageHero }> = ({
    hero,
}) => (
    <>
        {/* @ts-ignore TODO */}
        {hero.__typename === 'GenericHero' && <Hero hero={hero} />}
        {/* @ts-ignore TODO */}
        {hero.__typename === 'Hero' && <EventHero eventHero={hero} />}
    </>
);

export default FlexiblePageHero;
