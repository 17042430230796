import React, { useCallback, useEffect, useRef, useState } from 'react';
import { KeyboardKeyCode } from '../../types/keyboard';

const isActiveFocusWithin = <Reference extends HTMLElement>(
    ref: React.RefObject<Reference>,
): boolean => {
    const { activeElement } = document;

    return Boolean(activeElement && ref?.current?.contains(activeElement));
};

interface FocusOnMenuOption<MenuRef extends HTMLElement = HTMLOListElement> {
    menuRef: React.RefObject<MenuRef>;
    position?: 'current' | 'previous' | 'next';
}

const focusOnMenuOption = <MenuRef extends HTMLElement = HTMLOListElement>({
    menuRef,
    position = 'current',
}: FocusOnMenuOption<MenuRef>) => {
    switch (position) {
        case 'current': {
            const currentMenuOption = menuRef?.current?.querySelector(
                '[aria-selected=true]',
            );

            if (currentMenuOption) {
                (currentMenuOption as HTMLElement)?.focus();
            } else {
                (menuRef?.current?.childNodes[0] as HTMLElement)?.focus();
            }
            break;
        }
        case 'previous': {
            const { activeElement } = document;
            if (activeElement && menuRef?.current?.contains(activeElement)) {
                (activeElement.previousSibling as HTMLElement)?.focus();
            }
            break;
        }
        case 'next': {
            const { activeElement } = document;
            if (activeElement && menuRef?.current?.contains(activeElement)) {
                (activeElement.nextSibling as HTMLElement)?.focus();
            }
            break;
        }
        default:
            // eslint-disable-next-line no-console
            console.error(
                new Error(
                    'Something went wrong while navigating through the menu options.',
                ),
            );
    }
};

interface UseDropdownReturn<ToggleRef, MenuRef> {
    toggleRef: React.RefObject<ToggleRef>;
    menuRef: React.RefObject<MenuRef>;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useDropdown = <
    ToggleRef extends HTMLElement = HTMLButtonElement,
    MenuRef extends HTMLElement = HTMLOListElement,
>(): UseDropdownReturn<ToggleRef, MenuRef> => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const toggleRef = useRef<ToggleRef>(null);
    const menuRef = useRef<MenuRef>(null);

    const onToggleKeyDown = useCallback((event: KeyboardEvent) => {
        switch (event.code) {
            case KeyboardKeyCode.Escape:
                setIsOpen(false);
                break;
            case KeyboardKeyCode.Space:
            case KeyboardKeyCode.ArrowUp:
            case KeyboardKeyCode.ArrowDown:
            case KeyboardKeyCode.Enter:
                setIsOpen(true);
                break;
            default:
        }
    }, []);

    const onToggleMouseDown = useCallback((e: { detail: number; }) => {
        // If the event is triggered by the mouse, then detail will be equal to 1
        if (e.detail === 1) {
            // enter key also fires click event listener by default, if check added to prevent entering this code block in that scenario
            setIsOpen((previousValue) => !previousValue);
        }
    }, []);

    useEffect(() => {
        const toggleRefNode = toggleRef?.current;

        toggleRefNode?.addEventListener('keyup', onToggleKeyDown);
        toggleRefNode?.addEventListener('click', onToggleMouseDown);

        return () => {
            toggleRefNode?.removeEventListener('keyup', onToggleKeyDown);
            toggleRefNode?.removeEventListener('click', onToggleMouseDown);
        };
    }, [onToggleMouseDown, onToggleKeyDown]);

    const onMenuKeyDown = useCallback((event: KeyboardEvent) => {
        switch (event.code) {
            case KeyboardKeyCode.Escape:
            case KeyboardKeyCode.Enter:
            case KeyboardKeyCode.Space:
                setIsOpen(false);
                break;
            case KeyboardKeyCode.ArrowUp:
                focusOnMenuOption({ menuRef, position: 'previous' });
                break;
            case KeyboardKeyCode.ArrowDown:
                focusOnMenuOption({ menuRef, position: 'next' });
                break;
            case KeyboardKeyCode.Tab:
            default:
                event.preventDefault();
        }
    }, []);

    const onMenuClick = useCallback((e: { detail: number; }) => {
        // If the event is triggered by the mouse, then detail will be equal to 1
        if (e.detail === 1) {
            //  enter key also fires click event listener by default, if check added to prevent entering this code block in that scenario
            setIsOpen((previousValue) => !previousValue);
        }
    }, []);

    useEffect(() => {
        const menuRefNode = menuRef?.current;

        menuRefNode?.addEventListener('keyup', onMenuKeyDown);
        menuRefNode?.addEventListener('click', onMenuClick);

        return () => {
            menuRefNode?.removeEventListener('keyup', onMenuKeyDown);
            menuRefNode?.removeEventListener('click', onMenuClick);
        };
    }, [onMenuClick, onMenuKeyDown]);

    const onOutsideClick = useCallback(
        ({ target }: MouseEvent) => {
            const isFocusInToggle = toggleRef.current?.contains(target as Node);
            const isFocusInMenu = menuRef.current?.contains(target as Node);

            if (isOpen && target && !isFocusInToggle && !isFocusInMenu) {
                setIsOpen(false);
            }
        },
        [isOpen],
    );
    useEffect(() => {
        document.addEventListener('click', onOutsideClick);

        return () => {
            document.removeEventListener('click', onOutsideClick);
        };
    }, [onOutsideClick]);

    // Takes care of the focus when isOpen changes state
    useEffect(() => {
        // We don't want to trigger any focus change if the user is not interacting with the dropdown
        if (!isActiveFocusWithin(toggleRef) && !isActiveFocusWithin(menuRef)) {
            return;
        }

        if (isOpen) {
            focusOnMenuOption({ menuRef });
        } else {
            toggleRef?.current?.focus();
        }
    }, [isOpen]);

    return {
        toggleRef,
        menuRef,
        isOpen,
        setIsOpen,
    };
};
