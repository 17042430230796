'use client';
import React, { useEffect, useState } from 'react';

import PreviewPageSubmitBlock from './PreviewPageSubmitBlock';
import PreviewPageContent from './PreviewPageContent';

import Shell from '../../Shell';

import type {
    ContentfulFlexiblePageBlock,
    ContentfulStream,
    ContentfulStreamSecurity,
} from 'onair_frontend-lib';
import { logPageViewToSegment } from '@/utils/segment';

const PreviewPage = ({
    location,
    blocks,
    slug,
    streams,
    accessCode,
    streamSecurity,
}: {
    location?: Location;
    blocks?: ContentfulFlexiblePageBlock[];
    streams: ContentfulStream[];
    accessCode: string;
    slug: string;
    streamSecurity: ContentfulStreamSecurity;
}) => {
    const [hasAccess, setHasAccess] = useState(false);
    useEffect(() => {
        logPageViewToSegment(`/preview/${slug}`);
    }, []);
    return (
        <Shell
            isFooterTopLineDisabled
            isNewsletterSignupVisible
            pathname={location?.pathname}
        >
            {hasAccess ? (
                <PreviewPageContent
                    blocks={blocks}
                    streams={streams}
                    streamSecurity={streamSecurity}
                    accessCode={accessCode}
                    slug={slug}
                />
            ) : (
                <PreviewPageSubmitBlock
                    accessCode={accessCode}
                    setHasAccess={(hasAccessValue) =>
                        setHasAccess(hasAccessValue)
                    }
                />
            )}
        </Shell>
    );
};

export default PreviewPage;
