import { Event, EventVariant } from 'onair_frontend-lib';

/**
 * @description it tries to return the purchased variants related to the event supplied
 * @param purchasedEvents
 * @param variants
 */
export const getPurchasedVariantsFromEventVariants = (
    purchasedEvents: Event[],
    variants: EventVariant[],
): EventVariant[] => {
    if (purchasedEvents?.length && variants?.length) {
        const purchasedEventsSkus = purchasedEvents.map(
            (purchasedEvent) => purchasedEvent.variants[0].sku,
        );
        const variantsInCommon = variants.filter((variant) =>
            purchasedEventsSkus.includes(variant.sku),
        );
        return variantsInCommon;
    }
    return [];
};
