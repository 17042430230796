"use client";
import { useEffect, useState } from "react";

export const useBreakpoint = () => {
	const breakpoints = {
		sm: 576,
		md: 768,
		lg: 992,
		xl: 1200,
		xxl: 1320,
	};
	const [breakpoint, setBreakpoint] = useState("");

	useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth;

			if (width < breakpoints.sm) {
				setBreakpoint("sm");
			} else if (width < breakpoints.md) {
				setBreakpoint("md");
			} else if (width < breakpoints.lg) {
				setBreakpoint("lg");
			} else if (width < breakpoints.xl) {
				setBreakpoint("xl");
			} else {
				setBreakpoint("xxl");
			}
		};
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const breakpointSmallerThan = (bp: string) => {
		// @ts-ignore
		return breakpoints[breakpoint] < breakpoints[bp];
	};

	return { breakpoint, breakpointSmallerThan };
};
