import React, { FC } from 'react';
import Button from '../Button';
import messages from './messages';
import EmailSent from '../svgs/EmailSent';
import LinkInternal from '../LinkInternal';

const CheckYourEmailComponent: FC = () => (
    <div className="d-flex flex-column align-items-center">
        <h3 className="subtitle text-center">{messages.title}</h3>
        <div className="my-3">
            <EmailSent />
        </div>
        <h4 className="body-text text-center mb-2">{messages.body}</h4>
        <div
            className="d-flex flex-column justify-content-center mx-auto"
            style={{ maxWidth: '600px' }}
        >
            <Button className="mb-3" onClick={() => window.open('message://')}>
                {messages['primary-cta']}
            </Button>
            <div
                className="caption-text text-center"
                style={{ width: '300px' }}
            >
                <p className="block my-0 mb-1">{messages['help-text']}</p>
                <p className="block my-0">
                    {messages['help-text2']}
                    <LinkInternal
                        className="caption-text"
                        to="/forgot-password"
                    >
                        {messages['try-another-email-link']}
                    </LinkInternal>
                </p>
            </div>
        </div>
    </div>
);

export default CheckYourEmailComponent;
