import * as React from 'react';

export default () => (
    <svg
        width={270}
        height={121}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.319 7.959h90l.556-3.709h-90l-.556 3.709zM14.094 22.793h75l.556-3.709h-75l-.556 3.709zM71.869 37.627h-45l.556-3.709h45l-.556 3.709zM24.644 52.46h30l.556-3.708h-30l-.556 3.709zM82.42 67.295h-45l.555-3.709h45l-.556 3.709zM5.194 82.129h45l.556-3.709h-45l-.556 3.709zM17.97 96.963l.556-3.709h60l-.557 3.709h-60zM31.3 108.089l-.556 3.708h30l.557-3.708h-30z"
            fill="#F7F5F8"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M255.729 4.172h-120c-6.213 0-12.695 4.932-14.478 11.016L95.43 103.315c-1.783 6.084 1.809 11.016 8.022 11.016h120c6.213 0 12.695-4.932 14.478-11.016l25.821-88.127c1.783-6.084-1.809-11.016-8.022-11.016zM136.805.5c-8.285 0-16.927 6.576-19.304 14.688L91.68 103.315c-2.377 8.112 2.412 14.688 10.696 14.688h120c8.285 0 16.927-6.576 19.304-14.688l25.821-88.127C269.878 7.076 265.089.5 256.805.5h-120z"
            fill="#F7F5F8"
        />
        <path
            d="M129.054 48.674h75l1.076-3.672h-75l-1.076 3.672zM126.902 56.018h45l1.076-3.672h-45l-1.076 3.672zM184.75 63.362h-60l1.076-3.672h60l-1.076 3.672zM122.599 70.706h30l1.076-3.672h-30l-1.076 3.672z"
            fill="#E3E3E3"
            fillOpacity={0.5}
        />
        <path
            d="M235.934 30.926h-1.846l-2.516 8.587h1.846l2.516-8.587zM242.518 20.531l-1.259 4.295-.509-4.295h-1.77l-2.516 8.587h1.77l1.258-4.292.51 4.292h1.77l2.516-8.587h-1.77zM235.402 20.531h-1.844c-.951 0-1.952.752-2.222 1.674l-1.535 5.24c-.27.919.288 1.673 1.241 1.673h1.844c.951 0 1.952-.752 2.222-1.674l1.535-5.239c.27-.92-.288-1.674-1.241-1.674zm-2.051 6.869h-1.768l1.509-5.15h1.768l-1.509 5.15zM230.628 30.926h-1.845c-.952 0-1.954.752-2.224 1.674l-2.025 6.913h1.769l1.006-3.435h1.769l-1.006 3.434h1.769l2.026-6.912c.272-.922-.287-1.674-1.239-1.674zm-1.044 3.434h-1.769l.502-1.716h1.77l-.503 1.716zM239.752 36.074c.945-.01 1.933-.759 2.2-1.671l.529-1.804c.269-.92-.288-1.673-1.241-1.673h-1.844c-.951 0-1.951.752-2.221 1.673l-2.025 6.91h1.768l1.005-3.433h.002l.711 3.437 1.823-.008-.707-3.43zm.449-1.715h-1.767l.503-1.716h1.767l-.503 1.716z"
            fill="#FC4D3C"
        />
        <path
            d="M252.308 17.061l-7.594 25.918h-26.467l7.594-25.918h26.467zm.551-1.727H226.3c-.946 0-1.942.757-2.214 1.684l-7.62 26.007c-.271.926.279 1.685 1.227 1.685h26.559c.946 0 1.942-.757 2.214-1.685l7.62-26.007c.272-.927-.279-1.684-1.227-1.684z"
            fill="#FC4D3C"
        />
    </svg>
);
