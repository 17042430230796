import React from 'react';
import { NotificationType } from 'onair_frontend-lib';

import Button from '../../Button';
import ROUTES from '../../../constants/routes';
import LinkInternal from '../../LinkInternal';
import { cartPageTexts } from '../messages';
import Icon from '../../Icon';
import OnAirNotification from '../../OnAirNotification';

import type { FC } from 'react';

const CartEmptyComponent: FC<{ showNotification?: boolean }> = ({
    showNotification,
}) => (
    <div className="cart-empty__wrapper text-center">
        {showNotification && (
            <OnAirNotification
                type={NotificationType.info}
                text={cartPageTexts.abandoned_cart_opened_different_country_msg}
            />
        )}
        <div className="cart-empty__content">
            <Icon icon="oa-cart" />
            <span className="subtitle mt-3">
                {cartPageTexts.empty_cart_title}
            </span>
            <span className="body-text mt-2">
                {cartPageTexts.empty_cart_desc}
            </span>
            <LinkInternal
                to={ROUTES.home}
                className="d-flex justify-content-center"
            >
                <Button className="mt-2 me-0 btn-primary btn-browse-streams">
                    {cartPageTexts.empty_cart_btn_text}
                </Button>
            </LinkInternal>
        </div>
    </div>
);

export default CartEmptyComponent;
