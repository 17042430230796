import React, { FC } from 'react';
import { ContentfulEventVariant } from 'onair_frontend-lib';
import Icon from '../Icon';
import messages from './messages';

type SingleVariantConfiguratorType = {
    variants: ContentfulEventVariant[];
};

const SingleVariantConfigurator: FC<SingleVariantConfiguratorType> = ({
    variants,
}) => {
    // Destruct the only variant of the array
    const [variant] = variants;
    const singleVariantIcons = variant?.verticalIcons ?? variant?.icons;

    // In case there are no variant icons don't render the component
    if (!singleVariantIcons) return null;

    return (
        <div>
            <h4 className="caption-text uppercase pb-1">{messages.quality}</h4>
            {singleVariantIcons.map((icon) => (
                <Icon
                    key={`hero-icon-${icon}`}
                    icon={icon.type}
                    className="me-1 hero__icon"
                />
            ))}
        </div>
    );
};

export default SingleVariantConfigurator;
