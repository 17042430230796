import React from 'react';
import Skeleton from 'react-loading-skeleton';

import SkeletonTheme from '../../SkeletonTheme';

export const TicketSkeleton = () => (
    <div className="ticket ticket-skeleton mb-4">
        <Skeleton borderRadius="10px" className="ticket__image me-2" />

        <div className="d-flex flex-column w-100">
            <Skeleton height="32px" className="mb-1 ticket-skeleton__title" />
            <Skeleton
                height="24px"
                className="mb-1 ticket-skeleton__subtitle"
            />
            <div className="h-100 d-none d-lg-block">
                <Skeleton height="100%" />
            </div>
        </div>
    </div>
);

const MyTicketsSkeleton = () => (
    <>
        <SkeletonTheme>
            <TicketSkeleton />
            <TicketSkeleton />
        </SkeletonTheme>
    </>
);

export default MyTicketsSkeleton;
