export default {
	login: {
		log_in: "Log in",
		title: "Log in",
		log_in_to_buy_ticket:
			"In order to buy tickets please log in or create an account.",
		do_not_have_an_account_yet: "Don't have an account yet?",
		error_occurred_log_back_in:
			"It looks like an error has occurred, please log back in...",
		register_here_link: "Register here",
		forgot_password_question: "Forgot password?",
		submit_button: "Log in",
	},
	register: {
		error_minimum_age_required:
			"In order to register you need to be 13 years or older",
		error_please_accept_our_terms_and_conditions:
			"To register you need to agree with our terms and conditions",
	},
	other: {
		welcome_to_on_air: "Welcome to On Air!",
		register_cta_text: "Register with On Air to buy and stream shows",
		register: "Register",
		create_an_account: "Create an account",
		welcome_back: "Welcome back!",
		login_cta_text: "To access your purchased shows, please log in here",
		delete_account: "You need to log in first in order to delete your account",
	},
};
