// TODO: Add interactive unit tests to this component
import React, { FC } from "react";
import { commonTexts } from "onair_frontend-lib";
import CartListComponent from "../cart-list/CartList";
import CartEmptyComponent from "./CartEmpty";
import CartBottom from "../cart-bottom";
import { CartState } from "../../../state/reducers/cartSlice";

const CartListColumnNames: FC = () => (
	<div className="row product-list__col-names d-none d-md-flex">
		<div className="col-lg-9 col-md-8 col-sm-8 ps-0">
			<span className="body-title uppercase-first-letter">
				{commonTexts.product}
			</span>
		</div>
		<div className="col-lg-1 col-md-1 col-sm-1 uppercase-first-letter">
			<span className="body-title col-name__title">{commonTexts.price}</span>
		</div>
		<div className="col-lg-1 col-md-2 col-sm-2 uppercase-first-letter">
			<span className="body-title col-name__title">{commonTexts.quantity}</span>
		</div>
		<div className="col-lg-1 col-md-1 col-sm-1 uppercase-first-letter">
			<span className="body-title col-name__title">{commonTexts.total}</span>
		</div>
	</div>
);

type CartPageComponentProps = Pick<
	CartState,
	"normalizedCartList" | "showNotification"
>;

const CartPageComponent: FC<CartPageComponentProps> = ({
	normalizedCartList,
	showNotification,
}) => {
	return (
		<div className="container cart-page__wrapper">
			{normalizedCartList?.length ? (
				<div className="title-and-cart-page ">
					<span className="title">Cart</span>
					<CartListColumnNames />
					<CartListComponent normalizedCartList={normalizedCartList} />
					<CartBottom normalizedCartList={normalizedCartList} />
				</div>
			) : (
				<CartEmptyComponent showNotification={showNotification} />
			)}
		</div>
	);
};

export default CartPageComponent;
