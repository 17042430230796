'use client';
import React, { useEffect, useState } from 'react';

import FutureEventWithTicket from './FutureEventWithTicket';
import PastEventNotPurchasedComponent from './PastEventNotPurchased';
import PastEventPurchasedComponent from './PastEventPurchased';
import LiveEventSaleClosedComponent from './LiveEventSaleClosed';
import NotForSale from './NotForSale';
import messages from './messages';
import EventError from './EventError';

import PlayerLoader from '../../../lotties/player-loader/PlayerLoader';
import THEOPlayer from '../../theo-player/THEOPlayer';
import HeroEventConfigurator from '../../hero/HeroEventConfigurator';
import Hero from '../../hero/HeroComponent';

import type { CodedError } from '../../../types/error';
import type { THEOplayerError } from '../../theo-player/types';
import type {
    Event,
    EventStatus,
    EventPartner,
    PlayerEventAction,
    ContentfulImageWithGatsbyImageData,
} from 'onair_frontend-lib';
import type { FC } from 'react';

interface Props {
    event: Event;
    selectedVariantSku?: string;
    isShowRestrictedInCurrentCountry?: boolean;
    partnerToAccessShowInCurrentCountry?: EventPartner;
    // Player and Hero related properties
    eventStatus?: EventStatus;
    doorsOpenDate?: Date;
    eventStart?: Date;
    duration?: number;
    isSaleOpen?: boolean;
    showEventConfigurator?: boolean;
    showPlayer?: boolean;
    showPlayerLoader?: boolean;
    showError?: boolean;
    showPlaceholder?: boolean;
    onPlayerError?: (error: THEOplayerError) => void;
    logTrackPlayEvent?: (duration: number) => void;
    trackPlayerEvents?: (trackAction: PlayerEventAction) => void;
    error?: CodedError;
    hasTicketInShopify?: boolean;
    hasAccess?: boolean;
    loggedIn?: boolean;
    stream?: {
        dashUrl?: string;
        hlsUrl?: string;
        dashCastUrl?: string;
    };
    backgroundImageProps: any;
    trackingTitle?: string;
    shopifyUserId?: string;
    streamUrlsError?: boolean;
    jwt?: string;
}

const HeroWithGeoIpAccess: FC<Props> = ({
    event,
    selectedVariantSku,
    isShowRestrictedInCurrentCountry,
    partnerToAccessShowInCurrentCountry,
    duration,
    showPlaceholder,
    loggedIn = false,
    showPlayer = false,
    showEventConfigurator = false,
    showPlayerLoader = false,
    showError = false,
    error,
    onPlayerError,
    logTrackPlayEvent,
    trackPlayerEvents,
    hasTicketInShopify = false,
    hasAccess = false,
    isSaleOpen = false,
    eventStatus,
    doorsOpenDate,
    eventStart,
    stream: { dashUrl, hlsUrl, dashCastUrl } = {},
    backgroundImageProps,
    trackingTitle,
    shopifyUserId,
    streamUrlsError,
    jwt,
}) => {
    // This logic makes sure we don't get hydration errors
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        setIsLoggedIn(loggedIn);
    }, [loggedIn]);
    return (
        <>
            {showPlaceholder ? (
                <Hero
                    backgroundImageProps={backgroundImageProps}
                    colClassNames="col-12 col-md-8 col-lg-7 col-xl-5"
                    isCentered
                    isEventHero
                >
                    {isLoggedIn ? (
                        <div className="loading-event-info">
                            <HeroEventConfigurator event={event} />
                        </div>
                    ) : undefined}
                </Hero>
            ) : undefined}
            {showError && <EventError error={error!} event={event} />}
            {streamUrlsError ? (
                <EventError
                    error={{
                        code: 'NO_STREAM_URLS',
                        name: messages.no_stream_urls.title,
                        message: messages.no_stream_urls.message,
                    }}
                    event={event}
                />
            ) : (
                (showPlayerLoader || showPlayer) && (
                    <div className="event-page__player-wrapper">
                        {showPlayerLoader && <PlayerLoader />}
                        {showPlayer && (
                            <THEOPlayer
                                sku={selectedVariantSku!}
                                title={event.title}
                                dashUrl={dashUrl}
                                hlsUrl={hlsUrl}
                                dashCastUrl={dashCastUrl}
                                posterUrl={event.poster.asset?.file?.url}
                                onError={onPlayerError}
                                logTrackPlayEvent={logTrackPlayEvent}
                                trackPlayerEvents={trackPlayerEvents}
                                trackingTitle={trackingTitle}
                                shopifyUserId={shopifyUserId}
                                pipEnabled
                                jwt={jwt}
                            />
                        )}
                    </div>
                )
            )}

            {showEventConfigurator && (
                <Hero
                    backgroundImageProps={backgroundImageProps}
                    colClassNames="col-12 col-md-8 col-lg-7 col-xl-5"
                    isEventHero
                >
                    <HeroEventConfigurator
                        event={event}
                        hasTicketInShopify={hasTicketInShopify}
                    />
                </Hero>
            )}
            {!hasAccess &&
                eventStatus === 'LIVE' &&
                !isSaleOpen &&
                !showPlayerLoader && (
                    <Hero
                        backgroundImageProps={backgroundImageProps}
                        colClassNames="col-12 col-md-8 col-lg-7 col-xl-5"
                        isCentered
                        isEventHero
                    >
                        <LiveEventSaleClosedComponent
                            image={
                                event.eventInfoLogo as ContentfulImageWithGatsbyImageData
                            }
                            loggedIn={loggedIn}
                        />
                    </Hero>
                )}

            {hasTicketInShopify && eventStatus === 'FUTURE' && (
                <Hero
                    backgroundImageProps={backgroundImageProps}
                    colClassNames="col-12 col-md-8 col-lg-7 col-xl-5"
                    isCentered
                    isEventHero
                >
                    <FutureEventWithTicket
                        title={event.title}
                        description={event.shortDescription || ''}
                        eventStart={eventStart}
                        duration={duration}
                        doorsOpenDate={doorsOpenDate}
                        selectedVariantSku={selectedVariantSku}
                        image={
                            event.eventInfoLogo as ContentfulImageWithGatsbyImageData
                        }
                    />
                </Hero>
            )}

            {eventStatus === 'PAST' &&
                !hasTicketInShopify && ( // NOT PURCHASED
                    <Hero
                        backgroundImageProps={backgroundImageProps}
                        colClassNames="col-12 col-md-8 col-lg-7 col-xl-5"
                        isCentered
                        isEventHero
                    >
                        <PastEventNotPurchasedComponent
                            image={
                                event.eventInfoLogo as ContentfulImageWithGatsbyImageData
                            }
                            eventTitle={event.title}
                        />
                    </Hero>
                )}

            {eventStatus === 'PAST' &&
                hasTicketInShopify && ( // PURCHASED
                    <Hero
                        backgroundImageProps={backgroundImageProps}
                        colClassNames="col-12 col-md-8 col-lg-7 col-xl-5"
                        isCentered
                        isEventHero
                    >
                        <PastEventPurchasedComponent
                            image={
                                event.eventInfoLogo as ContentfulImageWithGatsbyImageData
                            }
                            eventTitle={event.title}
                        />
                    </Hero>
                )}
        </>
    );
};

export default HeroWithGeoIpAccess;
