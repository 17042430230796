import React from 'react';
import classNames from 'classnames';

import { BackgroundImage } from '../BackgroundImage';

import type { ContentfulEventHeroTheme } from 'onair_frontend-lib';
import type { FC, ReactNode } from 'react';

export interface HeroProps {
    children: ReactNode;
    backgroundImageProps: { imageUrl: string; alt: string }; // TODO: better typing
    colClassNames?: string;
    isCentered?: boolean;
    theme?: ContentfulEventHeroTheme;
    isEventHero?: boolean;
}
const HeroComponent: FC<HeroProps> = ({
    children,
    backgroundImageProps,
    colClassNames = 'col-12 col-md-8 col-lg-7 col-xl-6',
    isCentered = false,
    theme = 'default',
    isEventHero,
}) => (
    <BackgroundImage
        className={classNames('hero-wrapper', `hero-wrapper--theme-${theme}`, {
            'hero-wrapper--event': isEventHero,
            'hero-wrapper--center-content': isCentered,
        })}
        noGradient
        imageUrl={backgroundImageProps?.imageUrl}
        ariaLabel={backgroundImageProps.alt}
    >
        <div className="hero-wrapper__gradient">
            <div className="container h-100">
                <div
                    className={classNames('row h-100', {
                        'justify-content-center': isCentered,
                    })}
                >
                    <div className={colClassNames}>{children}</div>
                </div>
            </div>
        </div>
        <div className="hero-wrapper__bottom-gradient" />
    </BackgroundImage>
);

export default HeroComponent;
