'use client';
import React, { useEffect } from 'react';

import FlexiblePageHero from './FlexiblePageHero';
import FlexiblePageBlock from './FlexiblePageBlock';

import Shell from '../../Shell';

import type {
    ContentfulEventCarousel,
    ContentfulFlexiblePageBlock,
} from 'onair_frontend-lib/src/types/contentful';
import type { EventsByTag, OGP } from '@/types/page-context';
import { logPageViewToSegment } from '@/utils/segment';

const FlexiblePage = ({
    hero,
    blocks,
    eventsByTag,
    carousel,
    ogp,
    seo,
}: {
    hero: any;
    blocks: ContentfulFlexiblePageBlock[];
    eventsByTag: EventsByTag;
    carousel?: ContentfulEventCarousel;
    ogp: OGP;
    seo?: any;
}) => {
    useEffect(() => {
        setTimeout(() => {
            logPageViewToSegment();
        }, 500);
    }, []);

    return (
        <Shell
            isFooterTopLineDisabled
            isNewsletterSignupVisible
            schemaData={{
                carousel,
            }}
            ogp={seo}
        >
            {hero && <FlexiblePageHero hero={hero} />}
            {blocks?.map((block, index) => (
                <FlexiblePageBlock
                    key={`block${index}`}
                    isFirstBlock={index === 0 && !!hero}
                    block={block}
                    eventsByTag={eventsByTag}
                    showTransparentBackground={
                        blocks.length > 1 || index % 2 !== 0
                    }
                />
            ))}
        </Shell>
    );
};

export default FlexiblePage;
