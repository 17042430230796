'use client';
import React, { useEffect } from 'react';
import LoginRegister from '../../login-register/LoginRegister';
import Shell from '../../Shell';
import { logPageViewToSegment } from '@/utils/segment';

const LoginPage = ({ pageContext }: { pageContext: any }) => {
    useEffect(() => {
        logPageViewToSegment(`/${pageContext.slug}`);
    }, []);
    return (
        <Shell isFooterTopLineDisabled ogp={pageContext.seo}>
            <LoginRegister />
        </Shell>
    );
};

export default LoginPage;
