import React, { FC } from "react";
import { ON_AIR_ORGANIZATION_INFO } from "./messages";

export const ContactAddress: FC = () => {
	const { name, address, email, taxID, vatID } = ON_AIR_ORGANIZATION_INFO;
	const { streetAddress, postalCode, addressLocality, addressCountry } =
		address;

	return (
		<div className="contact-address body-text mt-5 mb-5">
			<p>{name}</p>
			<p>{streetAddress},</p>
			<p>
				{postalCode} {addressLocality}, {addressCountry}
			</p>
			<p>The Netherlands Chamber of Commerce (KVK): {taxID}</p>
			<p>VAT number: {vatID}</p>
			<p>E-mail: {email}</p>
		</div>
	);
};
