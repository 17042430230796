/* eslint-disable no-underscore-dangle */
import React from 'react';

import SellingPointsBlock from '../../selling-point';
import TestimonialBlock from '../../testimonial-block';
import Gallery from '../../gallery';
import EventCarousel from '../../event-carousel';
import VideoBlock from '../../video-block';
import { SpotifyPlayerBlock } from '../../spotify-player';
import { HighlightsBlock } from '../../highlights-block';

import type { EventsByTag } from '../../../types/page-context';
import type { ContentfulFlexiblePageBlock } from 'onair_frontend-lib';
import type { FC } from 'react';

import ContentBlock from '@/components/content-block';

const FlexiblePageBlock: FC<{
    block: ContentfulFlexiblePageBlock;
    eventsByTag?: EventsByTag;
    showTransparentBackground: boolean;
    isFirstBlock: boolean;
}> = ({ block, showTransparentBackground, isFirstBlock }) => {
    return (
        <div>
            {block.__type === 'contentBlock' && (
                <ContentBlock
                    contentBlock={block}
                    isFirstBlock={isFirstBlock}
                    showTransparentBackground={showTransparentBackground}
                />
            )}
            {block.__type === 'sellingPointsSection' && (
                <SellingPointsBlock
                    sellingPointsBlock={block}
                    isFirstBlock={isFirstBlock}
                />
            )}
            {block.__type === 'galleryBlock' && (
                <Gallery gallery={block} isFirstBlock={isFirstBlock} />
            )}
            {block.__type === 'testimonialBlock' && (
                <TestimonialBlock block={block} isFirstBlock={isFirstBlock} />
            )}
            {block.__type === 'eventCarouselBlock' && (
                <EventCarousel carousel={block} isFirstBlock={isFirstBlock} />
            )}
            {block.__type === 'videoBlock' && (
                <VideoBlock
                    youtubeLink={block.youtubeLink}
                    autoPlay={false}
                    selfHostedVideo={block.selfHostedVideo}
                    isFirstBlock={isFirstBlock}
                />
            )}
            {block.__type === 'spotifyPlaylist' && (
                <SpotifyPlayerBlock
                    block={block}
                    // @ts-expect-error
                    embeddedPlaylist={block.embeddedPlaylist}
                    callToActionButton={block.callToActionButton}
                    title={block.title}
                    text={block.text}
                    isFirstBlock={isFirstBlock}
                />
            )}
            {block.__type === 'highlightsBlock' && (
                <HighlightsBlock
                    highlights={block.highlights}
                    showTransparentBackground={showTransparentBackground}
                    isFirstBlock={isFirstBlock}
                />
            )}
        </div>
    );
};

export default FlexiblePageBlock;
