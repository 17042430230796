"use client";
import classNames from "classnames";
import { template } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
	ButtonType,
	commonTexts,
	getEnv,
	ValidationErrorCode,
	VALIDATION_ERROR_MESSAGES,
} from "onair_frontend-lib";

import messages from "./messages";

import { useAppSelector } from "../../state/hooks";
import Button from "../Button";
import Icon from "../Icon";
import Input from "../input";

import type { FC } from "react";
import { useGetEnvironmentVariable } from "@/hooks/useGetEnvironmentVariable";

const ConnectForm: FC = () => {
	const [connectCode, setConnectCode] = useState<string[]>(
		Array.from({ length: 6 }).fill("") as string[]
	);
	const [loading, setLoading] = useState<boolean>(false);
	const [connectionSuccessful, setConnectionSuccessful] =
		useState<boolean>(false);
	const [error, setError] = useState('');
	const formRef = useRef<HTMLFormElement>(null);
	const methods = useForm();
	const { register: registerInput, handleSubmit, formState } = methods;
	const { errors: validationErrors } = formState;
	const customerJwt = useAppSelector((state) => state.auth.token?.jwt);

	const { ONAIR_ENDPOINT } = useGetEnvironmentVariable(["ONAIR_ENDPOINT"]);

	const handleSubmitForm = useCallback(async () => {
		setLoading(true);
		// Simulate fake button loading for 5sec
		setTimeout(() => {
			setLoading(false);
		}, 5000);
		const response = await fetch(
			`${ONAIR_ENDPOINT!}/login-handover/acknowledge`,
			{
				method: "POST",
				body: JSON.stringify({
					jwt: customerJwt,
					handoverId: connectCode.join(""),
				}),
				cache: "no-store",
			}
		);

		const { error } = await response.json();
		setConnectionSuccessful(true);
		window.scrollTo(0, 0);

		if (error) {
			setConnectionSuccessful(false);
			setError(error);
			setLoading(false);
		}
	}, [connectCode, customerJwt]);

	const onSubmitForm = handleSubmit(handleSubmitForm);

	useEffect(() => {
		if (connectCode.filter(Boolean).length === 6) onSubmitForm();

		// We do not want onSubmitForm as a dependency here. It is causing unwanted re-renderings.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connectCode]);

	const handleInputFocus = (index: number) => {
		if (formRef.current && index < connectCode.length) {
			(formRef.current[index] as HTMLFormElement).focus();
		}
	};

	const handleBackspace = (index: number) => {
		/**
		 * We are checking if the the input at the specific index is empty or not. If empty then move the focus the previous input,
		 * but if not then backspace has the default behavior (deleting the value on that index).
		 */
		if (!connectCode[index] && index > 0) handleInputFocus(index - 1);
	};

	const handleInputChange = (value: string, index: number) => {
		/**
		 * Backspace and focusing on the previous input also triggers the onChange fn thus we are checking
		 * if the value has a value or nor before running the focusing fn. We are preventing funky behaviors ;)
		 */
		if (value) handleInputFocus(index + 1);
		const updatedCode = [...connectCode];
		updatedCode[index] = value[0]?.toUpperCase() ?? "";
		setConnectCode(updatedCode);
	};

	return (
		<div className="container">
			<div className="col-12 col-lg-7 connect-page d-flex flex-column align-items-center">
				<h3 className="subtitle mb-3">{messages.title}</h3>
				{connectionSuccessful ? (
					<div className="body-text col-md-7 col-12 text-center">
						{messages.successful_connection}
					</div>
				) : (
					<>
						<div className="body-text mb-3">{messages.description}</div>
						<div className="d-flex connect-inputs flex-column align-items-center justify-content-between w-100">
							<FormProvider {...methods}>
								<form
									autoComplete="off"
									ref={formRef}
									onSubmit={onSubmitForm}
									className="m-0 flex-row d-flex justify-content-between w-100"
								>
									{Array.from({ length: 6 })
										.fill("")
										.map((_, index) => (
											<Input
												className="input"
												noPadding
												disabled={loading}
												value={connectCode[index]}
												maxLength={1}
												onBackspace={() => handleBackspace(index)}
												autoGeneratedProps={registerInput(`code${index}`, {
													onChange: (e) =>
														handleInputChange(e.target.value, index),
													required: {
														value: true,
														message: template(
															VALIDATION_ERROR_MESSAGES[
																ValidationErrorCode.required
															]
														)({
															field: commonTexts.code,
														}),
													},
												})}
												error={!!validationErrors[`code${index}`]?.message}
												// eslint-disable-next-line react/no-array-index-key
												key={`index${index}`}
											/>
										))}
								</form>
							</FormProvider>
							{error && (
								<div className="body-text mt-3 col-12 connect-page__error d-flex caption-text">
									<Icon icon={classNames("oa-warning", "warning-icon")} />
									<div>{error}</div>
								</div>
							)}
						</div>
						<div
							className="d-flex connect-page__button-container flex-column justify-content-center
 text-center col-lg-6 col-md-7 col-12"
						>
							<Button
								className="mt-3 connect-button"
								type={ButtonType.SOLID_PRIMARY}
								loading={loading}
								onClick={onSubmitForm}
							>
								{messages.connect_button}
							</Button>
						</div>
						<div className="body-text mt-3 col-md-7 col-12 text-center">
							{messages.details}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default ConnectForm;
