import React, { FC } from 'react';
import { ContentfulTestimonialBlock } from 'onair_frontend-lib';
import TestimonialBlockComponent from './TestimonialBlockComponent';

const TestimonialBlock: FC<{
    block: ContentfulTestimonialBlock;
    isFirstBlock: boolean;
}> = ({ block, isFirstBlock }) => (
    <TestimonialBlockComponent
        key={block.id}
        title={block.title}
        testimonials={block.testimonials}
        landscape
        isFirstBlock={isFirstBlock}
    />
);

export default TestimonialBlock;
