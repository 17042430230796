import * as React from 'react';

export const YahooLogo = () => (
    <svg
        width="24"
        height="17.04"
        viewBox="0 0 24 17.04"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.81795 10.0524L5.68475 17.2941C6.10783 17.2193 6.46633 17.1814 6.76025 17.1814C7.03993 17.1814 7.39843 17.2193 7.83575 17.2941L7.70263 10.0524C7.97608 9.5812 8.55073 8.5723 9.42853 7.02565C10.3063 5.479 11.0449 4.20062 11.6461 3.1897C12.2473 2.17872 12.865 1.19951 13.5001 0.25C13.1037 0.352428 12.7349 0.403645 12.3938 0.403645C12.0999 0.403645 11.7209 0.352428 11.2569 0.25C10.8267 1.00797 10.3709 1.79154 9.88948 2.60073C9.40805 3.4099 8.83753 4.3543 8.17888 5.43287C7.5203 6.51145 7.04705 7.2868 6.76025 7.75803C6.50728 7.3411 6.1334 6.73577 5.6387 5.93988C5.14393 5.14405 4.74245 4.49568 4.43515 3.99378C4.12786 3.49188 3.76936 2.8906 3.35965 2.19102C2.94994 1.49143 2.58427 0.844083 2.26366 0.25C1.89493 0.345258 1.52926 0.3934 1.16768 0.3934C0.785625 0.3934 0.396398 0.345258 0 0.25C0.191542 0.51631 0.396397 0.818477 0.614572 1.15649C0.832747 1.4945 1.00175 1.76286 1.1216 1.96055C1.24143 2.15824 1.434 2.48601 1.70031 2.94385C1.96663 3.40172 2.13359 3.68853 2.20221 3.80425C3.19884 5.49738 4.40442 7.5808 5.81795 10.0524Z"
            fill="#4A089F"
        />
    </svg>
);
