import React, { FC } from 'react';
import { ContentfulSellingPoint } from 'onair_frontend-lib';
import EventSellingPointComponent from './SellingPoint';
import { renderContentfulDescription } from '../../utils/contentful';
import classNames from 'classnames';

export type SellingPointsSectionProps = {
    title?: string;
    sellingPoints: ContentfulSellingPoint[];
    showTitle: boolean;
    isFirstBlock?: boolean;
};

const SellingPointsSectionComponent: FC<SellingPointsSectionProps> = ({
    title,
    sellingPoints,
    showTitle,
    isFirstBlock,
}) => (
    <div
        className={classNames('container container--vertical-padding', {
            'first-block': isFirstBlock,
        })}
    >
        {showTitle && title && <h3 className="title mb-3">{title}</h3>}
        <div className="event-selling-points-wrap">
            {sellingPoints.map((item) => (
                <EventSellingPointComponent
                    key={item.title}
                    title={item.title}
                    description={renderContentfulDescription(item.description)}
                    icon={item?.icon?.type}
                />
            ))}
        </div>
    </div>
);

export default SellingPointsSectionComponent;
