import React, { FC, useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';

type Props = {
    upAnimation: boolean;
    children: any
};

/**
 *
 * @description Wrapper used to pass animation used by sticky cart (mobile screens)
 *
 * @param upAnimation Boolean indicating if the animation will be down
 * @param children
 */
export const StickyCartAnimation: FC<Props> = ({ upAnimation, children }) => {
    const stickyComp = useRef(null);

    useLayoutEffect(() => {
        const timeline = gsap.timeline();

        timeline.to(stickyComp.current, {
            y: upAnimation ? 0 : 160,
            bottom: upAnimation ? 0 : -160,
            opacity: 1,
            duration: 0.6,
        });
    }, [upAnimation]);

    // Run only the first time this component is rendered
    // used to hide the element at the initial render and if the animation is down
    useLayoutEffect(() => {
        const timeline = gsap.timeline();

        timeline.to(stickyComp.current, {
            opacity: 0,
            bottom: -160,
            duration: 0,
        });
    }, []);

    return (
        <div ref={stickyComp} className="sticky-cart">
            <>{children}</>
        </div>
    );
};
