'use client';
import React, { FC, useRef, useEffect } from 'react';
import { ContentfulVideo, parseYoutubeLink } from 'onair_frontend-lib';
import YoutubePlayer from '../YoutubePlayer';
import THEOPlayer from '../theo-player/THEOPlayer';
import classNames from 'classnames';
import { ThumbnailImage } from '../theo-player/ThumbnailImage';

export type Props = ContentfulVideo & {
    autoPlay?: boolean;
    isFirstBlock?: boolean;
};

const AUTO_SCROLL_MINUS_VALUE = 128; // The watch-trailer scrolls to the element but a few pixels before it this value is how much pixels before the container elem

const VideoBlockComponent: FC<Props> = ({
    youtubeLink,
    autoPlay,
    selfHostedVideo,
    isFirstBlock,
}) => {
    const youtubeRef = useRef<null | HTMLDivElement>(null);
    const youtubeId = parseYoutubeLink(youtubeLink ?? '')?.videoId;

    const thumbnailImageUrl = selfHostedVideo?.thumbnailImage?.asset?.url;
    const thumbnailImageAltText =
        selfHostedVideo?.thumbnailImage?.asset?.description ??
        selfHostedVideo?.thumbnailImage?.asset?.title;

    // Scroll to the correct spot for YouTube if the watch-trailer query param is set
    useEffect(() => {
        if (typeof window !== 'undefined' && autoPlay) {
            const offsetTop = youtubeRef?.current?.offsetTop;
            if (offsetTop)
                window.scrollTo(0, offsetTop - AUTO_SCROLL_MINUS_VALUE);
        }
    }, [youtubeLink, autoPlay]);

    if (!youtubeId && !selfHostedVideo) return null;

    return (
        <div
            className={classNames('container container--vertical-padding', {
                'first-block': isFirstBlock,
            })}
            ref={youtubeRef}
        >
            {selfHostedVideo ? (
                <div
                    className={classNames({
                        'contentblock-video-container youtube-player':
                            selfHostedVideo,
                    })}
                >
                    <ThumbnailImage
                        thumbnailImageUrl={thumbnailImageUrl}
                        alt={thumbnailImageAltText}
                    >
                        <THEOPlayer
                            selfHostedVideo={true}
                            hlsUrl={selfHostedVideo?.hlsUrl}
                            dashUrl={selfHostedVideo?.dashUrl}
                            dashCastUrl={selfHostedVideo?.dashCastUrl}
                            pipEnabled={false}
                            trackingTitle={selfHostedVideo.title}
                            posterUrl={thumbnailImageUrl}
                        />
                    </ThumbnailImage>
                </div>
            ) : (
                <YoutubePlayer youtubeId={youtubeId!} autoPlay={autoPlay} />
            )}
        </div>
    );
};

export default VideoBlockComponent;
