/* eslint-disable unicorn/no-negated-condition */
'use client';
import React, { useEffect } from 'react';

import ROUTES from '../../../constants/routes';
import ConnectForm from '../../connect';
import PrivateRoute from '../../PrivateRoute';
import Shell from '../../Shell';
import { logPageViewToSegment } from '@/utils/segment';

const ConnectPage = ({
    pageContext,
    location,
}: {
    pageContext: any;
    location?: Location;
}) => {
    useEffect(() => {
        logPageViewToSegment('/connect');
    }, []);
    return (
        <Shell
            isFooterTopLineDisabled
            ogp={pageContext?.seo}
            pathname={location?.pathname}
        >
            <ConnectForm />
        </Shell>
    );
};

const returnToUrl =
    typeof window !== 'undefined'
        ? `${ROUTES.connect}${window?.location?.search}`
        : undefined;

//@ts-ignore
export default ({ pageContext }: { pageContext: any }) => (
    <PrivateRoute
        //@ts-ignore
        component={ConnectPage}
        pageContext={pageContext}
        returnTo={returnToUrl}
    />
);
