'use client';
import React, { useEffect } from 'react';

import { SearchPageComponent } from './SearchComponent';

import type { ContentfulEventCarousel } from 'onair_frontend-lib';
import type { EventsByTag } from '@/types/page-context';
import { logPageViewToSegment } from '@/utils/segment';

const SearchPage = ({
    location,
    carousels,
    eventsByTag,
}: {
    location?: Location;
    carousels?: ContentfulEventCarousel[];
    eventsByTag?: EventsByTag;
}) => {
    useEffect(() => {
        logPageViewToSegment(`/search`);
    }, []);
    return (
        <SearchPageComponent
            location={location}
            carousels={carousels}
            eventsByTag={eventsByTag}
        />
    );
};

export default SearchPage;
