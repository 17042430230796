//@ts-nocheck TODO
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
	profileActions,
	changeProfileAction,
	LinkType,
} from "onair_frontend-lib";

import ProfileSettingsComponent from "./ChangeProfileComponent";

import ROUTES from "../../../../constants/routes";
import { useAppSelector } from "../../../../state/hooks";
import { serializeForm } from "../../../../utils/form";
import myPlaceMessages from "../../messages";

import type { NotificationObject, ProfileStatus } from "onair_frontend-lib";
import type { FC } from "react";
import type { LinkBase } from "../../../../types/link";
import type { SerializedFormDataType } from "./ChangeProfileTypes";

import { isObjectEmpty } from "@/utils/object";

export const links: LinkBase[] = [
	{
		text: myPlaceMessages.profile_settings.change_profile.change_password_link,
		to: `${ROUTES.myPlace}${ROUTES.profileSettings}${ROUTES.changePassword}`,
		icon: "oa-lock",
		type: LinkType.LINK_TERTIARY,
	},
	{
		text: myPlaceMessages.profile_settings.change_profile.change_email_link,
		to: `${ROUTES.myPlace}${ROUTES.profileSettings}${ROUTES.changeEmail}`,
		icon: "oa-chevron-right",
		type: LinkType.LINK_TERTIARY,
	},
];

const ChangeProfile: FC<{
	path: string;
}> = () => {
	const formMethods = useForm();
	const {
		register: registerInput,
		handleSubmit,
		formState: { errors },
	} = formMethods;

	const customerInfo = useAppSelector(
		(state) => state.auth.token?.customerInfo
	);
	const customerAccessToken = useAppSelector(
		(state) => state.auth.token?.customerAccessToken
	);
	const notification: NotificationObject | undefined = useAppSelector(
		({ profile }) => profile.notification
	);
	const status: ProfileStatus | undefined = useAppSelector(
		({ profile }) => profile.status
	);

	const dispatch = useDispatch();

	// To clean any previous status/notification when leaving the page
	useEffect(
		() => () => {
			dispatch(profileActions.resetState());
		},
		[dispatch]
	);

	const [serializedFormData, setSerializedFormData] =
		useState<SerializedFormDataType>({});
	const [acceptsMarketing, setAcceptsMarketing] = useState<boolean>(
		customerInfo?.acceptsMarketing || false
	);

	const formRef = useRef<HTMLFormElement>(null);

	const handleChangeForm = useCallback(() => {
		if (formRef.current) {
			const formElement: HTMLFormElement = formRef.current;
			const serializedData = serializeForm(formElement);
			setSerializedFormData({
				...serializedData,
				acceptsMarketing: serializedData.acceptsMarketing === "on",
			});
		}
	}, [setSerializedFormData]);

	const onSubmitForm = useCallback(() => {
		dispatch(profileActions.resetState());
		if (
			isObjectEmpty(errors) &&
			!isObjectEmpty(serializedFormData) &&
			customerAccessToken
		) {
			dispatch(
				changeProfileAction({
					customerAccessToken,
					customer: {
						email: customerInfo?.email,
						firstName: serializedFormData.firstName,
						lastName: serializedFormData.lastName,
						acceptsMarketing: serializedFormData.acceptsMarketing,
					},
				})
			);
		}
	}, [errors, serializedFormData, customerAccessToken, dispatch, customerInfo]);

	return (
		<ProfileSettingsComponent
			notification={notification}
			onChangeForm={handleChangeForm}
			formRef={formRef}
			customerInfo={customerInfo}
			validationErrors={errors}
			acceptsMarketing={acceptsMarketing}
			setAcceptsMarketing={setAcceptsMarketing}
			isLoading={status === "CUSTOMER_UPDATE_PENDING"}
			links={links}
			serializedFormData={serializedFormData}
			onSubmitForm={handleSubmit(onSubmitForm)}
			registerInput={registerInput}
			formMethods={formMethods}
		/>
	);
};

export default ChangeProfile;
