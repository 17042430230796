import React, { ChangeEvent, FC } from 'react';
import {
    NotificationObject,
    ValidationErrorCode,
    ValidationError,
} from 'onair_frontend-lib';
import Button from '../Button';
import Input from '../input';
import OnAirNotification from '../OnAirNotification';
import messages from './messages';

type ChangePasswordComponentProps = {
    onPasswordChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onSubmitForm: () => void;
    notification?: NotificationObject;
    isLoading?: boolean;
    error?: ValidationError;
};

const ChangePasswordComponent: FC<ChangePasswordComponentProps> = ({
    onPasswordChange,
    onSubmitForm,
    notification,
    isLoading,
    error,
}) => (
    <div className="d-flex flex-column mx-auto" style={{ maxWidth: '600px' }}>
        <h3 className="subtitle text-center mb-3">{messages.title}</h3>
        {notification && (
            <OnAirNotification
                className="mb-3"
                text={notification.text}
                type={notification.type}
            />
        )}
        <div className="d-flex flex-column justify-content-center">
            <Input
                className="mb-3"
                label={messages['new-password-label']}
                icon="oa-lock"
                type="password"
                onChange={onPasswordChange}
                onEnter={onSubmitForm}
                noPadding
                error={
                    error?.code === ValidationErrorCode.emptyPassword &&
                    error.message
                }
                testId="newPasswordInput"
            />
            <Button
                className="mb-2 me-0"
                onClick={onSubmitForm}
                loading={isLoading}
                testId="submitButton"
            >
                {messages['primary-cta']}
            </Button>
        </div>
    </div>
);

export default ChangePasswordComponent;
