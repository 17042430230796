// 'use client';
import React from 'react';
import { ButtonType, mapContentfulButtonTypeToEnum } from 'onair_frontend-lib';

import HeroTitle from './HeroTitle';
import HeroComponent from './HeroComponent';

import Button from '../Button';

import type { FC } from 'react';
import type { ContentfulHero } from 'onair_frontend-lib';

const Hero: FC<{ hero: ContentfulHero }> = ({ hero }) => {
    const { background, title, button } = hero;
    const backgroundImageProps = {
        imageUrl: (background?.asset as any)?.url as string,
        alt: background?.alt,
    };

    return (
        <HeroComponent backgroundImageProps={backgroundImageProps}>
            <div className="hero__content">
                <HeroTitle>{title}</HeroTitle>
                {button?.text && (
                    <div className="mt-5 d-flex">
                        <Button
                            className="hero__generic-button"
                            type={
                                button.type
                                    ? mapContentfulButtonTypeToEnum[button.type]
                                    : ButtonType.SOLID_SECONDARY
                            }
                            hrefInternal={button.href}
                        >
                            {button.text}
                        </Button>
                    </div>
                )}
            </div>
        </HeroComponent>
    );
};

export default Hero;
