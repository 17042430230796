import Image from 'next/image';
import React, { FC } from 'react';
import ROUTES from '../../../constants/routes';
import LinkInternal from '../../LinkInternal';
import messages from './messages';
import { ContentfulImageWithGatsbyImageData } from 'onair_frontend-lib';

type LiveEventSaleClosedProps = {
    image?: ContentfulImageWithGatsbyImageData;
    loggedIn?: boolean;
};

const LiveEventSaleClosedComponent: FC<LiveEventSaleClosedProps> = ({
    image,
    loggedIn,
}) => {
    const {
        live_event_sale_closed: {
            title,
            subtitle,
            login_title,
            login_body_text,
        },
    } = messages;

    // @ts-ignore TODO
    const eventInfoImage = image && image?.asset?.url;

    return (
        <div className="live-event-sale-closed-wrapper hero__content">
            {eventInfoImage && (
                <Image
                    fill
                    src={eventInfoImage}
                    alt={image?.alt || ''}
                    className="hero__event-image mx-auto"
                    objectFit="contain"
                />
            )}
            <p className="mt-4 mb-2 mb-md-3 title">{title}</p>
            <p className="mb-2 mb-md-3 body-text subtitle-text">{subtitle}</p>
            {!loggedIn && (
                <>
                    <p className="body-title">{login_title}</p>
                    <p className="body-title">
                        <LinkInternal to={ROUTES.login}>
                            {login_body_text}
                        </LinkInternal>
                    </p>
                </>
            )}
        </div>
    );
};

export default LiveEventSaleClosedComponent;
