import Image from 'next/image';
import React, { FC } from 'react';
import {
    ContentfulImageWithGatsbyImageData,
    LinkType,
} from 'onair_frontend-lib';
import { SocialMediaType } from 'onair_frontend-lib';
import { getUrlOfSocialMediaPost } from 'onair_frontend-lib';
import LinkExternal from '../../LinkExternal';
import messages from './messages';

type PastEventProps = {
    image?: ContentfulImageWithGatsbyImageData;
    eventTitle: string;
};

const PastEventPurchasedComponent: FC<PastEventProps> = ({
    image,
    eventTitle,
}) => {
    const {
        past_event_purchased: { title, body_title, body_text },
    } = messages;

    const eventInfoImage = image && image?.asset?.file?.url;

    return (
        <div className="past-event-purchased-wrapper hero__content">
            {eventInfoImage && (
                <Image
                    fill
                    src={eventInfoImage}
                    alt={image?.alt || ''}
                    className="hero__event-image mx-auto"
                    objectFit="contain"
                />
            )}
            <p className="mt-4 subtitle">{title}</p>
            <p className="mt-2 mt-md-3 body-text">
                {body_title} <b> {eventTitle} </b> ?{' '}
            </p>
            <p className="mt-2 mb-2 mt-md-3 body-text">{body_text} </p>
            <div className="past-event-social-links">
                <LinkExternal
                    to={getUrlOfSocialMediaPost(
                        eventTitle,
                        SocialMediaType.FACEBOOK,
                    )}
                    icon="oa-facebook"
                    type={LinkType.LINK_STYLED_IN_COMPONENT}
                    className="fb-share-link socials-share-link"
                >
                    Share
                </LinkExternal>
                <LinkExternal
                    to={getUrlOfSocialMediaPost(
                        eventTitle,
                        SocialMediaType.TWITTER,
                    )}
                    icon="oa-twitter"
                    type={LinkType.LINK_STYLED_IN_COMPONENT}
                    className="socials-share-link"
                >
                    Tweet
                </LinkExternal>
            </div>
        </div>
    );
};

export default PastEventPurchasedComponent;
