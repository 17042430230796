import * as React from 'react';

export const AppleLogo = () => (
    <svg
        width="24"
        height="23.25"
        viewBox="0 0 24 23.25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.6594 12.352C15.6918 15.8705 18.7165 17.0413 18.75 17.0563C18.7244 17.1388 18.2667 18.7248 17.1565 20.363C16.1966 21.7794 15.2005 23.1905 13.6314 23.2197C12.0895 23.2484 11.5937 22.2966 9.83093 22.2966C8.06865 22.2966 7.51777 23.1905 6.0582 23.2484C4.54357 23.3064 3.39015 21.7169 2.42244 20.3057C0.444968 17.4192 -1.06623 12.149 0.962925 8.59155C1.97097 6.82494 3.77242 5.70623 5.72775 5.67754C7.21508 5.64889 8.61892 6.68786 9.52822 6.68786C10.4369 6.68786 12.1429 5.43842 13.9364 5.62191C14.6872 5.65346 16.7948 5.92813 18.148 7.92817C18.0389 7.99642 15.6333 9.4104 15.6594 12.352ZM12.7616 3.71221C13.5658 2.72942 14.107 1.36129 13.9594 0C12.8002 0.0470368 11.3987 0.779865 10.5673 1.76212C9.82215 2.63194 9.16965 4.02416 9.34567 5.3585C10.6376 5.45942 11.9575 4.69562 12.7616 3.71221Z"
            fill="#606060"
        />
    </svg>
);
