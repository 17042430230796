import Image from 'next/image';
import React, { FC } from 'react';
import {
    NotificationType,
    LinkType,
    ContentfulImageWithGatsbyImageData,
} from 'onair_frontend-lib';
import Button from '../../Button';
import LinkExternal from '../../LinkExternal';
import OnAirNotification from '../../OnAirNotification';
import messages from './messages';

type OnlyForSaleWithPartnerProps = {
    image?: ContentfulImageWithGatsbyImageData;
    partnerName?: string;
    partnerLink?: string;
};

const OnlyForSaleWithPartner: FC<OnlyForSaleWithPartnerProps> = ({
    image,
    partnerName,
    partnerLink,
}) => {
    const {
        only_for_sale_with_partner: { buy_from_partner_text },
    } = messages;

    const eventInfoImage = image && image?.asset?.file?.url;

    // In case the link is populated in Contenful but not the partner's name use not_for_sale as fallback
    const notificationText = partnerName ?? messages.not_for_sale.title;

    return (
        <div className="event-country-availability hero__content">
            {eventInfoImage && (
                <Image
                    fill
                    src={eventInfoImage}
                    alt={image?.alt || ''}
                    className="hero__event-image mx-auto"
                    objectFit="contain"
                />
            )}
            <OnAirNotification
                text={notificationText.toUpperCase()}
                type={NotificationType.info}
                className="event-country-availability__items-width mt-4"
            />
            {!!partnerLink && (
                <div>
                    <LinkExternal
                        to={partnerLink}
                        className="socials-share-link-button"
                        type={LinkType.LINK_STYLED_IN_COMPONENT}
                    >
                        <Button className="btn-onair follow-onair-instagram-btn event-country-availability__items-width mt-3 mt-md-4">
                            {buy_from_partner_text}
                        </Button>
                    </LinkExternal>
                </div>
            )}
        </div>
    );
};

export default OnlyForSaleWithPartner;
