/* eslint-disable unicorn/prevent-abbreviations */
'use client';
import React, { useEffect } from 'react';
import { useParams } from 'next/navigation';

import ChangePasswordForm from '../../change-password';
import Shell from '../../Shell';

import type { FC } from 'react';
import { logPageViewToSegment } from '@/utils/segment';

export interface ChangePasswordProps {
    // params: {
    // 	userId: string;
    // 	resetToken: string;
    // };
    pageContext: any;
    location?: Location;
}

const ChangePassword: FC<ChangePasswordProps> = ({
    // params,
    pageContext,
    location,
}) => {
    const params = useParams();
    const userId = params?.id?.[1];
    const resetToken = params?.id?.[2];
    useEffect(() => {
        logPageViewToSegment(`/${pageContext.slug}`);
    }, []);

    if (!userId || !resetToken) return null;

    return (
        <Shell
            isFooterTopLineDisabled
            pathname={location?.pathname}
            ogp={pageContext?.seo}
        >
            <div
                className="container"
                style={{ marginTop: 200, marginBottom: 200 }}
            >
                <ChangePasswordForm userId={userId} resetToken={resetToken} />
            </div>
        </Shell>
    );
};

export default ChangePassword;
