import { useEffect } from 'react';
import { Player } from 'theoplayer';
import { PlayerEventAction } from 'onair_frontend-lib';

/**
 *
 * @description It accepts the THEO player's instance and the callback track methods as arguments. It will
 * initialize all the necessary listeners to keeps track of the 'play', 'pause', 'air_play' or 'cast ' a video.
 *
 * @param player THEOPlayer's player instance with the loaded video
 * @param logTrackPlayEvent Function sending 'play_event' logs to Segment
 * @param trackPlayerEvents Function sending 'pause_event', 'air_play_event' and 'cast_event'
 * logs to Segment
 */
export const usePlayerAnalytics = (
    player?: Player,
    logTrackPlayEvent?: (duration: number) => void,
    trackPlayerEvents?: (trackAction: PlayerEventAction) => void,
) => {
    useEffect(() => {
        if (!player) return;

        if (logTrackPlayEvent) {
            const handleTrackPlay = () => {
                player.removeEventListener('playing', handleTrackPlay);
                // Send track playing log to Segment as soon as the video start playing
                logTrackPlayEvent(player.duration);
            };
            player.addEventListener('sourcechange', () => {
                player.removeEventListener('playing', handleTrackPlay);
                player.addEventListener('playing', handleTrackPlay);
            });
        }

        // Send track pause log to Segment each time the video is paused
        if (trackPlayerEvents) {
            player.addEventListener('pause', () =>
                trackPlayerEvents('Pause Event'),
            );
        }
        // Check if chromecast is available and init a listener
        if (player?.cast?.chromecast && trackPlayerEvents) {
            player.cast.chromecast.addEventListener('statechange', (event: { state: string; }) => {
                if (event.state === 'connected') {
                    // Send the track request only if chromecast is connected
                    trackPlayerEvents('Cast Event');
                }
            });
        }
        // Check if airplay is available and init a listener
        if (player?.cast?.airplay && trackPlayerEvents) {
            player.cast.airplay.addEventListener('statechange', (event) => {
                if (event.state === 'connected') {
                    // Send the track request only if airplay is connected
                    trackPlayerEvents('AirPlay Event');
                }
            });
        }
    }, [player, logTrackPlayEvent, trackPlayerEvents]);
};
