import * as React from 'react';

export const OutlookComLogo = () => (
    <svg
        width="24"
        height="19.5"
        viewBox="0 0 24 19.5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.32904 8.90167C5.00912 9.38535 4.93294 9.93187 4.93485 10.4974C4.93485 11.0173 4.9996 11.5239 5.2681 11.979C5.49472 12.3675 5.82035 12.6093 6.28881 12.5998C6.75343 12.5903 7.10195 12.3541 7.28285 11.9428C7.42183 11.6248 7.5209 11.2744 7.5647 10.9297C7.64278 10.309 7.59703 9.68812 7.3343 9.1035C7.18003 8.76262 6.94768 8.49037 6.56495 8.39512C6.07171 8.27137 5.6242 8.45415 5.32904 8.90167Z"
            fill="#2D72C0"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.8618 20.2481C8.07072 19.4541 4.29489 18.6639 0.505715 17.8717L0.505821 15.2184C0.506085 11.2119 0.50635 7.20517 0.5 3.19869C0.5 2.99876 0.553317 2.93021 0.747537 2.89403C3.77697 2.31518 6.80645 1.72871 9.83585 1.14225C10.2986 1.05128 10.7614 0.962129 11.2311 0.871647C11.4398 0.831454 11.6498 0.790997 11.8618 0.75V20.2481ZM8.84 10.4801C8.83242 9.6879 8.72382 8.95673 8.33165 8.29222C8.01552 7.7571 7.589 7.35157 6.974 7.17637C5.7782 6.83558 4.63573 7.39537 4.07211 8.60445C3.71223 9.37943 3.63417 10.202 3.70462 11.0436C3.76555 11.7729 3.97691 12.447 4.44723 13.0239C5.38215 14.1702 7.08253 14.1778 8.03075 13.0391C8.65722 12.2851 8.8286 11.3883 8.84 10.4801Z"
            fill="#2D72C0"
        />
        <path
            d="M12.7931 4.87976C12.7885 4.89424 12.7846 4.90532 12.7814 4.91424C12.7754 4.93112 12.7722 4.9403 12.7722 4.95026L12.7721 4.9852C12.7703 6.18278 12.7684 7.38036 12.774 8.57794C12.774 8.64461 12.8369 8.73596 12.8979 8.77601C13.0971 8.90643 13.2987 9.03285 13.5003 9.15933C13.6667 9.26369 13.8332 9.36813 13.9985 9.47486C14.1242 9.55676 14.2347 9.55294 14.3565 9.46534C14.5104 9.35577 14.667 9.25159 14.8238 9.14724C14.8959 9.09927 14.9682 9.05117 15.0402 9.00259C15.4787 8.70759 15.9172 8.41245 16.3556 8.11732L16.3666 8.10987C17.5233 7.33121 18.6805 6.55224 19.839 5.77669C19.9666 5.69291 20.018 5.61679 19.9666 5.46064C19.8504 5.10641 19.5648 4.87976 19.1953 4.87976H12.7931Z"
            fill="#2D72C0"
        />
        <path
            d="M12.7797 10.1317V15.3052H18.8748C19.6194 15.3052 19.9982 14.9282 19.9982 14.1798C19.9995 12.6781 19.9991 11.1771 19.9987 9.67649C19.9985 8.9264 19.9982 8.17597 19.9982 7.42595C19.9982 7.3893 19.9946 7.35193 19.9904 7.30973C19.9879 7.284 19.9852 7.25633 19.983 7.22607L19.9367 7.25418C19.8819 7.28739 19.8386 7.31361 19.7964 7.34217C18.9158 7.9277 18.0351 8.51274 17.1545 9.09777C16.2738 9.68281 15.3932 10.2678 14.5125 10.8534C14.1945 11.0647 14.1431 11.0667 13.8347 10.8552C13.6694 10.7414 13.5036 10.628 13.3351 10.5127L13.334 10.5119C13.1534 10.3883 12.9696 10.2626 12.7797 10.1317Z"
            fill="#2D72C0"
        />
    </svg>
);
