"use client"
import { useEffect, useMemo } from 'react';
import {
    getEventVariantsCategories,
    EventStatus,
    EventVariant,
    Event,
} from 'onair_frontend-lib';
import { useAppSelector } from '../../../state/hooks';
import { getPurchasedVariantsFromEventVariants } from './event-page-utils';

/**
 *
 * Custom hook used to hold the logic for setting the selected variant, the event status,
 * along with hasTicketInShopify, loggedIn and doorsOpenDate.
 * It is also responsible to change the status of a FUTURE event in to LIVE
 *
 * This way the hook reduces the rerenders of EventPage component
 * and the configurator is always visible
 */
export const useEventPageStatus = (
    event: Event,
    setSelectedVariant: (variant: EventVariant) => void,
    setEventStatus: (eventStatus: EventStatus) => void,
    selectedVariant?: EventVariant,
    eventStatus?: EventStatus,
) => {
    const loggedIn = useAppSelector(
        (state) => state.auth.loginStatus === 'LOGGED_IN',
    );

    const { events: purchasedEvents, status: purchasedEventFetchStatus } =
        useAppSelector(({ tickets }) => tickets);

    const doorsOpenDate = useMemo(
        () =>
            selectedVariant?.doorsOpen
                ? new Date(selectedVariant.doorsOpen)
                : undefined,
        [selectedVariant?.doorsOpen],
    );

    /**
     * The content protection call takes a few seconds, and the tickets are fetched on app load.
     * This allows us to decide between showing:
     * - A spinner because we think the user has a ticket
     * - The hero event configurator, because the contentProtection could say that
     * the user actually doesn't have access to the event
     * */
    const purchasedVariantsRelatedToEvent =
        getPurchasedVariantsFromEventVariants(purchasedEvents, event?.variants);

    const hasTicketInShopify = purchasedVariantsRelatedToEvent.length > 0;

    /** This method takes care of deciding which event is the 'selected one',
     * and what's the event status of the page.
     *
     * Suppose the user is logged in and the purchased shows are being fetched.
     * In that case, we will defer the decision of which event is selected
     * since the purchased events are an important factor in that decision.
     * This also assures that we don't fetch the content protection multiple times.
     * */
    const handleEventVariantAndStatus = () => {
        if (
            event.variants.length > 0 &&
            (!loggedIn ||
                (loggedIn &&
                    (purchasedEventFetchStatus === 'FETCHED' ||
                        purchasedEventFetchStatus === 'FAILED')))
        ) {
            const { futureVariants, liveVariants, pastVariants } =
                getEventVariantsCategories(
                    hasTicketInShopify
                        ? purchasedVariantsRelatedToEvent
                        : event.variants,
                );
            let newEventStatus: EventStatus;
            let newSelectedVariant: EventVariant;
            // LIVE variants have the highest priority, then future and finally past events.
            if (liveVariants.length > 0) {
                newEventStatus = 'LIVE';
                [newSelectedVariant] = liveVariants;
            } else if (futureVariants.length > 0) {
                newEventStatus = 'FUTURE';
                [newSelectedVariant] = futureVariants;
            } else {
                newEventStatus = 'PAST';
                [newSelectedVariant] = pastVariants;
            }
            setSelectedVariant(newSelectedVariant);
            setEventStatus(newEventStatus);
        }
    };

    /**
     * If the event is in the FUTURE we will check every second to figure out if the doorsOpen has passed.
     * If doorsOpen has passed we change the status of the event to LIVE
     */
    useEffect(() => {
        let intervalCurrentDate = Date.now();
        let interval: number;
        if (eventStatus === 'FUTURE' && typeof window !== undefined) {
            interval = window.setInterval(() => {
                intervalCurrentDate = Date.now();
                if (
                    doorsOpenDate &&
                    intervalCurrentDate >= doorsOpenDate.getTime()
                ) {
                    handleEventVariantAndStatus();
                    // clear interval after show is live
                    clearInterval(interval);
                }
            }, 1000);
        }
        handleEventVariantAndStatus();
        return () => clearInterval(interval);
    }); // Only run once

    return {
        hasTicketInShopify,
        loggedIn,
        doorsOpenDate,
    };
};
