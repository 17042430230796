/* eslint-disable no-underscore-dangle */
import React, { FC } from 'react';
import {
    ContentfulFlexiblePageBlock,
    ContentfulStream,
    ContentfulStreamSecurity,
} from 'onair_frontend-lib';
import FlexiblePageBlock from '../flexible-page/FlexiblePageBlock';
import THEOPlayer from '../../theo-player/THEOPlayer';
import { unstable_noStore as noStore } from 'next/cache';
import _ from 'lodash';

type Props = {
    blocks?: ContentfulFlexiblePageBlock[];
    streams: ContentfulStream[];
    streamSecurity?: ContentfulStreamSecurity;
    accessCode: string;
    slug: string;
};

const PreviewPageContent: FC<Props> = ({
    blocks,
    streams,
    streamSecurity,
    accessCode,
    slug,
}) => {
    noStore();

    return (
        <>
            <div
                className="container-fluid mt-5 mt-lg-1"
                data-testid="previewWrapperTest"
            >
                <div className="row">
                    {streams?.map((event) => (
                        <div
                            className="p-0 preview-page-theo-wrap"
                            key={event?.id}
                        >
                            <THEOPlayer
                                dashUrl={event?.dashUrl}
                                hlsUrl={event?.hlsUrl}
                                dashCastUrl={event?.dashCastUrl}
                                posterUrl={
                                    streamSecurity?.poster?.asset?.file?.url ??
                                    ''
                                }
                                pipEnabled={false}
                                chromecastReceiverAppId={
                                    streamSecurity?.chromecastReceiverAppId
                                }
                                trackingTitle={event.title}
                                previewPageCredentials={{
                                    id: slug,
                                    accessCode,
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {blocks?.map((block, index) => (
                <FlexiblePageBlock
                    key={`block${block.id}`}
                    isFirstBlock={index === 0}
                    block={block}
                    showTransparentBackground={
                        blocks.length > 1 || index % 2 !== 0
                    }
                />
            ))}
        </>
    );
};

export default PreviewPageContent;
