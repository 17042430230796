/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { FC } from 'react';
import { EventVariant, formatDisplayPrice } from 'onair_frontend-lib';
import { CartStatus } from '../../state/reducers/cartSlice';
import Button from '../Button';
import Icon from '../Icon';
import messages from './messages';
import { localizedDateFormat } from '../../utils/date';

type StickyHeroEventInfoProps = {
    selectedVariant: EventVariant;
    customerCountryCode: string;
    cartStatus?: CartStatus;
    handleBuyTicketNow?: () => void;
    eventTitle: string;
};

const StickyHeroEventInfo: FC<StickyHeroEventInfoProps> = ({
    selectedVariant,
    customerCountryCode,
    cartStatus,
    handleBuyTicketNow,
    eventTitle,
}) => (
    <div className="container">
        <div className="row d-flex sticky-event-configurator__content">
            <div className="col-md-12">
                <div className="row d-flex wrapper">
                    <div className="left-column mb-2 mb-md-0 col-lg-6 col-md-8">
                        <div className="event-title d-none d-sm-block">
                            {selectedVariant.title ??
                                `${eventTitle} - ${selectedVariant.options?.[0].value}`}
                        </div>
                        <div className="d-flex event-info justify-content-center justify-content-md-start">
                            <div className="icons">
                                {selectedVariant?.icons?.map(
                                    ({ type: iconName }) => (
                                        <Icon
                                            key={`icon-${iconName}`}
                                            icon={iconName}
                                        />
                                    ),
                                )}
                            </div>

                            {selectedVariant.eventStart && (
                                <>
                                    <span className="dot d-none d-sm-block" />
                                    <div className="event-date d-none d-sm-block">
                                        {localizedDateFormat(
                                            new Date(
                                                selectedVariant.eventStart,
                                            ),
                                            'EEEE d MMMM yyyy, HH:mm',
                                            customerCountryCode,
                                        )}
                                    </div>
                                </>
                            )}
                            <span className="dot d-none d-sm-block" />
                            <div className="event-price">
                                <span>
                                    {selectedVariant.price &&
                                        formatDisplayPrice(
                                            customerCountryCode,
                                            selectedVariant.price,
                                        )}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="right-column col-md-3">
                        <Button
                            icon="oa-ticket"
                            grow
                            onClick={handleBuyTicketNow}
                            loading={
                                cartStatus === 'CREATE_CART_PENDING' ||
                                cartStatus === 'ADD_CART_LINES_PENDING'
                            }
                        >
                            {messages.buyShowNow}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default StickyHeroEventInfo;
