"use client";
import React from "react";

import ContentBlockComponent from "../content-block/ContentBlockComponent";
import { renderContentfulDescription } from "../../utils/contentful";
import { useContentfulButtonProps } from "../../hooks/useContentfulButtonProps";

import type {
	ContentfulContentBlock,
	ContentfulIcon,
	ContentfulImageWithGatsbyImageData,
} from "onair_frontend-lib";

const EventContentBlock = ({
	event,
	title,
	subtitle,
	description,
	image,
	button,
	imagePosition,
	links,
	showAppStoreBanners,
	socialMediaIconsTitle,
	icons,
	type,
	isFirstBlock,
}: ContentfulContentBlock & {
	icons?: ContentfulIcon[];
	isFirstBlock?: boolean;
}) => {
	const contentfulButtonProps = useContentfulButtonProps(button);

	return (
		<ContentBlockComponent
			event={event}
			isFirstBlock={isFirstBlock}
			title={title}
			subtitle={subtitle}
			description={renderContentfulDescription(description)}
			image={image as ContentfulImageWithGatsbyImageData}
			buttonProps={contentfulButtonProps}
			icons={icons}
			showAppStoreBanners={showAppStoreBanners}
			imagePosition={imagePosition}
			links={links}
			useH1TagForTitle={type === "EventDescription"}
			socialMediaIconsTitle={socialMediaIconsTitle}
		/>
	);
};

export default EventContentBlock;
