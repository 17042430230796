import React, { FC } from "react";
import { ContentfulSellingPoints } from "onair_frontend-lib";
import SellingPointsSectionComponent from "./SellingPointsSectionComponent";

const SellingPointBlock: FC<{
	sellingPointsBlock: ContentfulSellingPoints;
	isFirstBlock?: boolean;
}> = ({ sellingPointsBlock, isFirstBlock }) => (
	<SellingPointsSectionComponent
		sellingPoints={sellingPointsBlock.sellingPoints}
		title={sellingPointsBlock.title}
		showTitle={sellingPointsBlock.showTitle}
		isFirstBlock={isFirstBlock}
	/>
);

export default SellingPointBlock;
