import { useState, useMemo, useEffect, RefObject } from 'react';

/**
 *
 * @param elementRef Element ref we want to track if is visible in the viewport
 * @param threshold Number which indicate at what percentage of the target's visibility the observer's callback should be executed
 * eg: 0.5 means 50% of the target must not be visible
 *
 * @returns boolean
 */
export const useIsVisible = (
    elementRef: RefObject<HTMLElement>,
    threshold: number,
) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(
                ([entry]) => setIsIntersecting(entry.isIntersecting),
                {
                    threshold,
                },
            ),
        [threshold],
    );

    useEffect(() => {
        if (elementRef?.current) observer.observe(elementRef.current);

        return () => {
            observer.disconnect();
        };
    }, [elementRef, observer]);

    return isIntersecting;
};
