import classNames from "classnames";
import React from "react";
import { useDispatch } from "react-redux";
import {
	combineCurrencyAndAmount,
	commonTexts,
	ButtonType,
	webPurchaseTrack,
} from "onair_frontend-lib";

import { navigate } from "../../../utils/navigation";
import ROUTES from "../../../constants/routes";
import { useAppSelector, useCountryCodeSelector } from "../../../state/hooks";
import Button from "../../Button";
import { fetchCartCheckout, fetchMultipass } from "../services";
import { getCartItemCurrency, getCheckoutLineItems } from "../utils";
import { handleLogout } from "../../../state/tokenHelper";
import { cartPageTexts } from "../messages";

import type { NormalizedCartListItem } from "../../../types/cart";
import type { FC, RefObject } from "react";
import { sharedAnalyticsProperties } from "@/utils/analytics";
import { getEnvironmentVariableServerSide } from "@/utils/server-functions/getEnvServerSide";
import { createNavigationParams } from "@/state/reducers/navigationSlice";

interface CartTotalProps {
	normalizedCartList: NormalizedCartListItem[];
	handleIsLoading: (value: boolean) => void;
	isLoading: boolean;
	checkoutRef?: RefObject<HTMLDivElement>;
	isSticky?: boolean;
}

export const CartTotal: FC<CartTotalProps> = ({
	normalizedCartList,
	handleIsLoading,
	isLoading,
	checkoutRef,
	isSticky,
}) => {
	const { cart } = useAppSelector((state) => state.cartSlice);
	const auth = useAppSelector((state) => state.auth);
	const customerCountryCode = useCountryCodeSelector();
	const priceCurrency = cart && getCartItemCurrency(cart);
	const hasMerchandiseItems = normalizedCartList.some(
		(item) => item.isAddOnsProduct
	);

	const dispatch = useDispatch();

	const checkoutCartItems = async () => {
		handleIsLoading(true);
		const checkoutLineItems = cart ? getCheckoutLineItems(cart) : [];

		const cartCheckout = await fetchCartCheckout({
			queryVariables: {
				input: {
					lineItems: [...checkoutLineItems],
				},
				countryCode: customerCountryCode,
			},
		});
		const cartCheckoutUrl = cartCheckout.checkout.webUrl;

		// Send 'checkout' track event to Segment
		webPurchaseTrack({
			...sharedAnalyticsProperties,
			trackCallback: window?.analytics?.track,
			purchaseStatus: "Checkout Started",
			cartItems: normalizedCartList,
			currency: priceCurrency,
			cartId: cart?.id,
			url: window.location.href,
		});

		if (auth.loginStatus === "LOGGED_IN" && auth.token?.jwt) {
			// TODO - This needs to be server side
			const multiPassResult = await fetchMultipass(
				auth.token.jwt,
				cartCheckout.checkout.webUrl
			);

			// If an error occured it could very well be due to a wrong session, redirect them back to the login and promt relog
			if (!multiPassResult || multiPassResult.error) {
				// eslint-disable-next-line no-console
				console.error(
					"An error occurred signing in with multipass, logging the user out..."
				);
				console.error(multiPassResult.error);
				handleLogout(dispatch);
				dispatch(
					createNavigationParams({
						state: { loginReason: "ERROR", returnTo: ROUTES.cart },
					})
				);
				navigate(ROUTES.login);
			} else {
				const { SHOPIFY_DOMAIN } = await getEnvironmentVariableServerSide([
					"SHOPIFY_DOMAIN",
				]);
				// TODO - put back the env
				const multiPassLoginUrl = `${SHOPIFY_DOMAIN!}/account/login/multipass/${
					multiPassResult.data.multipass
				}`;
				navigate(multiPassLoginUrl);
			}
			return;
		}

		dispatch(
			createNavigationParams({
				state: { loginReason: "CHECKOUT", checkoutUrl: cartCheckoutUrl },
			})
		);
		navigate(ROUTES.login);
	};

	return (
		<div
			className={classNames({
				"d-sm-flex": isSticky,
			})}
		>
			<div
				className={classNames("d-flex", {
					"d-none": isSticky,
				})}
			>
				<span className="section-title uppercase-first-letter">
					{commonTexts.cart_total}
				</span>
			</div>
			<div
				className={classNames("mt-3 d-flex justify-content-between", {
					"d-none": isSticky,
				})}
			>
				<span className="body-text uppercase-first-letter">
					{commonTexts.sub_total}
				</span>
				<span className="body-title price-label">
					{combineCurrencyAndAmount(
						cart?.estimatedCost.subtotalAmount?.amount ?? 0,
						priceCurrency
					)}
				</span>
			</div>
			<div
				className={classNames("line my-2", {
					"d-none": isSticky,
				})}
			/>
			{!isSticky && hasMerchandiseItems && (
				<div className="mt-1 d-flex justify-content-between">
					<span className="body-text uppercase-first-letter">
						{commonTexts.shipping}
					</span>
					<span className="body-title price-label capitalize">
						{cartPageTexts.free}
					</span>
				</div>
			)}
			{!isSticky && (
				<>
					<div className="mt-1 d-flex justify-content-between">
						<span className="body-text uppercase-first-letter">
							{commonTexts.taxes}
						</span>
						<span className="body-title price-label capitalize">
							{cartPageTexts.included}
						</span>
					</div>
					<div className="line my-2 d-block" />
				</>
			)}
			<div
				className={classNames("mt-2 d-flex justify-content-between", {
					"m-sm-2 flex-sm-column": isSticky,
				})}
			>
				<span
					className={classNames("body-text uppercase-first-letter", {
						"landscape-total-text": isSticky,
					})}
				>
					{commonTexts.total}
				</span>
				<span
					className={classNames("section-title price-label", {
						"landscape-price": isSticky,
					})}
				>
					{combineCurrencyAndAmount(
						cart?.estimatedCost.totalAmount?.amount ?? 0,
						priceCurrency
					)}
				</span>
			</div>
			{isSticky && (
				<div className="my-1 d-block d-sm-none">
					<span className="caption-text color-accent-light uppercase-first-letter">
						{cartPageTexts.all_prices_final}
					</span>
				</div>
			)}
			<div ref={checkoutRef} className={classNames({ "col-sm-4": isSticky })}>
				<Button
					icon="oa-chevron-right"
					onClick={checkoutCartItems}
					className={classNames("mt-3", {
						"mb-sm-2 mt-sm-2": isSticky,
					})}
					type={ButtonType.SOLID_PRIMARY}
					grow
					loading={isLoading}
					testId="checkoutButton"
				>
					{commonTexts.checkout}
				</Button>
			</div>
		</div>
	);
};
