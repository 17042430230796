import type { Event as EventSchema, WithContext } from "schema-dts";
import { Event, EventVariant } from "onair_frontend-lib";

// For better SEO score need to provide url without www and / at the end
// const BASE_URL = 'https://onair.events';

export const generateEventSchema = (
	{ title, shortDescription, slug, poster, createdAt, seo }: Event,
	{ price, eventStart, eventEnd, doorsOpen, options }: EventVariant,
	BASE_URL: string
): WithContext<EventSchema> => {
	const eventURL = `${BASE_URL}/${slug}`;
	const name = `${title} ${
		Array.isArray(options)
			? `- ${options.map((option) => option.value).join(" / ")}`
			: ""
	}`;
	return {
		"@context": "https://schema.org",
		"@type": "Event",
		name,
		description: shortDescription,
		offers: {
			"@type": "Offer",
			url: eventURL,
			price: price?.EUR,
			priceCurrency: "EUR",
			availability: "https://schema.org/InStock",
			validFrom: createdAt,
		},
		// Interesting field to add in case we enable free events
		// isAccessibleForFree: true/false,
		image:[seo?.image?.asset?.url!],
		eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
		startDate: eventStart,
		endDate: eventEnd,
		doorTime: doorsOpen,
		// https://schema.org/EventStatusType
		eventStatus: "https://schema.org/EventScheduled",
		location: {
			"@type": "VirtualLocation",
			url: eventURL,
		},
		performer: {
			"@type": "MusicGroup",
			name: title,
			image: poster?.asset?.file?.url,
		},
		organizer: {
			"@type": "Organization",
			name: "On Air B.V.",
			url: BASE_URL,
		},
	};
};
