/* eslint-disable @typescript-eslint/restrict-template-expressions */
'use server';
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
interface AlgoliaSearchServerSideProps {
    query: string;
}

export const algoliaSearchServerSide = async ({
    query,
}: AlgoliaSearchServerSideProps) => {
    const response = await fetch(
        `https://${process.env.ALGOLIA_APPLICATION_ID}-dsn.algolia.net/1/indexes/${process.env.ALGOLIA_INDEX}/query`,
        {
            method: 'POST',
            headers: {
                'X-Algolia-Application-Id': process.env.ALGOLIA_APPLICATION_ID,
                'X-Algolia-API-Key': process.env.ALGOLIA_READ_ONLY_KEY,
                'Content-Type': 'application/json; charset=UTF-8',
            } as any,
            body: JSON.stringify({
                params: `query=${encodeURI(query)}`,
            }),
        },
    );
    const { hits } = await response.json();
    return hits;
};
