// import { openConsentManager } from '@segment/consent-manager';
import React from "react";
import { openConsentManager } from "@segment/consent-manager";

import Button from "../../Button";

export const ChangeCookieSettings = () => (
	<div className="container container--vertical-padding cookie-policy">
		<Button onClick={openConsentManager}>Change cookie settings</Button>
	</div>
);
