export const cartPageTexts = {
    empty_cart_title: 'Your cart is currently empty',
    empty_cart_desc:
        "It looks like you haven't added anything to your cart yet.",
    empty_cart_btn_text: 'Browse our shows',
    abandoned_cart_opened_different_country_msg:
        'Your cart items could not be restored because the items in the cart were added in a different country compared to your current country.',
    included: 'included',
    free: 'free',
    all_prices_final: 'all prices are final',
};
