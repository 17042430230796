"use client";
import type { KeyOSDRMConfiguration, UIPlayerConfiguration } from "theoplayer";

import { getEnvironmentVariableServerSide } from "@/utils/server-functions/getEnvServerSide";

export const getTheoPlayerConfigs = async (
	pipEnabled?: boolean
): Promise<UIPlayerConfiguration> => {
	const { THEOPLAYER_LICENSE, CHROMECAST_APP_ID } =
		await getEnvironmentVariableServerSide([
			"THEOPLAYER_LICENSE",
			"CHROMECAST_APP_ID",
		]);
	return {
		libraryLocation: "theoplayer",
		license: THEOPLAYER_LICENSE,
		allowNativeFullscreen: true,
		cast: {
			chromecast: {
				appID: CHROMECAST_APP_ID,
			},
		},
		// Hide pip on preview pages
		pip: pipEnabled
			? {
					visibility: 0.1,
					position: "bottom-right",
					retainPresentationModeOnSourceChange: false,
			  }
			: undefined,
		ui: {
			language: "en",
			languages: {
				en: {
					Language: "Audio",
				},
			},
		},
	};
};

export interface PreviewPageCredentials {
	id: string;
	accessCode: string;
}

interface GetContentProtectionParams {
	drmType: "widevine" | "fairplay" | "playready";
	sku?: string;
	jwt?: string;
	previewPageCredentials?: PreviewPageCredentials;
}

const LICENSE_ACQUISITION_ENDPOINT = "generate-auth-xml/license-acquisition";

const getLicenseAcquisitionURL = async ({
	sku,
	jwt,
}: Omit<GetContentProtectionParams, "drmType" | "previewPageCredentials">) => {
	const { DRM_ENDPOINT } = await getEnvironmentVariableServerSide([
		"DRM_ENDPOINT",
	]);
	return `${DRM_ENDPOINT!}/${LICENSE_ACQUISITION_ENDPOINT}?sku=${sku}&jwt=${jwt}`;
};
const getPreviewPageLicenseAcquisitionURL = async ({
	id,
	accessCode,
}: PreviewPageCredentials) => {
	const { DRM_ENDPOINT } = await getEnvironmentVariableServerSide([
		"DRM_ENDPOINT",
	]);

	return `${DRM_ENDPOINT!}/${LICENSE_ACQUISITION_ENDPOINT}?preview=true&sku=${id}&accessToken=${accessCode}`;
};
/**
 * @description It generates a KeyOSDRMConfiguration that can be used by THEOPlayer to apply DRM protection.
 * There are two scenarios where we want to acquire a license:
 * - An user that purchased a ticket wants to watch the show. In that case, a jwt and a sku are necessary to validate that he owns the ticket.
 * - An artist wants to preview his/her show, he/she can do it through the preview page. In that case a preview page id and access code are necessary to validate his/her identity.
 */
export const getContentProtection = async ({
	sku,
	jwt,
	previewPageCredentials,
}: Omit<GetContentProtectionParams, "drmType">): Promise<
	KeyOSDRMConfiguration | undefined
> => {
	let url = "";

	if (!((sku && jwt) || previewPageCredentials)) {
		return undefined;
	}

	if (sku && jwt) {
		url = await getLicenseAcquisitionURL({ sku, jwt });
	}

	if (previewPageCredentials) {
		url = await getPreviewPageLicenseAcquisitionURL({
			...previewPageCredentials,
		});
	}
	const { KEYOS_FAIRPLAY_CERT_URL } = await getEnvironmentVariableServerSide([
		"KEYOS_FAIRPLAY_CERT_URL",
	]);

	return {
		integration: "keyos",
		preferredKeySystems: ["widevine", "playready", "fairplay"],
		fairplay: {
			// This certificate is public and it does not pose a risk to share it
			certificateURL: KEYOS_FAIRPLAY_CERT_URL,
			licenseAcquisitionURL: `${url}&drmType=fairplay`,
			headers: {
				Accept: "application/octet-stream",
				"Content-Type": "application/octet-stream",
			},
		},
		widevine: {
			licenseAcquisitionURL: `${url}&drmType=widevine`,
			headers: {
				Accept: "application/octet-stream",
				"Content-Type": "application/octet-stream",
			},
		},
		playready: {
			licenseAcquisitionURL: `${url}&drmType=playready`,
		},
	};
};
