import { useContext } from 'react';

import { TheoPlayerContext } from './TheoPlayerContext';

interface UseTheoPlayerContext {
    isTheoLoading?: boolean;
    setIsTheoLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useTheoPlayerContext = (): UseTheoPlayerContext => {
    const { isTheoLoading, setIsTheoLoading } = useContext(TheoPlayerContext);

    return { isTheoLoading, setIsTheoLoading };
};
