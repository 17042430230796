import React, { FC } from 'react';
import classNames from 'classnames';
import Button from '../Button';
import {
    ButtonType,
    ContentfulEventOption,
    EventVariantOption,
} from 'onair_frontend-lib';
import { HeroEventConfiguratorOption } from './HeroEventConfiguratorOption';

type AvailableOptionsType = {
    position: number;
    options: ContentfulEventOption[];
};
type MultipleVariantsConfiguratorType = {
    availableOptionsArray: AvailableOptionsType[];
    selectedOptions: ContentfulEventOption[];
    onSelectOption: (updatedOption: EventVariantOption) => void;
};

const MultipleVariantsConfigurator: FC<MultipleVariantsConfiguratorType> = ({
    availableOptionsArray,
    selectedOptions,
    onSelectOption,
}) => (
    <>
        {availableOptionsArray.map(({ position, options }) => (
            <div
                key={`configuration-option-${position}`}
                className="event-configurator-option"
            >
                {options.map((option: EventVariantOption) => (
                    <Button
                        ariaLabel={option.value}
                        testId={option.value}
                        type={ButtonType.BUTTON_STYLED_IN_COMPONENT}
                        key={`configuration-option-value-${option.value}`}
                        className={classNames('event-configurator-option', {
                            'option-value--active': selectedOptions.some(
                                ({ value }) => value === option.value,
                            ),
                            'option-value--one-third': options.length === 3,
                            'option-value--half': options.length === 2,
                            'option-value--full': options.length === 1,
                        })}
                        onClick={() => onSelectOption(option)}
                    >
                        <HeroEventConfiguratorOption
                            option={option}
                            selectedOptions={selectedOptions}
                        />
                    </Button>
                ))}
            </div>
        ))}
    </>
);

export default MultipleVariantsConfigurator;
