"use client";
import React, { FC, useCallback } from "react";
import { ButtonType } from "onair_frontend-lib";
import ROUTES from "../../constants/routes";
import Button from "../Button";
import { CONTACT_PAGE_MESSAGES } from "./messages";
import { navigate } from "@/utils/navigation";
import { createNavigationParams } from "@/state/reducers/navigationSlice";
import { useDispatch } from "react-redux";

export const LoginRequiredCategoryError: FC<{ categoryType?: number }> = ({
	categoryType,
}) => {
	const dispatch = useDispatch();
	const onClickLoginLink = useCallback(() => {
		dispatch(
			createNavigationParams({
				state: {
					returnTo:
						categoryType !== undefined
							? `${ROUTES.contact}?category=${categoryType}`
							: ROUTES.contact,
				},
			})
		);
		navigate(ROUTES.login);
	}, [categoryType]);

	return (
		<>
			{CONTACT_PAGE_MESSAGES.contact_form_category_error.part1}
			<Button
				type={ButtonType.BUTTON_STYLED_IN_COMPONENT}
				onClick={onClickLoginLink}
				className="category-login-warning"
			>
				{CONTACT_PAGE_MESSAGES.contact_form_category_error.part2}
			</Button>
		</>
	);
};
