import classNames from 'classnames';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { template } from 'lodash';
import {
    commonTexts,
    VALIDATION_ERROR_MESSAGES,
    ValidationErrorCode,
} from 'onair_frontend-lib';

import { DATE_INPUT_NAMES } from './constants';
import {
    registerDayInputParams,
    registerYearInputParams,
} from './date-input-utils';

import Dropdown from '../dropdown/Dropdown';
import Input from '../input';
import InputError from '../input/InputError';

import type { FC } from 'react';
import type { YearValidationRules } from './types';

interface DateInputProps {
    error?: string;
    className?: string;
    disabled?: boolean;
    yearValidationRules?: YearValidationRules;
    defaultValue?: string; // format: YYYY-MM-DD
}

const defaultErrorMessage = template(
    VALIDATION_ERROR_MESSAGES[ValidationErrorCode.invalid],
)({ field: commonTexts.date });

const DateInput: FC<DateInputProps> = ({
    error,
    className,
    disabled,
    yearValidationRules = {},
    defaultValue = '',
}) => {
    const [defaultYear, defaultMonth = '1', defaultDay] =
        defaultValue.split('-');

    const [currentMonth, setCurrentMonth] = useState(
        Number.parseInt(defaultMonth, 10) - 1,
    );
    const {
        register,
        formState,
        getValues: getFormValues,
        setValue: setFormValue,
    } = useFormContext();
    const { errors } = formState;

    const updateMonth = (month: number) => {
        if (month >= 0 && month <= 11) {
            setCurrentMonth(month);
            setFormValue('month', month);
        }
    };

    const formMonthValue = getFormValues('month');
    const formYearValue = getFormValues('year');

    return (
        <div className={classNames('onair-date-input', className)}>
            <div className="d-flex flex-row">
                <Dropdown
                    options={[
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                    ]}
                    selected={currentMonth}
                    selectCallback={updateMonth}
                    label="Month"
                    disabled={disabled}
                    smallLabel
                    inputName="month"
                />
                <Input
                    type="number"
                    min={1}
                    max={31}
                    label="Day"
                    maxLength={2}
                    defaultValue={defaultDay}
                    className="onair-date-input__day"
                    smallLabel
                    disabled={disabled}
                    // An error message won't be shown in the input, but it will get the error color border
                    autoGeneratedProps={
                        !disabled &&
                        register(
                            registerDayInputParams.name,
                            registerDayInputParams.generateOptions(
                                formMonthValue,
                                formYearValue,
                            ),
                        )
                    }
                    testId="dayInput"
                />
                <Input
                    type="number"
                    min={1900}
                    max={new Date().getFullYear()}
                    label="Year"
                    maxLength={4}
                    defaultValue={defaultYear}
                    // An error message won't be shown in the input, but it will get the error color border
                    className="onair-date-input__year"
                    smallLabel
                    disabled={disabled}
                    autoGeneratedProps={
                        !disabled &&
                        register(
                            registerYearInputParams.name,
                            registerYearInputParams.generateOptions(
                                yearValidationRules,
                            ),
                        )
                    }
                    testId="yearInput"
                />
            </div>
            {/* The month is always selected by default, so only the day or year can be missing */}
            {(errors[DATE_INPUT_NAMES.year] ||
                errors[DATE_INPUT_NAMES.day]) && (
                <InputError testId="dateInputError">
                    {error || defaultErrorMessage}
                </InputError>
            )}
        </div>
    );
};

export default DateInput;
