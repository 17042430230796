import Image from 'next/image';
import React, { Fragment } from 'react';
import classNames from 'classnames';

import type { FC } from 'react';
import type { ContentfulImage } from 'onair_frontend-lib';

export interface GalleryProps {
    readonly images: ContentfulImage[];
    readonly preserveAspectRatio: boolean;
    readonly isFirstBlock?: boolean;
}

const GalleryComponent: FC<GalleryProps> = ({
    images,
    preserveAspectRatio,
    isFirstBlock,
}) => (
    <div
        className={classNames({
            [`gallery-${images.length}`]: images.length,
            'first-block': isFirstBlock,
        })}
    >
        {images.map(({ asset, alt }, index) => {
            const image = asset?.url;
            return (
                <Fragment key={index}>
                    {image && (
                        <div className={`gallery-${images.length}__item`}>
                            <div className="image-container">
                                <Image
                                    fill
                                    key={asset.file?.url}
                                    objectFit={
                                        preserveAspectRatio
                                            ? 'contain'
                                            : 'cover'
                                    }
                                    layout="fill"
                                    src={image}
                                    alt={alt}
                                />
                            </div>
                        </div>
                    )}
                </Fragment>
            );
        })}
    </div>
);

export default GalleryComponent;
