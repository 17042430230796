import Image from 'next/image';
import React, { FC } from 'react';
import {
    ContentfulImageWithGatsbyImageData,
    LinkType,
} from 'onair_frontend-lib';
import { ON_AIR_INSTAGRAM_URL } from '../../../constants/socials';
import Button from '../../Button';
import LinkExternal from '../../LinkExternal';
import messages from './messages';

type PastEventProps = {
    image?: ContentfulImageWithGatsbyImageData;
    eventTitle?: string;
};

const PastEventNotPurchasedComponent: FC<PastEventProps> = ({
    image,
    eventTitle,
}) => {
    const {
        past_event_not_purchased: {
            title,
            subtitle_part_1,
            follow_button_text,
        },
    } = messages;

    const eventInfoImage = image && image?.asset?.file?.url;

    return (
        <div className="past-event-not-purchased-wrapper hero__content">
            {eventInfoImage && (
                <Image
                    fill
                    src={eventInfoImage}
                    alt={image?.alt || ''}
                    className="hero__event-image mx-auto"
                    objectFit="contain"
                />
            )}
            <p className="mt-4 mb-2 mb-md-3 subtitle">{title}</p>
            <p className="body-text">
                {subtitle_part_1} <b>{eventTitle} </b>?
            </p>

            <LinkExternal
                to={ON_AIR_INSTAGRAM_URL}
                className="socials-share-link-button"
                type={LinkType.LINK_STYLED_IN_COMPONENT}
            >
                <Button
                    icon="oa-instagram1"
                    className="btn-onair follow-onair-instagram-btn mt-3 mt-md-4"
                >
                    {follow_button_text}
                </Button>
            </LinkExternal>
        </div>
    );
};

export default PastEventNotPurchasedComponent;
