import React from 'react';
import classNames from 'classnames';
import Image from 'next/image';

import { EventCarouselItemTitleAnimation } from './EventCarouselItemTitleAnimation';

import { useCountryCodeSelector } from '../../state/hooks';
import { localizedDateFormat } from '../../utils/date';
import { getEarliestEventVariant } from 'onair_frontend-lib';
import { BackgroundImage } from '../BackgroundImage';

import type { FC } from 'react';
import type { Event } from 'onair_frontend-lib';

type EventCarouselItemProps = {
    event: Event;
    landscape?: boolean;
    isFocusedOrHovered: boolean;
    displayDate: boolean;
};

export const EventCarouselItemUI: FC<EventCarouselItemProps> = ({
    event,
    landscape,
    isFocusedOrHovered,
    displayDate,
}) => {
    const customerCountryCode = useCountryCodeSelector();
    const earliestVariant = getEarliestEventVariant(event.variants);

    const logo = Object.keys(event?.carouselLogo?.asset ?? {}).length
        ? event.carouselLogo
        : event.eventInfoLogo;
    const alt = event.carouselLogo?.alt ?? event.eventInfoLogo?.alt;

    return (
        <div className="event-content">
            <BackgroundImage
                className={classNames(
                    'event-content__background d-flex flex-column-reverse flex-1',
                    {
                        'flex-column': landscape,
                        'flex-column-reverse': !landscape,
                    },
                )}
                imageUrl={
                    landscape
                        ? (event.poster?.asset as any)?.url ??
                          (event.portraitPoster?.asset as any)?.url ??
                          ''
                        : (event.portraitPoster?.asset as any)?.url ??
                          (event.poster?.asset as any)?.url ??
                          ''
                }
                height={584}
                ariaLabel={
                    landscape
                        ? (event.poster?.alt as any) ??
                          (event.portraitPoster?.alt as any) ??
                          ''
                        : (event.portraitPoster?.alt as any) ??
                          (event.poster?.alt as any) ??
                          ''
                }
            >
                <div
                    className={classNames(
                        'event-content__container d-flex justify-content-center p-2',
                        {
                            'flex-column-reverse': displayDate,
                            'mb-4 mb-xl-7 mb-lg-6 p-2': !landscape,
                        },
                    )}
                >
                    {displayDate && event.eventStart && (
                        <p
                            className={classNames(
                                'caption-text text-shadow-light event__card-subline mt-1',
                                {
                                    'mb-4 mb-xl-7 mb-lg-6':
                                        !landscape && !isFocusedOrHovered,
                                    'mb-2 mb-xl-5 mb-lg-4':
                                        !landscape && isFocusedOrHovered,
                                },
                            )}
                        >
                            {localizedDateFormat(
                                new Date(event.eventStart!),
                                'd MMMM yyyy, HH:mm',
                                customerCountryCode,
                            )}
                        </p>
                    )}
                    {event?.shortTitle && (
                        <p
                            className={classNames(
                                'event-content__short-text body-title mt-1',
                                {
                                    'd-none': !isFocusedOrHovered || landscape,
                                },
                            )}
                        >
                            {event?.shortTitle}
                        </p>
                    )}

                    {landscape ? (
                        <h6 className="caption-text text-shadow-light">
                            {event.title}
                        </h6>
                    ) : (
                        <EventCarouselItemTitleAnimation
                            isFocusedOrHovered={isFocusedOrHovered}
                        >
                            <div className="logo-img">
                                <Image
                                    src={(logo?.asset as any)?.url ?? ''}
                                    fill={true}
                                    alt={alt ?? ''}
                                />
                            </div>
                        </EventCarouselItemTitleAnimation>
                    )}
                </div>
            </BackgroundImage>
        </div>
    );
};
