import React, { FC, ReactNode } from 'react';
import Icon from '../Icon';

interface Props {
    title: string;
    text: ReactNode;
    icon?: string;
}

export const HighlightComponent: FC<Props> = ({ title, text, icon }) => (
    <div className="highlight">
        {icon && (
            <div className="d-flex mb-3 justify-content-center justify-content-sm-start">
                <Icon icon={icon} altText={title} className="highlight__icon" />
            </div>
        )}
        <div className="d-flex justify-content-center justify-content-sm-start">
            <p className="highlight__title">{title}</p>
        </div>
        <div className="highlight__text d-flex justify-content-center justify-content-sm-start">
            {text}
        </div>
    </div>
);
