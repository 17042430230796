import React, { useState } from 'react';
import isAfter from 'date-fns/isAfter';
import { LinkType } from 'onair_frontend-lib';

import LinkInternal from '../../LinkInternal';
import messages from '../messages';
import Icon from '../../Icon';
import { useCountryCodeSelector } from '../../../state/hooks';
import { localizedDateFormat } from '../../../utils/date';

import type { ContentfulIcon } from 'onair_frontend-lib';
import type { FC } from 'react';

interface TicketProps {
    title: string;
    href: string;
    imageUrl?: string;
    imageAlt: string;
    description?: string;
    startDate?: Date;
    endDate?: Date;
    currentDate: Date;
    variantIcons?: ContentfulIcon[];
}

const Ticket: FC<TicketProps> = ({
    title,
    description,
    href,
    imageUrl,
    imageAlt,
    startDate,
    endDate,
    currentDate,
    variantIcons,
}) => {
    const customerCountryCode = useCountryCodeSelector();

    const [isVOD] = useState(
        startDate &&
            endDate &&
            // If the show started in the past and the show didn't end yet, then it's a VOD
            isAfter(currentDate, startDate) &&
            isAfter(endDate, currentDate),
    );
    // If the event end date is in the past, then the show is expired
    const [isExpired] = useState(endDate && isAfter(currentDate, endDate));
    // If the event didn't start yet, then it hasn't been premiered
    const [notYetPremiered] = useState(
        startDate && endDate && isAfter(startDate, currentDate),
    );

    return (
        <LinkInternal
            to={`/${href}`}
            type={LinkType.LINK_STYLED_IN_COMPONENT}
            className="ticket"
        >
            <div className="d-flex">
                {imageUrl && (
                    <img
                        src={imageUrl}
                        alt={imageAlt}
                        className="rounded me-2 ticket__image"
                    />
                )}
                <div className="d-flex flex-column">
                    <h4 className="mb-1 card__title">{title}</h4>

                    <span className="caption-text mb-1 color-accent-light">
                        {isExpired && endDate && (
                            <>
                                {messages.my_tickets.expired_on}{' '}
                                {localizedDateFormat(
                                    endDate,
                                    'd MMMM yyyy',
                                    customerCountryCode,
                                )}
                            </>
                        )}
                        {notYetPremiered && startDate && (
                            <>
                                {messages.my_tickets.available_from}{' '}
                                {localizedDateFormat(
                                    startDate,
                                    'd MMMM yyyy',
                                    customerCountryCode,
                                )}
                            </>
                        )}
                        {isVOD && endDate && (
                            <>
                                {messages.my_tickets.available_until}{' '}
                                {localizedDateFormat(
                                    endDate,
                                    'd MMMM yyyy',
                                    customerCountryCode,
                                )}
                            </>
                        )}
                    </span>
                    {variantIcons && variantIcons.length > 0 && (
                        <div className="d-flex mb-1">
                            {variantIcons.map(({ type }) => (
                                <Icon
                                    key={`ticket-icon-${type}`}
                                    icon={type}
                                    className="me-1"
                                />
                            ))}
                        </div>
                    )}
                    <p className="body-text d-none d-lg-block">{description}</p>
                </div>
            </div>
        </LinkInternal>
    );
};

export default Ticket;
