import isBefore from "date-fns/isBefore";
import addYears from "date-fns/addYears";
import { format, parse } from "date-fns";
import * as fnsLocales from "date-fns/locale";
import { enUS } from "date-fns/locale";
// import { enGB } from "date-fns/locale";

export const isOlderThanThirteen = (dateISO: string) =>
	isBefore(new Date(dateISO), addYears(new Date(), -13));

/**
 * Returns the formatted date based on the user's location, without translating the month text.
 * @param date  - The date to be formatted.
 * @param formatString - The pattern of the format.
 * @param locale - The locale code representing the user's location.
 * @param showYear - Render the year at end or not
 * @returns {string} - The formatted date string.
 */
export const localizedDateFormat = (
	date: Date | number,
	formatString: string,
	locale: string,
	showYear: boolean = true
) => {
	// Format the date in the user's locale
	let formattedDate = format(date, formatString)

	/**
	 * date-fns does not support this kind of formatting - PPP without year. It is a known issue
	*/
	if (!showYear) {
		formattedDate = formattedDate
		.replace(format(date, " yyyy"), "")
		.replace(/,,/g, ",");
	}

	return formattedDate;
};
