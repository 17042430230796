import React, { FC } from 'react';
import classNames from 'classnames';
// import { convertToBgImage } from 'gbimage-bridge';
import { formatDistanceToNow } from 'date-fns';
import {
    ContentfulAssetWithGatsbyImageData,
    ContentfulTestimonial,
} from 'onair_frontend-lib';
import Icon from '../Icon';
import { skeletonShadowOverlay } from '../../stylesheets/overlay';
import { BackgroundImage } from '../BackgroundImage';

type TestimonialCarouselItemProps = {
    testimonial: ContentfulTestimonial;
    passedRef?: React.RefObject<HTMLDivElement>;
    lastItem?: boolean;
};

export const TestomonialCarouselItem: FC<TestimonialCarouselItemProps> = ({
    testimonial,
    passedRef,
    lastItem,
}) => {
    const ratingStars = Array.from(Array(5).keys()).map((_, index) => (
        <Icon
            key={`star-${index}`}
            icon={
                testimonial.rating && index < testimonial.rating
                    ? 'oa-star-filled'
                    : 'oa-star'
            }
        />
    ));

    return (
        <div
            ref={passedRef}
            className={classNames('carousel__item auto-height no-radius', {
                'carousel__item--last': lastItem,
            })}
        >
            <div className="testimonial-content">
                <div className="testimonial-content--left">
                    <BackgroundImage
                        imageUrl={
                            (testimonial.authorPicture?.asset as any)?.url!
                        }
                        className="testimonial-content__image"
                        showShadow={false}
                        ariaLabel={`${testimonial.authorName} picture`}
                    >
                        {!testimonial.authorPicture &&
                            testimonial.authorName[0] && (
                                <div className="body-title">
                                    {testimonial.authorName[0]}
                                </div>
                            )}
                    </BackgroundImage>
                </div>
                <div className="testimonial-content--right">
                    <div className="testimonial-content__header">
                        <h6 className="body-title author-name">
                            {testimonial.authorName}
                        </h6>
                        {testimonial.source?.type && (
                            <Icon
                                icon={testimonial.source?.type}
                                className="icon"
                            />
                        )}
                    </div>
                    {testimonial.jobTitle && (
                        <div className="body-text job-title">
                            {testimonial.jobTitle}
                        </div>
                    )}
                    <div className="testimonial-content__rating mt-1">
                        {testimonial.rating && (
                            <div className="stars">{ratingStars}</div>
                        )}
                        {testimonial?.date && (
                            <div className="created-time">
                                {formatDistanceToNow(
                                    new Date(testimonial?.date),
                                    {
                                        addSuffix: true,
                                    },
                                )}
                            </div>
                        )}
                    </div>
                    <p className="body-text mt-2">
                        {testimonial.content as unknown as string}
                    </p>
                </div>
            </div>
        </div>
    );
};
